import styled from 'styled-components';
import { BlueButton } from '@app/components/Buttons';

const Button = styled(BlueButton)`
  background-color: ${props => props.color ? props.color : props.theme.color.secondaryColor};

  :hover{
    background-color: ${props => props.color ? props.color : props.theme.color.secondaryColor};
  }
  
  :disabled {
    background-color: ${props => props.color ? props.color : props.theme.color.secondaryColor};
  }
`;

export default Button;