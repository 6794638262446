import styles from 'styled-components';

const Wrapper = styles.div`
  width: 100%;
  padding: 140px 0 160px;
  background-color: black;

  @media (max-width: 768px) {
    padding: 120px 0;
  }
`;

const ContentWrapper = styles.div`
  max-width: 1128px;
  margin: 0 auto;

  @media (max-width: 1128px) {
    padding-right: 24px;
    padding-left: 24px;
  }

  @media (max-width: 420px) {
    .vacancy-title {
      margin-bottom: 24px;
      font-size: 32px;
      line-height: 36px;
    }

    .vacancy-title__description {
      padding-bottom: 24px;
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

const Title = styles.h1`
  margin-bottom: 36px;
  color: white;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

const Description = styles.p`
  padding-bottom: 48px;
  color: white;

  @media (max-width: 768px) {
    padding-bottom: 60px;
  }

  @media (max-width: 420px) {
    max-width: 290px;
  }
`;

interface IVacanciesWrapper {
  color: string;
};

const VacanciesWrapper = styles.div<IVacanciesWrapper>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & > div:first-child {
    border-top: 2px solid ${props => props.color};
  }
`;

interface IVacancyDescription {
  active: boolean;
  show: boolean;
  color: string;
};

const VacancyDescription = styles.div<IVacancyDescription>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 32px 96px;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 2px solid ${props => props.color};
  color: white;
  cursor: pointer;

  @media (max-width: 900px) {
    padding: 36px 36px;
  }

  @media (max-width: 768px) {
    padding: 36px 16px;
  }

  &:hover {
    background-color: ${props => props.color};
    color: black;

    .vacancy-description__arrow svg path {
      fill: black;
    }
  }

  @media (max-width: 420px) {
    padding: 11px 16px;
  }

  .vacancy-card__content {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 700px) {
      flex-wrap:wrap;
    }

    .vacancy-description__position {
      font-family: Gerbera Medium;
      width: 48%;
      font-size: 32px;
      line-height: 40px;

      @media (max-width: 768px) {
        font-size: 26px;
      }

      @media (max-width: 700px) {
        width: 100%;
      }

      @media (max-width: 420px) {
        font-size: 16px;
        line-height: 18px;
      }
    }

    .vacancy-description__location {
      width: 48%;
      opacity: 0.8;

      @media (max-width: 700px) {
        width: 100%;
      }

      @media (max-width: 420px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .vacancy-description__arrow {
      position: absolute;
      right: 0;
      display: flex;
  
      svg {
        transition: transform .3s ease;

        @media (max-width: 420px) {
          height: 13px;
          width: 11px;
        }
      }

      @media (max-width: 700px) {
        top: 7px;
      }
    }
  }

  .vacancy-description__content {
    max-height: 0;
    width: 100%;
    display: flex;
    overflow: hidden;
    transition: all .4s ease-out;
    justify-content: space-between;

    @media (max-width: 880px) {
      flex-wrap: wrap;
    }

    .vacancy-content__left, .vacancy-content__right {
      width: 48%;

      @media (max-width: 880px) {
        width: 100%;
      }

      @media (max-width: 420px) {
        & > .event-paragraph__large {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    .vacancy-content__left {
      .event-paragraph__large {
        margin-bottom: 16px;

        @media (max-width: 420px) {
          margin-bottom: 8px;
        }
      }

      .vacancy-about-video-title {
        @media (max-width: 600px) {
          display: none;
        }
      }

      .vacancy-customer {
        margin-bottom: 36px;

        @media (max-width: 768px) {
          margin-bottom: 24px;
        }

        @media (max-width: 600px) {
          margin-bottom: 0;
        }
      }

      .event-paragraph__middle, .event-paragraph__large {
        @media (max-width: 420px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .event-paragraph__middle:not(:first-child) {
        margin-top: 10px;
      }

      @media (max-width: 880px) {
        margin-bottom: 24px;
      }

      @media (max-width: 420px) {
        margin-bottom: 16px;
      }

      .vacancy-about-video {
        video {
          @media (max-width: 880px) {
            width: 431px;
          }
        }

        @media (max-width: 600px) {
          display: none;
        }
      }
    }

    .vacancy-content__right {
      .event-paragraph__large {
        margin-bottom: 16px;

        @media (max-width: 420px) {
          margin-bottom: 8px;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .vacancy-tasks, .vacancy-skills {
        .event-paragraph__middle {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .event-paragraph__large:not(:first-child) {
        margin-top: 36px;

        @media (max-width: 420px) {
          margin-top: 16px;
        }
      }

      .event-paragraph__middle:not(:first-child) {
        margin-top: 10px;
      }

      .vacancy-curator {
        padding-top: 8px;
        display: flex;
        align-items: center;

        .curator-image-wrapper {
          height: 128px;
          width: 128px;
          margin-right: 36px;

          img {
            height: 128px;
            width: 128px;
            border-radius: 50%;
            object-fit: cover;
            object-position: top;
          }

          @media (max-width: 420px) {
            margin-bottom: 0;
            margin-right: 16px;

            &, img {
              height: 56px;
              width: 56px;
            }
          }
        }

        .curator-contact-wrapper {
          a {
            margin-top: 12px;
            display: flex;
            align-items: center;
            color: black;

            img {
              margin-right: 16px;
            }

            @media (max-width: 420px) {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }

  ${props => props.show && props.active && `
    background-color: ${props.color};
    color: black;

    .vacancy-description__arrow svg {
      transform: rotate(90deg);

      path {
        fill: black;
      }
    }

    .vacancy-description__content {
      margin-top: 36px;
      max-height: 1500px;
      transition: all .4s ease-in;

      @media (max-width: 420px) {
        margin-top: 16px;
      }
    }
  `}
`;

export { Wrapper, ContentWrapper, Title, Description, VacanciesWrapper, VacancyDescription };
