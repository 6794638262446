import styled from 'styled-components';

const Style= styled.div`
  	font-family: Gerbera, sans-serif;
	font-style: normal;
	font-weight: normal;

	@font-face {
		font-family: Gerbera Medium;
		font-style: normal;
		font-weight: normal;
		src: url(${require('@app/../assets/fonts/Gerbera-Medium.otf')});
	}
	
	  @font-face {
		font-family: Gerbera Light;
		font-style: normal;
		font-weight: normal;
		src: url(${require('@app/../assets/fonts/Gerbera-Light.otf')});
	}
	
	  @font-face {
		font-family: Gerbera Regular;
		font-style: normal;
		font-weight: normal;
		src: url(${require('@app/../assets/fonts/Gerbera-Regular.otf')});
	}

	@media (max-width: 1200px) {
		& > section {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
`

export default Style;
