import styled from "styled-components";
import { StyleMediaMax } from '@app/components/StyleMedia';
import MainLoaderContainer from '@app/components/Loader/MainLoaderContainer'

const NewsBox = styled.div`

${MainLoaderContainer}{
  padding-top: 145px;
}

${StyleMediaMax.tablet} {
    ${MainLoaderContainer}{
      padding-top: 70px;
    }
  }
`;

export default NewsBox;