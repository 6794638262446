import styled from 'styled-components';
import close from '@assets/icons/grey_close.svg';

const CloseIcon = styled.div`
  height: 100%;
  width: 31px;
  cursor: pointer;
  background: url(${close}) no-repeat center right 15px;
  border-right: ${props => `2px solid ${props.theme.color.shadow}`};
`;

export default CloseIcon;