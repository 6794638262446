import React from 'react';
import { Wrapper } from '@app/components/PageLayout';

const ProductsPage = () => {
  return (
    <Wrapper padding="135px 0 0" paddingTablet="70px 0 0" paddingMobile="60px 0 0">
      About our products
    </Wrapper>
  )
};

export default ProductsPage;
