import styled from 'styled-components';
import close from '@assets/icons/white_close.svg';
import { StyleMediaMax } from '@app/components/StyleMedia';

const CloseIcon = styled.div`
  height: 15px;
  width: 15px;
  margin-left: 82px;
  cursor: pointer;
  background: url(${close}) no-repeat center center;

  ${StyleMediaMax.desktopS} {
    margin-left: 10px;
  }
`;

export default CloseIcon;