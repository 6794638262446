import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
  max-width: calc(100%);
  padding: 10px 0;
  text-overflow: ellipsis;
  text-decoration: none;
  color: ${props => props.theme.color.primaryColorGrey};

  :first-child {
    padding-top: 0;
  }

  :last-child {
    padding-bottom: 0;
  }

  &:hover {
    text-decoration: none;
  }
`;

const HintWrapper = Wrapper.withComponent(NavLink);

export default HintWrapper;
