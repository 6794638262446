import React from 'react';

import { Paragraph, ListWrapper } from '@app/components/PageLayout';
import { AboutContainer, StyledContainer } from './StyledContainer';

const SchemeWidget = () => (
    <AboutContainer>
      <StyledContainer>
        <Paragraph>
        Программный комплекс «Умная Теплосеть» (система мониторинга тепловой сети) является решением для теплоснабжающих организаций, позволяющим повысить эффективность операционной деятельности, скорость принятия управленческих решений, качество услуг теплоснабжения.
        <br/><br/>
        Целью внедрения программного комплекса является сбор, анализ и визуализация показателей теплорегистраторов для принятия управленческих решенийцентра управления теплосетью и включает:
          <ListWrapper>
            <li>
              Создание интегрированного командного центра диспетчеризации с высоким уровнем визуализации состояния сети.
            </li>
            <li>
              Обработку первичных данных теплорегистраторов, включающую: сбор, хранение, анализ, аналитические инструменты, средства визуализации и отчетности.
            </li>
          </ListWrapper>
        </Paragraph>
      </StyledContainer> 
    </AboutContainer>
);

export default SchemeWidget;