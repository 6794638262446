import styled from "styled-components";
import { StyleMediaMax } from '@app/components/StyleMedia';

import { Container } from "@app/components/PageLayout";

const StyledContainer = styled(Container)`
  & > h2 {
   margin-bottom: 30px; 
  }

  ${StyleMediaMax.tablet} {
    max-width: calc(100vw - 120px);
  }

  ${StyleMediaMax.mobileM} {
    max-width: calc(100vw - 40px);
  }
`;

export default StyledContainer;