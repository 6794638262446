import styles from 'styled-components';
import languageImage from './assets/language_image.jpg';
import languageImageMini from './assets/language_image_mini.jpg';

const Wrapper = styles.div`
  width: 100%;
  position: relative;
  padding: 160px 0;

  &:before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    width: 34%;
    height: 100%;
    background-color: #27C3F3;
  }

  @media (max-width: 800px) {
    &:before {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 120px 0;
  }
`;

const ContentWrapper = styles.div`
  max-width: 1128px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  background: white;

  h1 {
    font-family: Gerbera Regular;
  }

  @media (max-width: 1128px) {
    padding-left: 24px;
    padding-right: 24px;

    &:before {
      display: none;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  .language-description__title-mini {
    display: none;
    font-size: 64px;
    line-height: 72px;
    font-family: Gerbera Regular;

    @media (max-width: 768px) {
      display: block;
    }

    @media (max-width: 420px) {
      font-size: 32px;
      line-height: 36px;
    }
  }
`;

const LanguageImage = styles.div`
  z-index: 1;
  height: 600px;
  max-width: 456px;
  width: 100%;
  background-image: url('${languageImage}');

  @media (max-width: 768px) {
    max-width: 720px;
    height: 400px;
    margin: 60px 0 40px;
    background-position-x: 50%;
    background-image: url('${languageImageMini}');
  }

  @media (max-width: 420px) {
    height: 180px;
    margin: 24px 0;
    background-size: cover;
  }
`;

const LanguageDescription = styles.div`
  z-index: 1;
  max-width: 630px;

  .language-description__title {
    font-size: 64px;
    line-height: 75px;
    margin-bottom: 36px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  p:nth-child(2), div:nth-child(3) {
    margin-bottom: 25px;
  }

  div:nth-child(3) {
    & > ul {
      list-style-position: inside;
    }
  }

  .language-description__text:first-child {
    margin-bottom: 36px;
  }

  button {
    margin-top: 58px;
    color: black;
    border-radius: 40px;

    @media (max-width: 500px) {
      width: 100%;
      background-color: #27C3F3;
    }

    @media (max-width: 420px) {
      margin-top: 24px;

      & > div {
        padding: 10px 83px;
      }
    }
  }

  @media (max-width: 1128px) {
    width: 100%;
    padding-left: 20px;
  }

  @media (max-width: 768px) {
    padding-left: 0;

    .language-description__text {
      max-width: 600px;
    }
  }

  @media (max-width: 420px) {
    * {
      font-size: 16px !important;
      line-height: 24px !important;
    }
  }
`;

const LanguageFormWrapper = styles.div`
  position: relative;
  max-width: 936px;
  width: 100%;
  background: black;

  & > div {
    background: white;
  }

  form > div:first-child {
    margin-bottom: 20px;
  }

  form {
    .form-condition {
      @media (max-width: 770px) {
        br {
          display: block;
        }
      }
    }
  }

  @media (max-width: 900px) {
    & > div {
      padding: 36px 64px;
    }
  }

  @media (max-width: 700px) {
    max-width: initial;
    width: auto;
    max-height: 100vh;
    overflow-y: scroll;

    & > div {
      padding: 26px 24px 32px;
    }

    form > div {
      width: 100%;
    }

    form > div:nth-child(1), form > div:nth-child(2), form > div:nth-child(3) {
      margin-bottom: 30px;
    }

    form > .form-submit {
      margin-top: 165px !important;
    }
  }
`;

export { Wrapper, ContentWrapper, LanguageImage, LanguageDescription, LanguageFormWrapper };
