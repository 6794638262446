import React from 'react';
import Wrapper from './Wrapper';
import StyledCheckbox from './StyledCheckbox';

interface Props {
  label: string;
  isChecked?: boolean;
  multiSelect?: boolean;
  onClick: (event: React.MouseEvent) => void;
}

const Checkbox: React.FC<Props> = ({ label, isChecked, onClick, multiSelect }) => (
  <Wrapper onClick={(event) => onClick(event)} multiSelect={multiSelect} isChecked={isChecked}>
    <StyledCheckbox isChecked={isChecked} />
    {label}
  </Wrapper>
);

export default Checkbox;