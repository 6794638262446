import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

type Props = {
  image: any,
  image_mobile: any
}

const Style = styled.section<Props>`

  & .container div.svg, .container div.button {
    display: none;
  }

  & {
    color: #ffffff;
    height: 870px;  
    background: radial-gradient(83.3% 128.75% at 73.49% 33.81%,rgba(0,0,0,0) 37.75%,#000 100%);  
    background-color: #333;   
    background-image: ${props => `url('${props.image}')`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  & .begin_wrap {
    height: 100%;
  }

  &  .container {
    width: 1130px;
    max-width: 100%;
    margin: 0 auto;
    padding: 210px 0;
    box-sizing: border-box;
  }

  & .logo1 {
    width: 64px;
    height: 64px;
  } 

  & .logo2 {
    width: 40px;
    height: 64px;
  }

  & .logo3 {
    width: 172px;
    height: 64px;
  }

  & .container div.date {
    margin-top: 16px;
    box-sizing: border-box;
    font-family: Gerbera Medium, sans-serif;
    font-size: 48px;
    line-height: 124%;
    color: #FFFFFF;
  }

  & .container .name {
    margin-top: 40px;

    & * {
      font-family: Gerbera Medium, sans-serif;
      font-size: 96px;
      font-weight: normal;
      line-height: 125%;
      color: #FFFFFF;
    }
  }

  & .container > p {
    margin-top: 20px;
    font-family: Gerbera Medium, sans-serif;
    font-size: 96px;
    line-height: 125%;
    color: #fff;
  }

  & .container span {
    margin-top: 16px;
    display: flex;
    font-family: Gerbera Regular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 124%;
    color: #FFFFFF;
  }

  & .container button {
    margin: 0 0 15px;
    color: #000000;
    background-color: #FFFFFF;   
    line-height: 100%;
    text-align: center;
  }

  & .container button:hover {
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid black;
  }

  & .container button.friend{
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #FFFFFF;
  }

  & .container button.friend:hover{
    color: #000000;
    background-color: #FFFFFF;
  }

  ${StyleMediaMax.tablet} {
    & .container button {
      width: 288px;
      height: 60px;
      font-size: 20px;
      border-style: solid !important;
    }
    & .container button.friend {
      margin-left: 24px;
    }
  }

  ${StyleMediaMax.tabletS} {
    & {
      overflow: hidden;
      background-position: 70% 15%;
      margin: 0 auto;
    }
    & .container button {
      width: 226px;
      height: 48px;
    }
    & .begin_wrap .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: 100%;
      flex-direction: column;
      padding: 0;
    }

    & .logo1 {
      width: 50px;
      height: 50px;
    }

    & .logo2 {      
      height: 50px;
      width: auto;
    }

    & .logo3 {     
      height: 50px;
      width: auto;
    }

    & .container div.date {
      box-sizing: border-box;
    }

    & .container .name {
      margin-top: 32px;
    }
    
    & .container > p {
      margin: 20px 0 24px;
    }
    
    & .container span {
      padding-top: 20px;
      margin: 0 0 80px;
    }

    .container div.button {
      display: block;
    }
  }

  ${StyleMediaMax.mobileL} {
    & {
      padding-top: 160px;
      height: 720px;
    }

    & .logo1 {
      width: 32px;
      height: 32px;
    }

    & .logo2 {
      width: 19px;
      height: 31px;
    }

    & .logo3 {
      width: 84px;
      height: 31px;
    }

    & .container div.date {
      font-size: 32px;
    }

    & .container .name {
      margin-top: 21px;

      & * {
        font-size: 48px;
      }
    }
    
    & .container > p {
      margin: 0;
      font-size: 48px;
    }

    & .container span {
      font-size: 24px;
      margin-bottom: 60px;
    }

    & .container button {
      margin: 0 40px 0 0;
    }
    & .container button.friend {
      margin-left: 0;
    }
  }

  ${StyleMediaMax.mobileM} {
    & {
      background-image: ${props => `url('${props.image_mobile}')`};
      background-size: cover;
    }
    & .container button {
      width: 192px;
      height: 40px;
      font-size: 16px;
      margin: 0 40px 0 0;
    }
  }

  ${StyleMediaMax.mobile} {
    & {
      padding-top: 0;
    }
    & .container .name {
      & * {
        font-size: 32px;
      }
    }
    & .container > p {
      font-size: 32px;
    }
    & .container div.date {
        font-size: 24px;
    }
    & .container span {
      font-size: 16px;    
    }
    & .container div.button {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    & .container button {
      width: 288px;
      margin: 8px 0;
    }
  }
`;

export default Style;