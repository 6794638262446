import styled from 'styled-components';

interface Props{
  type: string;
}

const Radio = styled.input.attrs(() => ({ type: "radio"}))<Props>`
  display: none;
  cursor: pointer;
  &:checked + div {
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 7px;
    width: 13px;
    height: 13px;
    background-color: ${props => props.theme.color.lightBlue};
  }
`;

export default Radio;