import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import { ParagraphTitle } from "@app/components/PageLayout";

interface Props {
  paddingMobile?: string;
}

const StyledParagraphTitle = styled(ParagraphTitle)<Props>`
  cursor: pointer;

  ${StyleMediaMax.mobileM} {
    padding: ${props => props.paddingMobile ? props.paddingMobile : undefined};
  }
`;

export default StyledParagraphTitle;
