import React from 'react';
import { FormWrapper, Title } from './Style';
import Button from '../Button';
import { NavLink } from 'react-router-dom';

interface IForm {
  children: any;
  titleForm: string;
  submit: () => void;
  padding?: string;
  formTheme?: 'white' | 'black';
  setModal?: ({show, url, type}: { show: boolean, url: string, type: string }) => void;
};

const Form = ({ children, titleForm, submit, padding, formTheme = 'black', setModal }: IForm) => {
  return (
    <FormWrapper padding={padding} formTheme={formTheme}>
      <Title formTheme={formTheme} className='event-color event-title form-title'>
        {titleForm}
        <div>
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setModal && setModal({ show: false, url: '', type: '' })}
          >
            <path fillRule="evenodd" clipRule="evenodd" d="M25.1618 2.53486C25.6304 2.06623 25.6304 1.30643 25.1618 0.8378C24.6932 0.369171 23.9334 0.369171 23.4647 0.8378L12.9998 11.3027L2.53483 0.8378C2.0662 0.369171 1.30641 0.369171 0.837778 0.8378C0.369148 1.30643 0.369148 2.06623 0.837778 2.53486L11.3027 12.9998L0.837312 23.4652C0.368682 23.9338 0.368682 24.6936 0.837311 25.1623C1.30594 25.6309 2.06574 25.6309 2.53437 25.1623L12.9998 14.6969L23.4652 25.1623C23.9338 25.6309 24.6936 25.6309 25.1623 25.1623C25.6309 24.6936 25.6309 23.9338 25.1623 23.4652L14.6968 12.9998L25.1618 2.53486Z" fill="white"/>
          </svg>
        </div>
      </Title>

      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          submit();
        }}
      >
        {children}

        <div className='form-submit'>
          <Button
            type='submit'
            text='Отправить'
            classes='event-paragraph__small'
          />
        </div>

        <div className='form-condition'>
          Нажимая кнопку «Отправить» я принимаю условия <br /><NavLink className='event-color' to='/policy'>Политики Конфиденциальности</NavLink>
        </div>
      </form>
    </FormWrapper>
  );
};

export default Form;
