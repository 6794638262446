import React from 'react';
import { BreadcrumbContext, BreadcrumbContextProvider } from './BreadcrumbProvider';

interface Props {
  Component: React.FC<any> | React.FC<BreadcrumbContextProvider>;
}

const WithBreadcrumb: React.FC<Props> = ({Component, ...props}) => (
<BreadcrumbContext.Consumer>{
    (context: BreadcrumbContextProvider) => (
      <Component
        {...context}
        {...props}
      />
    )}
</BreadcrumbContext.Consumer>
);

export default WithBreadcrumb;