import React, { useState } from 'react';
import * as AboutElement from './Style';
import { digits } from './data';
import ReactHtmlParser from 'react-html-parser';
import AboutImageTab from './AboutImageTab';
import AboutListTab from './AboutListTab';
import { TLandingType } from '../../types';
import tabTeam from './assets/tab_team.jpg';
import tabJob from './assets/tab_job.jpg';
import tabTalant from './assets/tab_talant.jpg';

const getImage = (image: string) => {
  switch (image) {
    case 'job':
      return tabJob;
    case 'team':
      return tabTeam;
    case 'talant':
      return tabTalant;
  }
};

const About = ({ type, tabs, color }: { type: TLandingType, tabs: any, color?: string }) => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <AboutElement.Wrapper id='about-company'>
      <AboutElement.Title className='event-title'>
        Orion Innovation <br />в цифрах
      </AboutElement.Title>
      <AboutElement.DigitsWrapper>
        {digits.map((digit) => (
          <AboutElement.DigitsElement key={digit.id}>
            <h1 className='event-color digit__title event-title'>{digit.title}</h1>
            <p className='digit__description event-paragraph__small'>{ReactHtmlParser(digit.description)}</p>
          </AboutElement.DigitsElement>
        ))}
      </AboutElement.DigitsWrapper>
      <AboutElement.TabsWrapper>
        <AboutElement.TabsContentWrapper>
          <AboutElement.TabsScrollWrapper>
            <AboutElement.TabsElementWrapper>
              {tabs.map((tab: any) => (
                <AboutElement.TabElement
                  className='event-paragraph__small'
                  key={tab.id}
                  onClick={() => tab.id !== currentTab && setCurrentTab(tab.id)}
                  active={currentTab === tab.id}
                  color={color}
                >
                  {tab.title}
                </AboutElement.TabElement>
              ))}
            </AboutElement.TabsElementWrapper>
          </AboutElement.TabsScrollWrapper>
          <AboutElement.TabContent>
            {tabs[currentTab].type === 'default' && (
              <>
                <div className='tab-content__image'>
                  <img src={getImage(tabs[currentTab].image)} alt="tab" />
                </div>
                <div className='tab-content__text'>
                  <div className='content__title event-paragraph__large'>
                    {tabs[currentTab].title}
                  </div>
                  <div className='content__description event-paragraph__middle'>
                    {ReactHtmlParser(tabs[currentTab].description)}
                  </div>
                </div>
              </>
            )}

            {tabs[currentTab].type === 'imagetab' && <AboutImageTab data={tabs[currentTab].data} type={type} />}

            {tabs[currentTab].type === 'list' && <AboutListTab data={tabs[currentTab].data} type={type} />}
          </AboutElement.TabContent>
        </AboutElement.TabsContentWrapper>
      </AboutElement.TabsWrapper>
    </AboutElement.Wrapper>
  );
};

export default About;
