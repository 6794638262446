import React from 'react';
import * as HeaderElement from './Style';
import HeaderLogo from '@app/components/Header/HeaderLogo';
import MobileNavigation from './MobileNavigation';
import { TLandingType, TLinks } from '../../types';

const Header = ({ links, background, type }: { links: TLinks, background: string, type: TLandingType }) => (
  <HeaderElement.Wrapper background={background} type={type}>
      <HeaderElement.Navigation>
        <HeaderLogo to='/' />

        <HeaderElement.NavigationLinks type={type}>
          <HeaderLogo to='/' />

          {links.map((link) => (
            <p
              className='event-paragraph__middle'
              key={link.id}
              onClick={() => {
                const el = document.getElementById(link.sectionId);
                el && el.scrollIntoView();
              }}
            >
              {link.name}
            </p>
          ))}
        </HeaderElement.NavigationLinks>

        <MobileNavigation links={links} />
      </HeaderElement.Navigation>
    <HeaderElement.DescriptionWrapper>
      <div>
        <HeaderElement.Title type={type}>
          Партнер <br />{type} 2021: <br />
          Центр разработки<br />
          Orion Innovation
        </HeaderElement.Title>
        <HeaderElement.Description>
          <HeaderElement.DescriptionElement className='event-paragraph__middle'>
            Мы разрабатываем промышленные IT-решения для
            клиентов по всему миру. Создаем инновационные продукты
            с использованием современных подходов искусственного
            интеллекта, облачных, веб- и мобильных технологий.
          </HeaderElement.DescriptionElement>
        </HeaderElement.Description>
      </div>
    </HeaderElement.DescriptionWrapper>
  </HeaderElement.Wrapper>
);

export default Header;
