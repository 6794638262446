import React from 'react';

import { theme } from '@app/styles/theme';

import { Wrapper, Container, Title } from '@app/components/PageLayout';
import NumberContainer from '@app/components/pages/HomePage/widgets/CounterWidget/NumberContainer';
import AboutCounterWrapper from './AboutCounterWrapper';
import CounterItem from '@app/components/pages/HomePage/widgets/CounterWidget/CounterItem';
import DescriptionContainer from '@app/components/pages/HomePage/widgets/CounterWidget/DescriptionContainer';
import SubDescription from './SubDescription';
import MobileDescription from './MobileDescription';

const COLOR = theme.color.primaryColorGrey;
const BORDER = theme.color.borderLight;
const BACKGROUND = theme.color.breadCrumbsText;
const IS_ABOUT = true;

const CounterWidget = () => (
  <Wrapper background={COLOR}>
    <Container borderColor={BORDER}>
      <Title position="absolute" color={COLOR} margin="0 0 60px" paddingTablet="0 60px 0 0" paddingMobile="0 20px 0 0">Mera NN (An Orion Innovation company)</Title>
      <AboutCounterWrapper>
        <CounterItem borderColor={BORDER} paddingTop="165px" isAbout={IS_ABOUT}>
          <NumberContainer showMobileBorder color={COLOR} backgroundColor={BACKGROUND} padding="0 0 0 15px" marginTablet="0 0 0 -1px">1200+</NumberContainer>
          <DescriptionContainer hideOnMobile borderColor={COLOR} color={COLOR}>IT engineers</DescriptionContainer>
          <MobileDescription borderColor={COLOR} color={COLOR}>IT engineers</MobileDescription>
          <SubDescription mobilePadding="20px 0 10px 15px">18% - Managers <br /> 32% - Senior <br /> 31% - Middle <br /> 19% - Junior </SubDescription>
        </CounterItem>
        <CounterItem borderColor={BORDER} borderMobile="true" isAbout={IS_ABOUT}>
          <NumberContainer showMobileBorder color={COLOR} backgroundColor={BACKGROUND} padding="0 0 0 15px" marginTablet="0 0 0 -1px">20+</NumberContainer>
          <DescriptionContainer hideOnMobile borderColor={COLOR} color={COLOR}>programming <br /> languages</DescriptionContainer>
          <MobileDescription borderColor={COLOR} color={COLOR}>programming languages</MobileDescription>
          <SubDescription mobilePadding="20px 0 10px 15px">75% – software development <br /> 25% – sustain and bugfix </SubDescription>
        </CounterItem>
        <CounterItem borderColor={BORDER} borderMobile="true" isAbout={IS_ABOUT}>
          <NumberContainer showMobileBorder tablet color={COLOR} backgroundColor={BACKGROUND} padding="0 0 0 15px" marginTablet="0 0 0 -62px">150+</NumberContainer>
          <DescriptionContainer hideOnMobile borderColor={COLOR} color={COLOR}>projects with different level of responsibility</DescriptionContainer>
          <MobileDescription borderColor={COLOR} color={COLOR}>projects with different level of responsibility</MobileDescription>
          <SubDescription mobilePadding="20px 0 70px 15px">Job rotation program, internal talent pool, and freelance opportunities </SubDescription>
        </CounterItem>
        <CounterItem borderColor={BORDER} borderMobile="true" marginRight="60px">
          <NumberContainer last={true} backgroundColor={BACKGROUND} marginTablet="0 0 0 -1px"/>
        </CounterItem>
      </AboutCounterWrapper>
    </Container>
  </Wrapper>
);

export default CounterWidget;
