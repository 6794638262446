import styles from 'styled-components';

const Wrapper = styles.div`
  width: 100%;
  padding-top: 160px;

  @media (max-width: 768px) {
    padding-top: 120px; 
  }

  @media (max-width: 420px) {
    padding-bottom: 0 !important;
  }
`;

const Title = styles.h1`
  max-width: 1128px;
  margin: 0 auto;

  br {
    display: none;
  }

  @media (max-width: 810px) {
    br {
      display: block;
    }
  }

  @media (max-width: 1128px) {
    padding: 0 24px;
  }

  @media (max-width: 420px) {
    margin: 0;
  }
`;

const DigitsWrapper = styles.div`
  max-width: 1128px;
  width: 100%;
  display: flex;
  margin: 64px auto;
  justify-content: space-between;

  @media (max-width: 1128px) {
    padding: 0 24px;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    margin: 60px 0;

    & > div:nth-child(1), & > div:nth-child(2) {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 420px) {
    margin: 48px auto 24px;
  }
`;

const DigitsElement = styles.div`
  max-width: 264px;
  flex: 1 1 auto;

  .digit__title {
    font-family: Gerbera Medium;
    margin-bottom: 12px;
  }

  .digit__description {
    @media (max-width: 420px) {
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    width: 50%;
    max-width: inherit;
  }
`;

const VideoWrapper = styles.div`
  position: relative;
  max-height: 630px;
  display: flex;
  margin: 0 auto;

  @media (max-width: 1128px) {
    padding: 0 24px;
  }

  video {
    z-index: 1;
    max-height: 630px;
    max-width: 1128px;
    width: 100%;
    margin: 0 auto;
    object-fit: cover;

    @media (max-width: 420px) {
      height: 175px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    height: 50%;
    width: 100%;
    background: #7B61FF;

    @media (max-width: 1128px) {
      left: 0;
    }
  }
`;

const TabsWrapper = styles.div`
  padding: 96px 0 140px;

  @media (max-width: 1128px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 768px) {
    padding: 80px 24px 120px;
  }
`;

const TabsContentWrapper = styles.div`
  max-width: 1128px;
  margin: 0 auto;
`;

const TabsElementWrapper = styles.div`
  display: flex;
  width: 100%;

  @media (max-width: 1128px) {
    position: absolute;
    width: 1100px;
    align-items: center;
  }

  @media (max-width: 420px) {
    .event-paragraph__small {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

interface ITabElement {
  active: boolean;
  color?: string;
};

const TabElement = styles.div<ITabElement>`
  height: 48px;
  display: flex;
  flex: 1 1 auto;
  cursor: pointer;
  color: black;
  touch-action: pan-x;
  border-bottom: 1px solid rgba(0, 0, 0, .16);

  ${props => props.active && `
    border-bottom: 3px solid ${props.color};
  `}
`;

const TabContent = styles.div`
  height: 400px;
  display: flex;
  background-color: white;

  @media (max-width: 960px) {
    height: auto;
  }

  @media (max-width: 768px) {
    height: 375px;
  }

  @media (max-width: 760px) {
    height: auto;
  }

  @media (max-width: 420px) {
    height: 440px;
  }
  
  .tab-content__image {
    width: 40%;
    margin: 48px 120px 0 0;

    @media (max-width: 1128px) {
      margin: 24px 24px 0 0;
    }

    @media (max-width: 960px) {
      margin-right: 20px;
    }

    @media (max-width: 768px) {
      height: 275px;
    }

    @media (max-width: 700px) {
      display: none;
    }

    img {
      width: 100%;

      @media (max-width: 768px) {
        height: 275px;
      }
    }
  }

  .tab-content__text {
    width: 60%;
    margin: 48px 0 0;

    @media (max-width: 1128px) {
      margin: 24px 0 0;
    }

    @media (max-width: 700px) {
      width: 100%;
    }

    @media (max-width: 500px) and (min-width: 401px) {
      .event-paragraph__middle {
        font-size: 18px;
      }
    }

    .content__title {
      margin-bottom: 24px;

      @media (max-width: 420px) {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .content__description {
      max-width: 545px;

      @media (max-width: 700px) {
        max-width: inherit;
      }

      @media (max-width: 420px) {
        font-size: 16px !important;
        line-height: 24px !important;
      }
    }
  }
`;

const AboutImageTabWrapper = styles.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 48px 0 0;

  & > div {
    max-height: 175px;
    width: 33%;

    .image-tab__description {
      @media (max-width: 540px) {
        font-family: Gerbera Light;
        font-size: 20px;
        line-height: 20px;
      }

      @media (max-width: 480px) {
        font-size: 16px;
      }
    }

    @media (max-width: 960px) {
      flex-wrap: wrap;
    }
  }

  & > div:first-child {
    margin-bottom: 55px;

    @media (max-width: 960px) {
      margin-bottom: 36px;
    }

    @media (max-width: 420px) {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 960px) {
    padding: 36px 0 0;

    & > div:not(last-child) {
      margin-bottom: 36px;
    }
  }

  @media (max-width: 768px) {
    padding: 24px 0 0;
    flex-direction: row;

    & > div:nth-child(2) {
      order: 5;
    }

    & > div:nth-child(3) {
      order: -2;
    }

    & > div {
      img {
        height: 64px;
        width: 64px;
      }
    }
  }

  @media (max-width: 560px) {
    padding: 20px 0 0;
    justify-content: space-between;

    & > div {
      img {
        height: 56px;
        width: 56px;
      }
    }

    & > div:not(last-child) {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 420px) {
    & > div {
      width: 100%;
      display: flex;
      align-items: center;

      img {
        margin-right: 25px;
      }
    }

    & > div:last-child {
      margin-bottom: 0;
    }
  }
`;

const AboutListTabWrapper = styles.div`

`;

interface IAboutListTabTitle {
  title: string;
};

const AboutListTabTitle = styles.p<IAboutListTabTitle>`
  ${props => !props.title && `
    height: 32px;

    @media (max-width: 420px) {
      height: 20px;
    }
  `}
`;

const TabsScrollWrapper = styles.div`
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1128px) {
    position: relative;
    height: 63px;
    overflow-x: scroll;
  }

  @media (max-width: 420px) {
    &, & > div {
      height: 48px;
    }
  }
`;

export {
  Wrapper,
  Title,
  DigitsWrapper,
  DigitsElement,
  VideoWrapper,
  TabsWrapper,
  TabsContentWrapper,
  TabsElementWrapper,
  TabElement,
  TabContent,
  AboutImageTabWrapper,
  AboutListTabWrapper,
  AboutListTabTitle,
  TabsScrollWrapper
};
