import styled from "styled-components";
import { StyleMediaMax } from '@app/components/StyleMedia';
import zoomIcon from '@assets/icons/zoom_icon.svg';

interface Props {
  imgUrl: string;
  tabletImgUrl: string;
}

const ImageContainer = styled.div<Props>`
  width: 1170px;
  height: 856px;
  background-image: ${props => `url(${props.imgUrl})`};
  margin: 40px 0;

  ${StyleMediaMax.tablet} {
    width: calc(100vw - 120px);
    height: calc((100vw - 120px) / 1.82);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${props => `url(${props.tabletImgUrl})`};
  }

  ${StyleMediaMax.mobileM} {
    width: calc(100vw - 40px);
    height: calc((100vw - 120px) / 1.4);
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      display: block;
      height: 18px;
      width: 18px;
      background: url(${zoomIcon}) no-repeat center;
    }
  }
`;

export default ImageContainer;