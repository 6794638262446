import React, {useContext} from 'react';
import FooterWrapper from './FooterWrapper';
import ContactFormSection from './ContactFormSection';
import SocialMediaSection from './SocialMediaSection';
import ContactFormAboutUs from './ContactFormAboutUs';
import SocialMediaAboutUs from './SocialMediaAboutUs';
import LandingContext from "@app/components/Builder/LandingContext";

const Footer = () => {
    const {isLanding} = useContext(LandingContext);
    return (
        <FooterWrapper id="footer">
            {isLanding
                ? isLanding === 'AboutUs'
                    ? (<>
                        <ContactFormAboutUs />
                        <SocialMediaAboutUs />
                    </>)
                    : null
                : (<>
                    <ContactFormSection />
                    <SocialMediaSection />
                </>)
            }
        </FooterWrapper>
    );
}

export default Footer;