import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none; 
`;

export default Wrapper;