import styled from 'styled-components';
import { Wrapper } from '@app/components/PageLayout';
import { StyleMediaMax } from '@app/components/StyleMedia';

const IntroductionWrapper = styled(Wrapper)`
  ${StyleMediaMax.mobileM} {
    padding-bottom: 50px;
  }  
`;

export default IntroductionWrapper;