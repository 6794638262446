import styled from 'styled-components';
import { StyleMediaMin } from '@app/components/StyleMedia';

interface Props {
  top?: string;
  padding?: string;
  left?: string;
}

const Container = styled.div<Props>`
  ${StyleMediaMin.tablet} {
    position: absolute;
    top: 100%;
    left: ${props => props.left ? props.left : "0"};
    width: 100%;
    padding: ${props => props.padding ? props.padding : "20px"};
    margin-top: ${props => props.top ? props.top : '15px'};
    display: flex;
    z-index: 3;
    box-shadow: ${props => `0 4px 10px 0 ${props.theme.color.shadow}`};
    background-color: ${props => props.theme.color.white};
  }

`;

export default Container;