import styled from 'styled-components';
import StyledTextarea from '@app/components/Form/Textarea/StyledTextarea';
import { FormContainer } from '@app/components/Form';
import { StyleMediaMax } from '@app/components/StyleMedia';

const FooterWrapper = styled.footer`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => props.theme.color.white};

  ${StyleMediaMax.tablet} {
    ${FormContainer} {
      width: calc(100vw - 120px);

      ${StyledTextarea} {
        width: 100%;  
      }
    }
  }

  ${StyleMediaMax.mobileM} {
    ${FormContainer} {
      width: calc(100vw - 40px);
    }
  }
`;

export default FooterWrapper;