import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import MobileButton from '@app/components/Buttons/MobileButton';

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 60px;
  display: none;
  z-index: 10;
  background-color: ${props => props.theme.color.black};
  box-shadow: ${props => `0 8px 20px 0 ${props.theme.color.shadow}`};
  padding: 0 20px;

  ${StyleMediaMax.tablet} {
    display: flex;

    ${MobileButton} {
      margin-right: 0;
    }
  };
`;

export default Wrapper;