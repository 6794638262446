import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import NavigationMenuWrapper from '@app/components/NavigationMenuAboutUs/Wrapper';

const ScrollContainer = styled.div`
  ${StyleMediaMax.tablet} {
    overflow-y: scroll;
    -webkit-overflow-scrolling: initial;
    height: calc(100vh - 60px);
    position: fixed;
    top: 59px;
    left:0;

    ${NavigationMenuWrapper}{
      position: initial;
      margin: 0;
      height: unset;
      min-height: 100%;
      border: 0;
    }

    
  }
`;

export default ScrollContainer;
