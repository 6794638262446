import styled from 'styled-components';

interface Props {
  alignCenter?: boolean;
  color?: string;
}

const AcceptText = styled.p<Props>`
  text-align: ${p => p.alignCenter ? "center" : "left"};
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.1px;
  color: ${props => props.color? props.color : undefined};
  a {
    color: ${props => props.color? props.color : undefined};
  }
`;

export default AcceptText;