import styled from "styled-components";
import LoadingElement from "@app/components/LoadingButton/LoadingElement";
import blue_spinner from "@assets/icons/blue_spinner.svg";

const LoadElement = styled(LoadingElement).attrs(() => ({
  icon: blue_spinner,
}))`
  width: 50px;
  background-size: contain;
`;

export default LoadElement;