import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const AwardWrapper = styled.div`
  display: flex;
  margin-top: 60px;

  ${StyleMediaMax.mobileM} {
    margin-top: 40px;
    flex-direction: column;
  }
`;

export default AwardWrapper;