import styled from 'styled-components';
import ExternalLinkButton from './ExternalLinkButton';

const BlueExternalLinkButton = styled(ExternalLinkButton)`
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.black};

  :hover {
    color: ${props => props.theme.color.white};
    background-color: ${props => props.theme.color.blackButtonHover};
  }
`;

export default BlueExternalLinkButton;
