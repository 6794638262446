import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import CrumbRoute from '@app/components/CrumbRoute';
import HomePage from '@app/components/pages/HomePage';
import AboutPage from '@app/components/pages/AboutPage';
import VacancyPage from '@app/components/pages/VacancyPage';
import ProjectPage from '@app/components/pages/ProjectPage';
import MeetupsPage from '@app/components/pages/MeetupsPage';
import ContactsPage from '@app/components/pages/ContactsPage';
import NewsPage from '@app/components/pages/NewsPage';
import PolicyPage from '@app/components/pages/PolicyPage';
import NotFoundPage from "@app/components/pages/NotFoundPage";
import CareerRouter from './CareerRouter';
import JavaHiring from '@app/components/pages/JavaHiring';
import Holyjs from '@app/components/pages/Conferences/Holyjs';
import AboutUsPage from '@app/components/pages/AboutUsPage';
import ProductsPage from '@app/components/pages/ProductsPage';
import SmartHeatingSystem from '@app/components/pages/ProductsPage/SmartHeatingSystem';
import useAxiosFetch from '@app/api/useAxiosFetch';

export interface IDataStatus {
  published: boolean;
  link: string;
  description: string;
  main_title: string;
  mobile_title: string;
  btn_text: string;
};

export interface IStatus {
  data: undefined | [IDataStatus];
  loading: boolean;
  error: boolean;
  errorMessage: null | string;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const PagesRouter = () => {
  const [pathLanding, setPathLanding] = useState('');
  const {
    data = [{ published: false, link: '', description: '', main_title: '', mobile_title: '', btn_text: '' }],
    loading
  }: IStatus = useAxiosFetch('landing/status');

  useEffect(() => {
    if (data.length && data[0].published && data[0].link.length) {
      setPathLanding(`/${data[0].link}`);
    }
  }, [data]);

  return (
    <Switch>
      <Route exact path="/" pathName="Главная" render={() => <HomePage data={data[0]} loading={loading} />} />
      <Route exact path="/404" pathName="Главная" component={NotFoundPage} />
      <CrumbRoute exact path="/about" pathName="О нас" Component={AboutPage} />
      <CrumbRoute path="/vacancy" pathName="Вакансии" Component={VacancyPage} />
      {/* <CrumbRoute path="/projects" pathName="Проекты и технологии" Component={ProjectPage} /> */}
      {/* <CrumbRoute path="/meetups" pathName="Митапы" Component={MeetupsPage} /> */}
      <CrumbRoute exact path="/contacts" pathName="Контакты" Component={ContactsPage} />
      <CrumbRoute path="/news" pathName="Новости" Component={NewsPage} />
      {/* <CrumbRoute path="/career" pathName="Карьера в компании" pathUrl="/career/employment" Component={CareerRouter} /> */}
      <CrumbRoute path="/policy" pathName="Политика конфиденциальности" Component={PolicyPage} />
      <CrumbRoute exact path="/corp" pathName="About us" Component={AboutUsPage} />
      <CrumbRoute exact path="/products" pathName="Our products" Component={ProductsPage} />
      <CrumbRoute exact path="/products/heating" pathName='Програмный Комплекс "Умная Теплосеть"' Component={SmartHeatingSystem} />
      <Route exact path="/search_results" component={() => <h1>Search results</h1>} />
      <Route exact path='/holyjs/:section?' component={Holyjs} />
      <Route path="/https/:url" component={(props: any) => (
        <h1>Redirecting to https://{props.match.params.url}{window.location.replace(`https://${props.match.params.url}`)}</h1>
      )} />
      <Route exact path={pathLanding}>
        {!loading && (
          pathLanding ? <JavaHiring /> : <Redirect to='/404' />
        )}
      </Route>
      <Redirect to="/404" />
    </Switch>
  );
};

export default PagesRouter;
