import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from "react-router";
import NavigationMenuAboutUs from '@app/components/NavigationMenuAboutUs';
import mobile_menu from '@assets/icons/mobile_menu.svg';
import search_default from '@assets/icons/white_search.svg';
import close from '@assets/icons/white_close.svg';

import Wrapper from './Wrapper';
import HeaderMobileLogo from './HeaderMobileLogo';
import MobileButton from '@app/components/Buttons/MobileButton';
import Container from '@app/components/HeaderAboutUs/Container';
import SearchWrapper from '@app/components/HeaderAboutUs/SearchWrapper';
import Dropdown from '@app/components/Dropdown';
import ScrollContainer from './ScrollContainer';

const HeaderMobileAboutUs: React.FC<RouteComponentProps> = ({ location }) => {
  const [isOpen, setOpen] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const [linkTransition, setlinkTransition] = useState(location.pathname);
  const icon = isOpen ? close : mobile_menu;
  const position = "0 0";
  const resize = () => { setOpen(false); };

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  if (linkTransition !== location.pathname) {
    setlinkTransition(location.pathname);
    setOpen(false);
  }

  const dropdownContent = () => (
    <ScrollContainer>
      <NavigationMenuAboutUs />
    </ScrollContainer>
  );

  return (
    <Wrapper>
      <Container>
        <MobileButton
          onClick={() => {
            setSearch(!isSearch);
            setOpen(false);
          }} src={search_default} />
        <Dropdown
          content={
            <SearchWrapper
              closeSearch={() => setSearch(false)}
              focus={isSearch}
            />
          }
          isOpen={isSearch}
          onClose={() => setSearch(false)}
        />
        <HeaderMobileLogo to="/" onClick={() => setOpen(false)} />
        <MobileButton
          onClick={() => {
            setOpen(!isOpen);
          }} src={icon} position={position} />
        <Dropdown
          content={dropdownContent()}
          isOpen={isOpen}
          onClose={() => { }}
        />
      </Container>
    </Wrapper>
  );
}


export default withRouter(HeaderMobileAboutUs);