import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface ILandingBtn {
    typeBtn: 'submit' | 'recommend';
};

const LandingBtn = styled.button<ILandingBtn>`
    width: 226px;
    height: 48px;
    background: ${props => props.typeBtn === 'submit' ? '#111111' : 'transparent'};
    border-width: ${props => props.typeBtn === 'submit' ? '0' : '1px'};
    cursor: pointer;

    &:hover {
        background: ${props => props.typeBtn === 'submit' ? '#333333' : 'rgba(0, 0, 0, 0.1)'};
    }

    &:active {
        background: ${props => props.typeBtn === 'submit' ? '#27C3F3' : 'rgba(0, 0, 0, 0.12)'};
    }

    span {
        font-size: 20px;
        line-height: 160%;
        letter-spacing: -0.04em;
        color: ${props => props.typeBtn === 'submit' ? '#FFFFFF' : '#111111'};
    }

    ${StyleMediaMax.mobile} {
        display: block;
        margin: auto;
        width: 288px;
        height: 40px;
        span {
            font-size: 16px;
        }
    }
`;

export default LandingBtn;
