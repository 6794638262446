import styled from "styled-components";
import { Container } from "@app/components/PageLayout";
import { StyleMediaMax, StyleMediaMin } from '@app/components/StyleMedia';

const MainLoaderContainer = styled(Container)`

  ${StyleMediaMax.tablet} {
    ${StyleMediaMin.mobileM}{
      margin: auto;
    }
  }
`;

export default MainLoaderContainer;