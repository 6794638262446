import styled from 'styled-components';
import { Title } from '@app/components/PageLayout';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  border?: string;
  borderColor?: string;
  borderMobile?: string;
  paddingTablet?: string;
  paddingTop?: string;
  margin?: string;
  isAbout?: boolean;
  marginRight?: string;
}

const CounterItem = styled.div<Props>`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.isAbout? '0px':'222px'};
  border-left: ${props => props.borderColor ? `1px solid ${props.borderColor}` : `1px solid ${props.theme.color.borderLight}`};
  &:first-child {
    border: none;
    padding-top: ${props => props.isAbout? '0px':'120px'};
  }
  &:nth-child(2) {
    margin-left: -1px;
  }

  ${Title} {
    height: 2.7rem;
  }

  ${StyleMediaMax.tablet} {
    padding-top: 60px;
    border-left: ${props => props.borderColor ? `1px solid ${props.borderColor}` : `1px solid ${props.theme.color.borderLight}`};

    ${Title} {
      margin: -105px 0 0;
    }

    &:first-child {
      padding-top: ${props => props.margin ? `220px` : 0};
      margin-left: 0;
    }
    &:nth-child(2) {
      padding-top: ${props => props.paddingTablet ? `${props.paddingTablet}` : `0`};
      margin-left: 0;
    }
    &:nth-child(3) {
      border: none;
    }
    &:nth-child(4) {
      margin-right: ${props => props.marginRight ? `${props.marginRight}` : `60px`};
    }
  }

  ${StyleMediaMax.mobileM} {
    padding-top: ${props => props.paddingTop ? `${props.paddingTop}` : `30px`};
    margin: ${props => props.margin ? `${props.margin}` : undefined};
    border: ${props => props.borderMobile ? `none` : props.border ? `${props.border}` : undefined};

    &:first-child {
      padding-top: ${props => props.paddingTop ? `${props.paddingTop}` : 0};
      margin-left: 0;
    }
    &:nth-child(2) {
      padding-top: 0;
      margin-left: 0;
    }
    &:nth-child(3) {
      border: none;
      border-left: 1px solid #e1e8ed;
    }
    &:nth-child(4) {
      margin-right: 20px;
    }
  }
`;

export default CounterItem;
