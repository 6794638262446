import styled from 'styled-components';
import { StyleMediaMax, StyleMediaMin } from '@app/components/StyleMedia';

const Wrapper = styled.div`
  background-color: ${props => props.theme.color.black};
  display: flex;
  justify-content: center;
  border-top: 1px solid ${props => props.theme.color.primaryColorGrey};

  br {
    display: none;
  }

  ${StyleMediaMax.tablet} {
    ${StyleMediaMin.mobileM} {
      br {
        display: block;
      }
    }
  }
`;

export default Wrapper;