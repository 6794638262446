import styles from 'styled-components';

interface ILabelInput {
  isValue: string;
};

interface IInputWrapper {
  width?: string;
};

const InputWrapper = styles.div<IInputWrapper>`
  width: ${props => props.width || '100%'};
  &[data-failure="true"] {
    label {
      color: #cd2026 !important;
    }

    input {
      border-color: #cd2026 !important;
    }
  }

  input {
    width: 100%;
    padding: 10px 8px;
    border: none;
    background: transparent;
    border-bottom: 3px solid rgba(255, 255, 255, .3);
    outline: none;
    color: white;

    &::placeholder {
      font-size: 20px;
      line-height: 140%;
      color: white;
    }
  }
`;

const LabelInput = styles.label<ILabelInput>`
  margin-left: 10px;
  opacity: 0;
  transition: all .2s ease-in-out;
  
  ${props => props.isValue && `
    opacity: 1;
  `}
`;

export { InputWrapper, LabelInput };
