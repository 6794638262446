import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import { Title } from '@app/components/PageLayout';

const MobileTitle = styled(Title)`
  display: none;

  ${StyleMediaMax.mobileM} {
    display: block;
    font-size: 24px;
    line-height: 1.42;
    padding-top: 20px;
  }
`;

export default MobileTitle;