import { createGlobalStyle } from 'styled-components'


function fontFace400(){
    return `
      @font-face{
          font-family: "Gerbera";
          src: url(${require('../assets/fonts/Gerbera-Regular.otf')});
          font-weight: 400;
      }
  `;
}

function fontFace500(){
    return `
      @font-face{
          font-family: "Gerbera";
          src: url(${require('../assets/fonts/Gerbera-Medium.otf')});
          font-weight: 500;
      }
  `;
}

function fontFace600(){
    return `
      @font-face{
          font-family: "Gerbera";
          src: url(${require('../assets/fonts/Gerbera-Bold.otf')});
          font-weight: 600;
      }
  `;
}

const GlobalStyle = createGlobalStyle`

  ${fontFace400()}
  ${fontFace500()}
  ${fontFace600()}

  * {
    padding: 0;
    margin: 0;
    font-family: 'Gerbera', sans-serif;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  #root {
    height: 100vh;
  }

  body {
    font-weight: 500;
    line-height: 1.63;
    font-size: 16px;
  }

  h1 {
    font-size: 50px;
    font-weight: 600;
  }

  h2 {
    font-size: 34px;
    font-weight: 600;
    line-height: 1.29;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.42;
  }

  h4 {
    font-size: 16px;
    font-weight: 600;
  }

  a {
    color: ${props => props.theme.color.lightBlue};
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

`;

export default GlobalStyle;
