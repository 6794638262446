import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Style= styled.section`
  & {
    max-width: 1200px;
    margin: 0 auto;
    padding: 160px 0;
    box-sizing: border-box;
    position: relative;
  }

  .candidate-section-title {
    margin-bottom: 0;
    padding-bottom: 20px;
    font-family: Gerbera Medium, sans-serif;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #111111;
    border-bottom: 2px solid #BDBDBD;
  }

  .candidate-wrapper {
    display: flex;
    flex-wrap: wrap;

    .candidate-card {
      display: flex;
      justify-content: space-between;
      padding: 48px 0;
      border-bottom: 2px solid #BDBDBD;
      width: 100%;

      .candidate-title {
        max-width: 45%;
        font-family: Gerbera Regular, sans-serif;
        font-size: 32px;
        line-height: 124%;
        letter-spacing: -0.04em;
        color: #111111;
      }

      .candidate-description {
        max-width: 50%;

        .candidate-description__list {

          li {
            font-family: Gerbera Light, sans-serif;
            font-size: 20px;
            line-height: 160%;
            letter-spacing: -0.04em;
            color: #333333;
          }
        }
      }
    }
  }

  .candidate-btns {
    margin-top: 80px;

    & > button:first-child {
      margin-right: 40px;
    }
  }
  
  ${StyleMediaMax.mobileL} {
    & {
      padding: 0 24px;
      margin: 80px auto;
    }
  }

  & h2 {
    font-weight: bold;
    font-size: 60px;
    line-height: 100%;
    color: #3B3E48;
    margin-bottom: 80px;
  }

  ${StyleMediaMax.tabletS} {
    & h2 {

      margin-bottom: 80px;
    }
  }
  
  ${StyleMediaMax.mobileL} {
    & h2 {
      font-weight: normal;
      font-size: 16px !important;
    }
  }

  & > div {
    display: flex;
  }

  & > div > div > p {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    padding-left: 27px;
    font-size: 18px;
    line-height: 140%;
    color: #3B3E48;
    
    :before {
      position: absolute;
      left: 0px;
      content: '—';
    }
  }
  
  & div p:first-child {
    padding-left: 0px;
    :before {
      content: '';
    }
  }

  ${StyleMediaMax.tabletS} {
    & > div > div {
      margin: 0;
    }

    & > div > div > p {
      width: 530px;
      margin: 0 0 18px;

      :last-child {
        margin: 0;
      }
      :first-child {
        width: 456px;
      }
    }
  }

  ${StyleMediaMax.mobileL} {
    & > div > div {
      flex-direction: column;
      padding: 24px 0 !important;

      & > div {
        max-width: 100% !important;
        & > ul {
          margin-left: 20px;
        }
      }
      & > div:first-child {
        font-size: 24px !important;
        margin-bottom: 16px;
      }
    }

    & > div > div > p {
      font-size: 14px;
      line-height: 140%;
      margin: 0 0 14px;
      
      :last-child {
        margin: 0;
      }
    }
  }

  & img {
    width: 456px;
    height: 100%;
    margin-right: 120px;
  }

  ${StyleMediaMax.mobileM} {
    .candidate-btns button {
      width: 192px;
      height: 40px;
      span {
        font-size: 16px;
      }
    }
  }

  ${StyleMediaMax.mobile} {    
    & > div {
      flex-direction: column;
      align-items: center;
      & > button {
        margin: 8px 0 !important;
        width: 288px !important;
      }
    }
  }

  ${StyleMediaMax.tabletS} {
    & img {
      margin-bottom: 40px;
    }
  }  
`;

export default Style;