import React, { useState } from "react";

import { Container } from "@app/components/PageLayout";
import DropContainer from "./DropContainer";
import DropArrow from "./DropArrow";
import StyledParagraphTitle from "./StyledParagraphTitle";

interface Props {
  title: string;
  borderedBottom?: boolean;
  open?: boolean;
  paddingMobile?: string;
}

const DropDownContainer: React.FC<Props> = ({ children, title, open, paddingMobile }) => {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <DropContainer>
      <Container onClick={() => setIsOpen(!isOpen)} marginTablet="0" marginMobile="0">
        <StyledParagraphTitle>
          {title}
        </StyledParagraphTitle>
      <DropArrow isExpanded={isOpen} />
      </Container>
      {isOpen && children}
    </DropContainer>
  );
};

export default DropDownContainer;
