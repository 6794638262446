import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const RequestsContainer = styled.div`
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
  margin-left: 100px;
  color: ${props => props.theme.color.secondaryTextLight};

  ${StyleMediaMax.desktopS} {
    margin-left: 20px;
  }

  ${StyleMediaMax.laptop} {
    * {
      font-size: 12px;
    }
  }
`;

export default RequestsContainer;