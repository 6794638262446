import styled from "styled-components";
import arrowPlus from "@assets/icons/plus_icon.svg";
import arrowMinus from "@assets/icons/minus_icon.svg";

interface Props {
    isExpanded?: boolean;
}

const DropArrow = styled.div<Props>`
  height: 25px;
  width: 25px;
  background: url(${props => props.isExpanded ? arrowMinus : arrowPlus}) no-repeat;
  background-size: 118%;
  background-position: center;
  position: absolute;
  top: 30px;
  right: 19px;
  cursor: pointer;
`;

export default DropArrow;