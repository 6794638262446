import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  layerOne: string;
  layerTwo: string;
  margin?: string;  
  marginTablet?: string;
  marginMobile?: string;
}

const ImageWithText = styled.div<Props>`
  margin: ${props => props.margin ? props.margin : undefined};
  height: 337px;
  background: ${props => props.layerOne ? `url(${props.layerOne})` : undefined}, ${props => props.layerTwo ? `url(${props.layerTwo})` : undefined} no-repeat center;
  background-size: cover;

  ${StyleMediaMax.tablet}{
    margin: ${props => props.marginTablet ? props.marginTablet : undefined};
    height: auto;
  }

  ${StyleMediaMax.mobileM}{
    margin: ${props => props.marginMobile ? props.marginMobile : undefined};
    height: auto;
  }
`;

export default ImageWithText;
