import React, { useState } from 'react';

import useAxiosFetch from "@app/api/useAxiosFetch";
import NewsWidgetView from './NewsWidgetView';
import { options } from './NewsPage';

interface Props {
  pageNum: number;
  setPageNum: (page: number) => void;
  sortBy: string;
  setSortBy: (value: string) => void;
}

const NewsWidget: React.FC<Props> = ({ pageNum, setPageNum, sortBy, setSortBy }) => {
  const initialURL = sortBy === options[1] ? `news/?page=${pageNum}&ordering=-views` : `news/?page=${pageNum}&ordering=-date`;
  const [url, setUrl] = useState(initialURL);
  const { data = {}, loading, error, setReload } = useAxiosFetch(url);

  return (
    <NewsWidgetView
      isLoading={loading}
      isError={error}
      toggleReload={setReload}
      data={data}
      setUrl={setUrl}
      pageNum={pageNum}
      setPageNum={setPageNum}
      sortBy={sortBy}
      setSortBy={setSortBy}
    />
  )
};

export default NewsWidget;