import axios from "axios";
import { api } from "@app/api/api";
import { useState, useEffect } from "react";

const useAxiosFetch = (url: string, timeout?: number) => {
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    let unmounted = false;
    let source = axios.CancelToken.source();
    api.get(url, {
      cancelToken: source.token,
      timeout: timeout
    })
      .then(a => {
        if (!unmounted) {
          setData(a.data);
          setLoading(false);
        }
      }).catch(e => {
        if (!unmounted) {
          setError(true);
          setErrorMessage(e.message);
          setLoading(false);

          if (axios.isCancel(e)) {
            console.log(`request cancelled: ${e.message}`);
          } else {
            console.log(`another error happened: ${e.message}`);
          }
        }
      })
    if (reload) {
      setError(false);
      setLoading(true);
      setErrorMessage(null);
    }
    return () => {
      unmounted = true;
      source.cancel("Cancelling in cleanup");
      setReload(false);
    };
  }, [timeout, url, reload]);

  return { data, loading, error, errorMessage, setReload };
};

export default useAxiosFetch;