import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import { Container, ParagraphTitle, Title, Wrapper } from '@app/components/PageLayout';
import { theme } from '@app/styles/theme';

const PolicyPageWrapper = styled(Wrapper)`
  ${Title} {
    padding: 0 0 30px;
    border-bottom: 1px solid ${theme.color.borderGrey};
  }

  ${ParagraphTitle} {
    padding: 0 0 30px;
  }

  ${StyleMediaMax.tablet} {
    ${Container} {
      max-width: calc(100vw - 120px);
      margin: auto;
    }
  }

  ${StyleMediaMax.mobileM} {
    padding-top: 30px;

    ${Container} {
      max-width: calc(100vw - 40px);
    }
  }
`;

export default PolicyPageWrapper;