import styled from 'styled-components';

interface Props {
  color?: string;
}

const ExternalLink = styled.a.attrs(() => ({
  target: "_blank",
  rel: "noopener noreferrer",
}))<Props>`
  color: ${ props => props.color };
`;

export default ExternalLink;
