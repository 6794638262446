import React, { useEffect, useState } from 'react';
import Style from './Style';
import ReactHtmlParser from 'react-html-parser';
import { IDescriptionOfProject } from '../../types';

const DescriptionOfProject = ({ block_title, project_logo, project_left_text, project_right_text, project_top_text, technologies }: IDescriptionOfProject) => {
	const [techData, setTechData] = useState<{[key: string]: string[]}>();

	useEffect(() => {
		if (technologies.length) {
			const tempData: {[key: string]: string[]} = {};

			technologies.forEach((el) => {
				if (tempData[el.type]) {
					tempData[el.type].push(el.name);
				} else {
					tempData[el.type] = [el.name];
				}
			});

			setTechData(tempData);
		}
	}, [technologies]);

	return (
		<Style id='section_project' backgroundImage={project_logo}>
			<div className='about-project__wrapper'>
				<h2 className='about-project__title'>{block_title}</h2>

				<div className='project-description__wrapper'>
					<div className='about-project__top'>
						{ReactHtmlParser(project_top_text) || ''}
					</div>

					<div className='about-project__left'>
						{ReactHtmlParser(project_left_text) || ''}
					</div>

					<div className='about-project__right'>
						{ReactHtmlParser(project_right_text) || ''}
					</div>
				</div>

				<h3 className='about-project__technologies-title'>Технологии в проекте</h3>

				<div className='technologies-wrapper'>
					{techData && Object.keys(techData) && Object.keys(techData).map((key) => (
						<ul className='technologies-list' key={key}>
							<li className='technology-type'>{key}</li>

							{techData[key].map((el) => (
								<li className='technology-element' key={el}>
									{el}
								</li>
							))}
						</ul>
					))}
				</div>
			</div>
		</Style>
	)
}

export default DescriptionOfProject;