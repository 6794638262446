import styles from 'styled-components';

interface IFormWrapper {
  width?: string;
  maxWidth?: string;
  padding?: string;
  formTheme?: 'white' | 'black';
};

interface ITitle {
  formTheme?: 'white' | 'black';
};

const FormWrapper = styles.div<IFormWrapper>`
  max-width: ${props => props.maxWidth || '100%'};
  width: ${props => props.width || '100%'};
  padding: ${props => props.padding || '0'};

  form {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: ${props => props.formTheme === 'white' ? 'black' : 'white'};

    label {
      color: ${props => props.formTheme === 'white' ? 'black' : 'white'};
    }

    input, textarea {
      color: ${props => props.formTheme === 'white' ? 'black' : 'white'};
      border-bottom: 3px solid ${props => props.formTheme === 'white' ? 'rgba(0,0,0,.3)' : 'rgba(255,255,255,.3)'};

      &:focus {
        border-color: ${props => props.formTheme === 'white' && 'black'};
      }

      &::placeholder {
        color: ${props => props.formTheme === 'white' ? 'black' : 'white'};
      }
    }

    .form-submit {
      width: 100%;
      margin: 48px 0 24px;

      button {
        color: ${props => props.formTheme === 'white' ? 'black' : '#27C3F3'};

        @media (max-width: 700px) {
          width: 100%;

          ${props => props.formTheme === 'white' && `
            background-color: #27C3F3;
          `}
        }

        @media (max-width: 420px) {
          & > div {
            padding: 10px 83px;
            font-size: 16px !important;
            line-height: 24px !important;
          }
        }
      }
    }

    .form-condition {
      color: ${props => props.formTheme === 'white' ? 'rgba(0, 0, 0, 0.64)' : 'rgba(255, 255, 255, 0.64)'};
      br {
        display: none;

        @media (max-width: 670px) {
          display: block;
        }
      }

      a {
        cursor: pointer;
      }

      @media (max-width: 420px) {
        &, a {
          font-size: 11px;
          line-height: 20px;
        }
      }
    }
  }
`;

const Title = styles.h1<ITitle>`
  margin-bottom: 64px;
  display: flex;
  align-items: center;

  @media (max-width: 420px) {
    margin-bottom: 45px;
    font-size: 32px;
    line-height: 36px;
    align-items: flex-end;
  }

  & > div {
    margin-left: auto;
    display: flex;
    align-items: baseline;

    & > svg {
      cursor: pointer;

      path {
        fill: black;
      }
    }
  }
`;

export { FormWrapper, Title };
