import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import { Container } from "@app/components/PageLayout";

const EmptyContainer = styled(Container)`
  ${StyleMediaMax.tablet} {
    max-width: calc(100vw - 120px);
    margin-left: 0;
    text-align: center;
  }

  ${StyleMediaMax.mobileM} {
    max-width: calc(100vw - 40px);
  }
`;

export default EmptyContainer;