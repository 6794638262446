import styles from 'styled-components';

const Wrapper = styles.div`
  width: 100%;
  padding: 140px 0 96px;
  background-color: black;

  @media (max-width: 768px) {
    padding: 120px 0;
  }
`;

const ContentWrapper = styles.div`
  max-width: 1128px;
  margin: 0 auto;

  form > div:first-child {
    margin-bottom: 20px;
  }

  @media (max-width: 1128px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  h1 > div > svg {
    display: none;
  }

  @media (max-width: 700px) {
    & > div > h1 {
      max-width: 350px;
    }

    form > div {
      width: 100%;
    }

    form > div:nth-child(1), div:nth-child(2), div:nth-child(3), div:nth-child(4) {
      margin-bottom: 30px;
    }
  }
`;

export { Wrapper, ContentWrapper };
