import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  hideOnMobile?: boolean;
  color?: string;
  margin?: string;
  marginMobile?: string;
  padding?: string;
  paddingMobile?: string;
  fontWeight?: string;
}

const ParagraphTitle = styled.div<Props>`
  font-size: 24px;
  line-height: 1.42;
  margin: ${props => props.margin ? props.margin : undefined};
  color: ${props => props.color ? props.color : undefined};
  padding: ${props => props.padding ? props.padding : undefined};
  font-weight: ${props => props.fontWeight ? props.fontWeight : undefined};

  ${StyleMediaMax.tablet} {
    font-weight: bold;
  }

  ${StyleMediaMax.mobileM} {
    display: ${props => props.hideOnMobile ? 'none' : undefined};
    margin: ${props => props.marginMobile ? props.marginMobile : undefined};
    padding: ${props => props.paddingMobile ? props.paddingMobile : undefined};
    font-weight: bold;
  }
`;

export default ParagraphTitle;
