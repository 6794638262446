import React from 'react';
import * as ContactElement from './Style';
import ContactForm from './ContactForm';

const Contact = ({ type }: {type: 'Heisenbug' | 'JPoint' | 'HolyJS'}) => (
  <ContactElement.Wrapper className='contact-form'>
    <ContactElement.ContentWrapper>
      <ContactForm type={type} />
    </ContactElement.ContentWrapper>
  </ContactElement.Wrapper>
);

export default Contact;
