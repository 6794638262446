import React, { CSSProperties } from "react";

import { theme } from "@app/styles/theme";
import { Container, SubParagraph, Title } from "@app/components/PageLayout";
import { WhiteLinkButton } from "@app/components/Buttons";
import NotFoundWrapper from "./NotFoundWrapper";

const BORDER = theme.color.transparentDisabled;

const wrapper: CSSProperties = {
    height: '71px',
    border: '0',
    color: 'white',
    padding: 0,
    margin: 0,
}

const notFound: CSSProperties = {
    float: 'left'
};

const div404: CSSProperties = {
    lineHeight: '250px',
    margin: '0 0 0 500px',
    fontSize: '2000%',
    fontWeight: 'bold',
};

const NotFoundPage = () => (
    <NotFoundWrapper>
        <Container borderColor={BORDER} padding="222px 0 195px 0" paddingTablet="95px 0" paddingMobile="60px 0 325px">
            <div style={wrapper}>
                <div style={notFound}>
                    <Title>
                        Страница<br/> не найдена
                    </Title>
                </div>
                <div style={div404}>
                    404
                </div>
            </div>

            <SubParagraph margin="60px 0 61px 0" marginMobile="0" color='#aab8c2'>
                Искомая страница не найдена.<br/>
                Вернитесь на предыдущую<br/>
                или главную страницу.
            </SubParagraph>
            <WhiteLinkButton to="/" width="270px">Вернуться на главную</WhiteLinkButton>
        </Container>
    </NotFoundWrapper>
);

export default NotFoundPage;