import styled from 'styled-components';

const NewsTitle = styled.div`
  font-weight: 600;
  display: block;
  line-height: 1.63;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  line-height: 23px;
`;

export default NewsTitle;