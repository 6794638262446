import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import { Title } from '@app/components/PageLayout';

const NewsTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 30px;
  border-bottom: ${props => `1px solid ${props.theme.color.borderLight}`};

  ${StyleMediaMax.mobileM} {
    padding-bottom: 15px;

    ${Title}{
      display: none;
    }
  }
`;

export default NewsTitleWrapper;