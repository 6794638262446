import styled from 'styled-components';

interface Props {
  icon?: string;
}

const BreadcrumbArrow = styled.div<Props>`
  margin-right: 20px;
  width: 7px;
  height: 10px;
  background: ${props => props.icon ? `url(${props.icon}) no-repeat` : undefined};
  background-size: cover;
`;

export default BreadcrumbArrow;