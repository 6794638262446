import React from 'react';

import { theme } from '@app/styles/theme';

import { Container, Title } from '@app/components/PageLayout';
import CounterWrapper from './CounterWrapper';
import CounterItem from './CounterItem';
import NumberContainer from './NumberContainer';
import DescriptionContainer from './DescriptionContainer';

const CounterWidget = () => (
  <Container borderColor={theme.color.borderLight} padding="1px 0 0 0">
    <Title position="absolute" margin="120px 0 60px 0" marginTablet="" marginMobile="40px 20px 0 0" color={theme.color.primaryColorGrey}>Orion Innovation в цифрах</Title>
    <CounterWrapper>
      <CounterItem margin="150px 0 0" isAbout={true}>
        <NumberContainer margin="0 0 0 -1px">30+</NumberContainer>
        <DescriptionContainer>лет на рынке <br /> IT-услуг</DescriptionContainer>
      </CounterItem>
      <CounterItem paddingTop="0" margin="150px 0 0" isAbout={true} paddingTablet="220px">
        <NumberContainer>6000</NumberContainer>
        <DescriptionContainer>специалистов
        <br /> в сфере IT</DescriptionContainer>
      </CounterItem>
      <CounterItem border="none" isAbout={true}>
        <NumberContainer border="none" padding="0 0 0 40px" margin="0 0 0 -21px" marginTablet="0 0 0 -60px">200+</NumberContainer>
        <DescriptionContainer>заказчиков <br /> по всему миру <br />10+ Fortune 100
        </DescriptionContainer>
      </CounterItem>
      <CounterItem isAbout={true} marginRight="60px">
        <NumberContainer last={true} margin="0 0 0 0">13</NumberContainer>
        <DescriptionContainer>стран <br /> присутствия</DescriptionContainer>
      </CounterItem>
    </CounterWrapper>
  </Container>
);

export default CounterWidget;
