import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import DescriptionContainer from '@app/components/pages/HomePage/widgets/CounterWidget/DescriptionContainer';

const MobileDescription = styled(DescriptionContainer)`
  display: none;

  ${StyleMediaMax.mobileM} {
    display: block;
    padding-left: 15px;
  }
`;

export default MobileDescription;