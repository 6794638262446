import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import { Container } from '@app/components/PageLayout';
import NewsGrid from '../NewsGrid';
import { OrangeLinkButton } from '@app/components/Buttons';

const NewsItemPageContainer = styled(Container)`

  ${StyleMediaMax.tablet}{
    max-width: calc(100vw - 120px);

    ${OrangeLinkButton}{
      margin: 0;
    }
  }

  ${StyleMediaMax.mobileM} {
    max-width: calc(100vw - 40px);
    position: relative;

    ${NewsGrid}{
      padding: 40px 0;
    }
  }
`;

export default NewsItemPageContainer;