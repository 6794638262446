import React, { useEffect } from 'react';
import { RouteComponentProps } from "react-router";
import { find } from 'lodash';

import { scrollToPosition } from "@app/utils/";
import { BreadcrumbContextProvider } from '@app/components/BreadcrumbContext/BreadcrumbProvider';

interface Props extends BreadcrumbContextProvider {
  routeProps: RouteComponentProps;
  pathName: string;
  pathUrl?: string;
  Component: React.FC<any> | React.FC<RouteComponentProps>;
}

const vacancyAndProject = {
  name: 'Вакансии и проекты',
  to: '/vacancy',
};

const CrumbComponent: React.FC<Props> = ({ path, routeName, setRouteName, routeProps, pathName, pushPath, pathUrl, clearPath, Component }) => {
  useEffect(() => {
    if (path !== undefined) {
      if (routeName !== routeProps.location.pathname) {
        setRouteName(routeProps.location.pathname);
        clearPath();
      } else {
        if (!find(path, (link) => link.name === pathName && (pathUrl ? link.to === pathUrl : link.to === routeProps.match.url))) {
          if (pathName === '') {
            if (!find(path, (link) => link.to === routeProps.match.url)) {
              pushPath({
                name: pathName,
                to: pathUrl || routeProps.match.url,
              });
            }
          } else {
            pushPath({
              name: pathName,
              to: pathUrl || routeProps.match.url,
            });
          }

          if ((routeProps.match.url === '/vacancy' || routeProps.match.url === '/projects') &&
            !find(path, (pathValue) => pathValue.name === vacancyAndProject.name && pathValue.to === vacancyAndProject.to)) {
            pushPath(vacancyAndProject);
          }
        }
      }
    }

    scrollToPosition("wrapper");
    
  }, [clearPath, path, pathName, pathUrl, pushPath, routeName, routeProps.location.pathname, routeProps.match.url, setRouteName]);

  return (
      <Component {...routeProps} />
  )
};

export default CrumbComponent;