import styled from 'styled-components';

const RequestsContainer = styled.span`
  text-decoration: underline;
  color: ${p => p.theme.color.white};
  
  &:hover{
    cursor: pointer;
  }
`;

export default RequestsContainer;