import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import ExternalLink from "@app/components/Links/ExternalLink";

interface Props {
  src: string;
}

const SocialMediaImg = styled(ExternalLink) <Props>`
  height: 35px;
  width: 35px;
  margin-left: 25px;
  background-image: ${props => `url(${props.src})`};
  cursor: pointer;
  
  &:first{
    margin-right: 40px;
  }

  ${StyleMediaMax.mobileM} {
    margin-left: auto;
  }
`;

export default SocialMediaImg;