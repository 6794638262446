import React from 'react';

import scheme_desktop from "@assets/pictures/products/heating_system/scheme_desktop.jpg";
import scheme_tablet from "@assets/pictures/products/heating_system/scheme_tablet.jpg";
import { Title, Paragraph } from '@app/components/PageLayout';
import ImageContainer from "./ImageContainer";
import StyledContainer from "./StyledContainer";

const SchemeWidget = () => (
    <StyledContainer>
      <Title>
      Програмный Комплекс "Умная Теплосеть"
      </Title>
      <Paragraph>
      Контакты для обращения в службу техподдержки продукта: +7-831-278-88-22<br/><br/>
      Стоимость ПО определяется по запросу клиента, в зависимости от запрашиваемых характеристик.<br/>
      Для покупки Продукта напишите на адрес <a href="mailto:smart-heating@orioninc.com" target="_blank" rel="noopener noreferrer">smart-heating@orioninc.com</a> и Вам вышлют коммерческое предложение.
      </Paragraph>
      <a href={scheme_desktop} target="_blank" rel="noopener noreferrer">
        <ImageContainer imgUrl={scheme_desktop} tabletImgUrl={scheme_tablet} />
      </a>
    </StyledContainer>
);

export default SchemeWidget;