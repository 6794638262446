import styled from 'styled-components';
import Button from './Button';

const TransparentButton = styled(Button)`
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.transparent};

  :hover{
    color: ${props => props.theme.color.primaryText};
    background-color: ${props => props.theme.color.white};
  }

  :disabled {
    color: ${props => props.theme.color.transparent};
    background-color: ${props => props.theme.color.transparentDisabled};
  }
`;

export default TransparentButton;