import React from 'react';

import { theme } from '@app/styles/theme';

import { BlueLinkButton } from '@app/components/Buttons';
import { Container } from '@app/components/PageLayout';

import WelcomeWrapper from './WelcomeWrapper';
import WelcomeTitleLink from './WelcomeTitleLink';
import DisplayTitle from './DisplayTitle';
import MobileTitle from './MobileTitle';
import poster from '@assets/frame.png';
import { IDataStatus } from '@app/components/PagesRouter/PagesRouter';
import { parseText } from '@app/utils';
import ReactHtmlParser from 'react-html-parser';

const WelcomeWidget = ({ data, loading }: { data: IDataStatus, loading: boolean }) => {
  return (
    <WelcomeWrapper>
      <Container borderColor={theme.color.transparentDisabled} padding="140px 0" paddingTablet="95px 0 0 0" paddingMobile="60px 0 0 0">
        <DisplayTitle margin="0 60px 60px 0">
          {!loading
            ?
            // ? (!data || (data && !data.published)) 
            //   ? (
              (
                <>
                  Добро пожаловать на карьерный портал <br />
                  Центра разработки Orion Innovation <br />
                  (ранее <WelcomeTitleLink href="https://www.mera.com/">MERA</WelcomeTitleLink>)
                </>
              )
              // : parseText(data.main_title)
            : ''
          }
        </DisplayTitle>
        <MobileTitle margin="0 20px 30px 0">
          {!loading
            ?
            // ? (!data || (data && !data.published)) 
              // ? 'Карьерный портал Центра разработки Orion Innovation'
              'Карьерный портал Центра разработки Orion Innovation'
              // : parseText(data.mobile_title)
            : ''
          }
        </MobileTitle>
        <div className='videoContainer'>
          <div className='video'>
            <video controls={true} poster={poster} preload='none'>
              <source src="/media/MERA_wip-7.mp4" />
            </video>
          </div>

          <div className="left">
            {!loading
              ?
              // ? (!data || (data && !data.published))
                // ? (
                (
                  <>
                    <p>
                      Развивайся вместе с компанией:<br />
                      выбирай из сотни проектов,<br />
                      решая задачи на грани своего<br />
                      экспертного уровня<br />
                      в составе опытной команды.<br />
                      Удаленка или релокация -
                      <br />выбор за тобой!
                    </p>
                  </>
                )
                // : ReactHtmlParser(data.description)
              : ''
            }
            {/* <BlueLinkButton
              to={!loading
                ? (!data || (data && !data.published))
                  ? '/vacancy'
                  : `/${data.link}`
                : ''
              }
              width="270px"
              margin="60px 0 0"
            >
              {!loading
                ? (!data || (data && !data.published)) 
                  ? 'Смотреть вакансии'
                  : data.btn_text
                : ''
              }
            </BlueLinkButton> */}
          </div>
        </div>

      </Container>
    </WelcomeWrapper>
  )};

export default WelcomeWidget;
