import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import Wrapper from './Wrapper';
import SearchInput from '@app/components/SearchInput';
import RequestsContainer from './RequestsContainer';
import RequestCloseIcon from './RequestCloseIcon';
import RequestItem from './RequestItem';
import useAxiosFetch from "@app/api/useAxiosFetch";
import { Filters } from "@app/components/pages/MeetupsPage/widgets/MeetupModels/MeetupModel";
import { filterHints } from '@app/components/SearchInput/SearchInput';

interface Props {
  isRequestOpen?: boolean;
  setRequestOpen?: (value: boolean) => void;
  closeSearch?: () => void;
  focus?: boolean;
}

export interface Hint {
  title: string;
  to: string;
}

const SearchWrapper: React.FC<Props> = ({ isRequestOpen, setRequestOpen, closeSearch, focus }) => {
  const ref = useRef(null);
  const [isHintOpen, setHintOpen] = useState(false);
  const [value, setValue] = useState('');
  const [hints, setHints] = useState<Hint[]>([]);

  const { data = [] }: { data: Filters[] } = useAxiosFetch("vacancy/popular_tags/");

  const requestClose = () => {
    setRequestOpen && setRequestOpen(false);
    setValue('');
    setHintOpen(false);
  }

  useEffect(() => {
    const handleClick = (event: any) => {
      const domNode = ReactDOM.findDOMNode(ref.current);
      if (!(domNode && (domNode.contains(event.target) || domNode === event.target))) {
        setRequestOpen && setRequestOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  return (
    <Wrapper ref={ref} isRequestOpen={isRequestOpen}>
      <SearchInput
        value={value}
        placeholder="Искать вакансии"
        isHintOpen={isHintOpen}
        hints={hints}
        setHints={setHints}
        setValue={(searchValue) => setValue(searchValue)}
        setHintOpen={(hintValue) => setHintOpen(hintValue)}
        setRequestOpen={(requestValue) => { setRequestOpen && setRequestOpen(requestValue) }}
        isRequestOpen={isRequestOpen}
        closeSearch={closeSearch}
        focusInput={focus}
      />
      {isRequestOpen &&
        <RequestsContainer >
          Популярные запросы:
            {data.map(
            (tag: Filters) =>
              <RequestItem
                key={tag.id}
                onClick={() => {
                  setValue(tag.name)
                  filterHints(tag.name, setHints, setHintOpen)
                }}
              >
                {tag.name}
              </RequestItem>
          )}
          <RequestCloseIcon onClick={ requestClose } />
        </RequestsContainer>
      }
    </Wrapper>
  );
}

export default SearchWrapper;