import React from 'react';
import { AboutImageTabWrapper } from './Style';
import ReactHtmlParser from 'react-html-parser';
import { blueData } from './blueData';
import { purpleData } from './purpleData';
import { TLandingType } from '../../types';

interface IAboutImageTab {
  data: {
    title: string;
    id: number;
  }[]
  type: TLandingType;
};

const AboutImageTab = ({ data, type }: IAboutImageTab) => {
  return (
    <AboutImageTabWrapper>
      {data.map((item) => (
        <div key={item.title}>
          <img src={type === 'JPoint' ? purpleData[item.id] : blueData[item.id]} alt="about"/>
          <p className='event-paragraph__large image-tab__description'>
            {ReactHtmlParser(item.title)}
          </p>
        </div>
      ))}
    </AboutImageTabWrapper>
  );
};

export default AboutImageTab;
