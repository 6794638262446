import styled from 'styled-components';

export interface TextareaProps {
  error?: boolean;
  backgroundColor?: string;
}

const StyledTextarea = styled.textarea<TextareaProps>`
  resize: none;
  outline: none;
  border: none;
  width: 100%;
  height: 103px;
  padding: 16px 5px 5px 20px;
  background-color: ${props => props.backgroundColor? props.backgroundColor :props.theme.color.white};
  border: ${props => props.error ? `solid 1px ${props.theme.color.primaryColor}` : 'none'};
  font-weight: 400;

  &::placeholder{
    color: ${props => props.theme.color.secondaryTextLight};
  }
`;

export default StyledTextarea;