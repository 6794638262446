import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 120px;
  z-index: 4;
  background-color: ${props => props.theme.color.black};
  box-shadow: ${props => `0 8px 20px 0 ${props.theme.color.shadow}`};

  ${StyleMediaMax.tablet} {
    display: none;
  };
`;

export default Wrapper;