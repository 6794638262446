import React, { useState } from 'react';
import { MobileNavigationWrapper } from './Style';
import HeaderLogo from '@app/components/Header/HeaderLogo';
import { TLinks } from '../../types';

const MobileNavigation = ({ links }: { links: TLinks }) => {
  const [showMenu, setShowMenu] = useState(false);

  const scroll = (id: string) => {
    const element = document.getElementById(id);
    element && element.scrollIntoView();
    setShowMenu(false);
  };

  return (
    <MobileNavigationWrapper show={showMenu}>
      <div className='mobile-navigation'>
        <HeaderLogo to='/' />

        <div
          className='btn-menu'
          onClick={() => setShowMenu(!showMenu)}
          data-show={showMenu}
        >

        </div>
      </div>

      {showMenu && (
        <div className='mobile-navigation-content'>
          <div className='menu-navigation__wrapper'>
            <nav className='menu-navigation'>
              <div className='navigation-list'>
                {links.map((link) => (
                  <div onClick={() => scroll(link.sectionId)} key={link.id}>
                    {link.name}
                  </div>
                ))}
              </div>
            </nav>
            <div className='menu-right'>
              © 2021 Orion Innovation. Все права защищены.
            </div>
          </div>
        </div>
      )}
    </MobileNavigationWrapper>
  );
};

export default MobileNavigation;
