import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  position?: string;
  margin?: string;
  marginTablet?: string;
  marginMobile?: string;
  paddingTablet?: string;
  paddingMobile?: string;
  color?: string;
  order?: string;
  display?: string;
}

const Title = styled.h2<Props>`
  line-height: 1.29;
  color: ${props => props.color ? props.color : undefined};
  margin: ${props => props.margin ? props.margin : undefined};

  ${StyleMediaMax.tablet} {
    position: ${props => props.position ? props.position : undefined};
    margin: ${props => props.marginTablet ? props.marginTablet : undefined};
    padding: ${props => props.paddingTablet ? props.paddingTablet : undefined};
    display: ${props => props.display ? props.display : undefined};
    vertical-align: ${props => props.display ? "bottom" : undefined};
  }

  ${StyleMediaMax.mobileM} {
    position: ${props => props.position ? props.position : undefined};
    margin: ${props => props.marginMobile ? props.marginMobile : undefined};
    padding: ${props => props.paddingMobile ? props.paddingMobile : undefined};
    order: ${props => props.order ? props.order : undefined};
  }
`;

export default Title;