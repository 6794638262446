import styled from "styled-components";
import { StyleMediaMax } from '@app/components/StyleMedia';
import { Container} from '@app/components/PageLayout';

const DropDownWrapper = styled(Container)`
  ${StyleMediaMax.tablet} {
    width: calc(100vw - 120px);
    margin-left: 0;

    ${Container}{
      margin-left: 0;
      padding: 0 40vw 0 0;
    }
  }

  ${StyleMediaMax.mobileM} {
    width: calc(100vw - 40px);

    ${Container}{
      margin-left: 0;
      padding: 0;
      >:first-child {
        font-size: 24px;
        max-width: 85%;
        padding: 0;
      }

      >:last-child {
        top: 54px;
        right: 12px;
      }
    }
  }
`;

export default DropDownWrapper;