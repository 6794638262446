import React, { ComponentState, SetStateAction } from 'react';

import Loader from "./Loader";

interface Props {
  isLoading: boolean;
  isError: boolean;
  toggleReload: SetStateAction<ComponentState>;
  loaderPadding?: string;
}

const WithLoader = <P extends object>(Component: React.ComponentType<P>):React.FC<P & Props> =>
    ({isLoading, isError, toggleReload, loaderPadding, ...props}: Props) =>
        (isLoading ?
          <Loader padding={loaderPadding}/> :
            isError ?
              <Loader error={isError} reloading={toggleReload} padding={loaderPadding}/> :
                <Component {...props as P}/>);

export default WithLoader;