import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const InputWrapper = styled.div`
  display: flex;

  ${StyleMediaMax.mobileM} {
    display: block;
  }
`;

export default InputWrapper;