import React from 'react';

import { Wrapper } from '@app/components/PageLayout';
import Industry from '@app/components/pages/HomePage/widgets/IndustryWidget';

const IndustryWidget = () => (
  <Wrapper>
    <Industry page="about" withoutButton />
  </Wrapper>
);

export default IndustryWidget;
