const firstVideo = require('../assets/firstVideo.mp4');
const secondVideo = require('../assets/secondVideo.mp4');

export interface IHolyjsLanguage {
  answers: string[],
  name: string,
};

export const holyjsLanguage: IHolyjsLanguage = {
  answers: ['Cultural Shock <br />21 April, 4:30 pm', 'Hacks on Com <br />22 April, 4:30 pm'],
  name: 'HolyJS',
};

export interface IHolyjsTeamElement {
  image: string;
  name: string;
  position: string;
  description: string;
  id: number;
  url: string;
  answers: string[];
};

export type IHolyjsTeam = IHolyjsTeamElement[];

export const holyjsTeam: IHolyjsTeam = [
  {
    image: 'cloud',
    name: 'Cloud Systems',
    position: 'Software Engineer, Enterprise',
    description: 'Продукт объединяет функции обмена сообщениями, IP-телефонии, видео- конференций, а также интегрированных контакт-центров.Мы пишем на последних версиях JavaScript, используем Angular и TypeScript. В проекте идеально выстроены рабочие процессы.',
    id: 0,
    url: firstVideo,
    answers: ['21 апреля 15:00', '22 апреля 15:00'],
  },
  {
    image: 'cloud',
    name: 'Contact Center',
    position: 'Software Engineer, Enterprise',
    description: 'В команде Senior-инженеров делаем кастомизации под нужды конкретного заказчика. Пишем быстро и беспрерывно. Используем самые новые технологии, подходы в разработке и тулы.',
    id: 1,
    url: secondVideo,
    answers: ['21 апреля 16:00', '22 апреля 16:00'],
  },
];

export const links = [
  {
    name: 'О компании',
    sectionId: 'about-company',
    id: 0,
  },
  {
    name: 'Получить подарок',
    sectionId: 'luck',
    id: 1,
  },
  {
    name: 'Прокачать английский',
    sectionId: 'language',
    id: 2,
  },
  {
    name: 'Играть в Star Game',
    sectionId: 'game',
    id: 3,
  },
];

export const luckData = {
  answers: ['Менее 2 лет', '2-4 года', 'Более 4 лет'],
  name: 'HolyJS',
  times: ['20 апреля с 13:30', '21 апреля c 16:00', '22 апреля c 13:30', '23 апреля c 16:00'],
};
