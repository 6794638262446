import React from 'react';
import Style from './Style';

const AcceptingStatements = ({ block_title, main_description }: { block_title: string, main_description: string }) => {
	return (
		<Style id='section_steps'>
			<div className='wrapper'>
				<h2>{block_title}</h2>
				
				<p className='first'>
					{main_description}
				</p>
			</div>
		</Style>
	)
}

export default AcceptingStatements;
