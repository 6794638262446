import React, { useEffect, useContext, useRef } from 'react';
import LandingContext from "@app/components/Builder/LandingContext";
import StyleWrapper from '../components/StyleWrapper';
import Header from '../sections/Header';
import About from '../sections/About';
import Vacancy from '../sections/Vacancy';
import Luck from '../sections/Luck';
import Contact from '../sections/Contact';
import Bonus from '../sections/Bonus';
import Language from '../sections/Language';
import Game from '../sections/Game';
import { holyjsLanguage, luckData, links } from './data';
import { vacancies } from './vacancies';
import desktop from './assets/desktop.jpg';
import { tabs } from './tabs';

const btnPreset = {
  hover: '#7DDBF8',
  fill: false,
  text: '#000',
  background: '#27C3F3',
};

const Holyjs = (props: any) => {
  const { setIsLanding } = useContext(LandingContext);
  const languageRef = useRef<HTMLDivElement>(null);
  const projectRef = useRef<HTMLDivElement>(null);
  const gameRef = useRef<HTMLDivElement>(null);
  const luckRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const section = props.match.params.section;

    if (section) {
      const scroll = setTimeout(() => {
        switch (section) {
          case 'english':
            languageRef && languageRef.current && languageRef.current.scrollIntoView();
            break;
          case 'projects':
            projectRef && projectRef.current && projectRef.current.scrollIntoView();
            break;
          case 'game':
            gameRef && gameRef.current && gameRef.current.scrollIntoView();
            break;
          case 'luck':
            luckRef && luckRef.current && luckRef.current.scrollIntoView();
            break;
        }
      }, 300);

      return () => clearTimeout(scroll);
    }
  }, [props.match.params.section]);

  useEffect(() => {
    setIsLanding('conferences');
    return () => setIsLanding('');
  }, [setIsLanding]);

  return (
    <StyleWrapper color='#27C3F3' btn={btnPreset} type='holyjs'>
      <Header
        type='HolyJS'
        links={links}
        background={desktop}
      />
      <About type='HolyJS' tabs={tabs} color='#27C3F3' />
      <Vacancy data={vacancies} color='#27C3F3' />
      <Bonus color='blue' />
      <Luck data={luckData} ref={luckRef} type='HolyJS' />
      <Game ref={gameRef} link='https://holyjs-game.orioninc.ru/' type='HolyJS' />
      <Language ref={languageRef} data={holyjsLanguage} />
      <Contact type='HolyJS' />
    </StyleWrapper>
  );
};

export default Holyjs;
