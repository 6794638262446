import styles from 'styled-components';
import dropImage from '../../assets/selector-drop.svg';

interface IWrapper {
  width?: string;
  selected: string[];
  show: boolean;
  selectorTheme?: 'white' | 'black';
};

const Wrapper = styles.div<IWrapper>`
  position: relative;
  width: ${props => props.width || '100%'};

  &[data-failure="true"] {
    .selector__placeholder {
      color: #cd2026 !important;
    }

    .selector__answer {
      border-color: #cd2026 !important;
    }
  }

  .selector__label {
    position: relative;
    width: 100%;
    
    .selector__placeholder {
      margin-left: 10px;
      color: ${props => props.selectorTheme === 'white' ? 'black' : 'white'};
      opacity: ${props => props.selected.length ? '1' : '0'};
    }

    .selector__answer {
      width: 100%;
      padding: 10px 8px;
      border: none;
      background: transparent;
      border-bottom: 3px solid ${props => props.selectorTheme === 'white' ? 'rgba(0, 0, 0, .3)' : 'rgba(255, 255, 255, .3)'};
      outline: none;
      color: ${props => props.selectorTheme === 'white' ? 'black' : 'white'};

      &::placeholder {
        font-size: 20px;
        line-height: 140%;
        color: ${props => props.selectorTheme === 'white' ? 'black' : 'white'};
      }
    }

    .selector__answer-drop {
      position: absolute;
      right: 5px;
      bottom: 13px;
      height: 24px;
      width: 24px;
      background: url(${dropImage});
      cursor: pointer;
    }
  }

  .selector__answers {
    position: absolute;
    z-index: 2;
    top: 71px;
    width: 100%;
    max-height: ${props => props.show ? '200px' : '0'};
    overflow: hidden;
    background: ${props => props.selectorTheme === 'white' ? 'white' : 'gray'};
    box-shadow: 0px 0.8px 4px rgba(0, 0, 0, 0.04), 0px 4px 12px rgba(0, 0, 0, 0.15);

    & > div {
      display: flex;
      padding: 13px 16px;
      color: ${props => props.selectorTheme === 'white' ? 'black' : 'white'};

      & > div > div {
        margin-top: 5px;

        ${props => props.selectorTheme === 'white' && `
          border-color: black;
        `}
      }
    }
  }
`;

export { Wrapper };
