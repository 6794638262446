import styled from "styled-components";
import error_send from "@assets/icons/error_send.svg";

const ErrorElement = styled.div`
  width: 50px;
  height: 50px;
  margin: auto;
  background: url(${error_send}) no-repeat center center;
`;

export default  ErrorElement;