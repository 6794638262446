import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  margin?: string;
}
const SectionTitle = styled.h2<Props>`
  margin: ${props => props.margin ? props.margin : undefined};

  ${StyleMediaMax.tablet} {
    margin: 0 0 60px;
  }

  ${StyleMediaMax.mobileM} {
    margin: 0 0 40px;
    font-size: 27px;
  }
`;

export default SectionTitle;

//font-size: 27px; | null