import { css,  } from 'styled-components';
import sizes, { MediaQueries } from './sizes';


const StyleMediaMin: MediaQueries = {};

for (let [key, value] of Object.entries(sizes)) {
  StyleMediaMin[key] = css`@media(min-width: ${value + 1}px)`;
}

export default StyleMediaMin;
