import styled from 'styled-components';

const ControlContainer = styled.div`
  font-weight: 600;
  color: ${props => props.theme.color.black};
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: inherit;
  }
`;

export default ControlContainer;