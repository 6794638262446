import React from 'react';

import { theme } from '@app/styles/theme';

import { OrangeLinkButton } from '@app/components/Buttons';
import { Title, Container } from '@app/components/PageLayout';
import IndustryWrapper from './IndustryWrapper';
import IndustryItem from './IndustryItem';
import IndustryIcon from './IndustryIcon';

import telco from '@assets/icons/industries/telco.svg';
import fintech from '@assets/icons/industries/fintech.svg';
import security from '@assets/icons/industries/security.svg';
import iot from '@assets/icons/industries/iot.svg';
import ai from '@assets/icons/industries/ai.svg';
import car from '@assets/icons/industries/car.svg';
import industry from '@assets/icons/industries/industry.svg';
import medicine from '@assets/icons/industries/medicine.svg';

interface Props {
  page: string;
  withoutButton?: boolean;
}

const IndustryWidget: React.FC<Props> = ({ page, withoutButton }) => (
  <Container borderColor={theme.color.borderLight} padding="120px 0" paddingMobile="70px 0 70px">
    <Title margin="0 0 60px 0" marginMobile="0 0 40px" color={theme.color.primaryColorGrey}>{ page === 'AboutUs'? 'Industries': 'Индустрии' }</Title>
    <IndustryWrapper>
      <IndustryItem>
        <IndustryIcon src={telco} /> { page === 'AboutUs'? <>Telecommunications & <br />Enterprise solutions</>: <>Телекоммуникации <br /> и корпоративная связь</> }
      </IndustryItem>
      <IndustryItem>
        <IndustryIcon src={iot} /> { page === 'AboutUs'? 'Internet of Things': 'Интернет вещей' }
      </IndustryItem>
      <IndustryItem>
        <IndustryIcon src={car} /> { page === 'AboutUs'? 'Automotive': 'Автомобильная индустрия' }
      </IndustryItem>
      <IndustryItem>
        <IndustryIcon src={fintech} /> { page === 'AboutUs'? 'FinTech': 'Финансовая индустрия' }
      </IndustryItem>
      <IndustryItem>
        <IndustryIcon src={ai} /> { page === 'AboutUs'? <>Artificial Intelligence</>: <>Искусственный <br /> интеллект</> }
      </IndustryItem>
      <IndustryItem>
        <IndustryIcon src={industry} /> { page === 'AboutUs'? 'Industry 4.0': 'Индустрия 4.0' }
      </IndustryItem>
      <IndustryItem>
        <IndustryIcon src={security} /> { page === 'AboutUs'? 'Safety & Security': 'Охрана и безопасность' }
      </IndustryItem>
      <IndustryItem>
        <IndustryIcon src={medicine} /> { page === 'AboutUs'? 'Healthcare': 'Здравоохранение' }
      </IndustryItem>
    </IndustryWrapper>
    {!withoutButton &&
      <OrangeLinkButton hideonmobile="none" margin="60px 0 0 -0.5px" width="270px" widthTablet="270px" to="/about">
        Подробнее о компании
      </OrangeLinkButton>
    }
  </Container>
);

export default IndustryWidget;