import React from 'react';

import office_nn from "@assets/pictures/offices/office_nn.jpg";
import office_nn_tablet from "@assets/pictures/offices/office_nn_tablet.jpg";
import office_kazan from "@assets/pictures/offices/office_kazan.jpg";
import office_kazan_tablet from "@assets/pictures/offices/office_kazan_tablet.jpg";
import { theme } from "@app/styles/theme";

import { Title, ParagraphTitle, ParagraphTitleMobile, FlexContainer, FlexItem } from '@app/components/PageLayout';
import ImageContainer from "./ImageContainer";
import ContactsContainer from "./ContactsContainer";
import Link from "../Link";

const CONTACTS_TEXT = theme.color.textLight;

const OfficesWidget = () => (
    <ContactsContainer>
      <Title>
        Контакты
      </Title>
      <ParagraphTitle margin="60px 0 40px 0" marginMobile="40px 0" fontWeight="bold">
        Центральные офисы
      </ParagraphTitle>
      <FlexContainer justifyContent="space-between" flexDirectionMobile={true}>
        <FlexItem>
          <ImageContainer imgUrl={office_nn} tabletImgUrl={office_nn_tablet} />
          <ParagraphTitle margin="40px 0 30px 0" fontWeight="bold">
            Нижний Новгород
          </ParagraphTitle>
          <ParagraphTitle color={CONTACTS_TEXT} hideOnMobile fontWeight="bold">
            603163, Россия, г. Нижний Новгород,<br/>
            ул. Деловая, 13. За ТЦ «Фантастика»
          </ParagraphTitle>
          <ParagraphTitleMobile color={CONTACTS_TEXT}>
            603163, Россия, г. Нижний Новгород,
            ул. Деловая, 13. За ТЦ «Фантастика»
          </ParagraphTitleMobile>
          <ParagraphTitle margin="74px 0 30px" marginMobile="40px 0 30px" fontWeight="bold">
            HR-служба
          </ParagraphTitle>
          <ParagraphTitle color={CONTACTS_TEXT} fontWeight="bold">
          Email: <Link href="mailto:jobs.rus@orioninc.com" noMargin tel>jobs.rus@orioninc.com</Link>
          </ParagraphTitle>
        </FlexItem>
        {/* <FlexItem>
          <ImageContainer imgUrl={office_kazan} tabletImgUrl={office_kazan_tablet} />
          <ParagraphTitle margin="40px 0 30px 0" fontWeight="bold">
            Казань
          </ParagraphTitle>
          <ParagraphTitle color={CONTACTS_TEXT} hideOnMobile fontWeight="bold">
            420021, РФ, Республика Татарстан,<br/>
            Казань, ул. Парижской Коммуны, 26.<br/>
            Здание БЦ «Легенда», 2 этаж.
          </ParagraphTitle>
          <ParagraphTitleMobile color={CONTACTS_TEXT}>
            420021, РФ, Республика Татарстан,
            Казань, ул. Парижской Коммуны, 26.
            Здание БЦ «Легенда», 2 этаж.
          </ParagraphTitleMobile>
          <ParagraphTitle margin="40px 0 30px 0" fontWeight="bold">
            HR-служба
          </ParagraphTitle>
          <ParagraphTitle color={CONTACTS_TEXT} fontWeight="bold">
            Email: <Link href="mailto:jobs.rus@orioninc.com" noMargin tel>jobs.rus@orioninc.com</Link>
          </ParagraphTitle>
        </FlexItem> */}
      </FlexContainer>
    </ContactsContainer>
);

export default OfficesWidget;