import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import {theme} from "@app/styles/theme";
import Button from "@app/components/Buttons/Button";

interface Props {
  borderColor?: string;
  padding?: string;
  paddingTablet?: string;
  paddingMobile?: string;
  hideOnMobile?: boolean;
  fontWeight?: string;
  margin?: string;
  marginMobile?: string;
  marginTablet?: string;
  borderBottom?: string;
}

const Container = styled.div<Props>`
  width: 1170px;
  max-width: calc(100vw - 40px);
  margin: ${props => props.margin ? props.margin : 'auto'};
  padding: ${props => props.padding ? props.padding : undefined};
  border-left: ${props => props.borderColor ? `1px solid ${props.borderColor}` : 'none'};
  color: ${theme.color.primaryColorGrey};
  font-weight: ${props => props.fontWeight ? props.fontWeight : undefined};
  border-bottom: ${props => props.borderBottom ? `1px solid ${props.borderBottom}` : 'none'};

  ${StyleMediaMax.tablet} {
    max-width: calc(100vw - 60px);
    padding: ${props => props.paddingTablet ? props.paddingTablet : undefined};
    margin: ${props => props.marginTablet ? props.marginTablet : undefined};
    margin-left: ${props => props.marginTablet ? undefined : "60px"};

    .meetupOrangeButton {
      margin-right: 19em;
    }
  }

  ${StyleMediaMax.mobileM} {
    display: ${props => props.hideOnMobile ? 'none' : undefined};
    max-width: calc(100vw - 20px);
    padding: ${props => props.paddingMobile ? props.paddingMobile : undefined};
    margin-left: 20px;
    margin: ${props => props.marginMobile ? props.marginMobile : undefined};

    ${Button} {
      margin: 30px 0 56px 0;
    }

    .meetupOrangeButton {
      margin-right: 0;
    }
  }
  .filtersMobileButton button {
    margin: 45px 0 30px 0;
  }
  .meetupsFilters button {
    margin: 30px 0 30px 0;
  }
  .multiSelectMobileButton button {
    margin: 0;
  }
  .meetupGreyButton button{
    margin: 30px 0 0 0;
  }
  .meetupOrangeButton button{
    margin: 30px 0 0 0;
  }

  .videoContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .left .imit-button {
      display: block;
      margin-top: 60px;
      width: 270px;
      height: 50px;
      padding: 14px 89px;
      box-sizing: border-box;
      background-color: #ffffff;
      color: #000000;
      text-decoration: none;

      font-size: 16px;
      font-weight: 500;
      line-height: 1.63;
    }

    @media (max-width: 1200px) {
      .left .imit-button {
        margin-top: 60px;
        width: 270px;
        height: 50px;
        padding: 14px 89px;
      }
    }

    @media (max-width: 700px) {
      .left .imit-button {
        margin-top: 20px;
        width: 100%;
        height: 0;
        min-height: 30px;
        padding: 10px 29px;
        font-size: 10px;
        text-align: center;
      }
    }

    .video {
      height: 320px;
      width: 570px;
      margin-bottom: 60px;

      video {
        width: 570px;
      }
    }

    @media (max-width: 1200px) {
      .videoContainer {
        width: 98%;
      }
      .video {
        height: auto;
        width: inherit;
        margin-top: 60px;
        margin-right: 61px;
        order: 1;

        video {
          height: auto;
          width: inherit;
        }
      }
    }

    @media (max-width: 700px) {
      .videoContainer {
        width: 98%;
      }
      .video {
        height: auto;
        width: inherit;
        margin-bottom: 60px;
        margin-right: 21px;
        margin-top: 60px;
        order: 1;

        video {
          height: auto;
          width: inherit;
        }
      }
    }

    .left {
      margin-left: 30px;
      color: #ffffff;
      width: 450px;

      p {
        font-size: 24px !important;
        line-height: 28px;
        font-weight: 500;
      }
    }

    @media (max-width: 1200px) {
      .left {
        margin-left: 0;
        width: 100%;
        margin-right: 60px;

        p {
          font-size: 16px !important;
          line-height: 28px;
          border-left: 1px solid #fff;
          padding-left: 30px;
          font-weight: normal;
        }
      }

      @media (max-width: 700px) {
        .left {
          margin-right: 20px;
          width: 100%;

          p {
            font-size: 14px !important;
            line-height: 1.63;
            border-left: 1px solid #fff;
            padding-left: 20px;
            font-weight: 500;
          }
        }
      }
    }
  }
`;

export default Container;
