import styled from 'styled-components';

interface Props {
  isSending: boolean;
}

const LoadingWrapper = styled.div<Props>`
  opacity: ${props => props.isSending ? '0.3' : undefined};
`;

export default LoadingWrapper;