import styles from 'styled-components';

const ModalWindowWrapper = styles.div`
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

const ModalWindowContentWrapper = styles.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: black;
    opacity: .4;
  }
`;

export { ModalWindowWrapper, ModalWindowContentWrapper };
