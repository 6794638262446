import curatorImage from './assets/curator-image.jpg';
import firstPoster from './assets/firstPoster.jpg';
const firstVideo  = require('../assets/firstVideo.mp4');

export interface IVacanciesElement {
  position: string;
  location: string;
  description: {
    customer: string;
    video?: {
      url: string;
      poster: string;
    };
    tasks: { text: string; id: number }[];
    skills: { text: string; id: number }[];
    curator: {
      name: string;
      image: string;
      link: string;
      textLink: string;
    };
  };
  id: number;
};

export type IVacancies = IVacanciesElement[];

export const vacancies: IVacancies = [
  {
    position: 'Node.js developer (Medicine)',
    location: 'N. Novgorod/ Kazan/ Remote',
    description: {
      customer: 'Разрабатываем медицинские порталы для ранней диагностики заболеваний. Программа позволяет заказать генетический тест из любой точки мира, вызвать специалиста для забора крови, привезти материал в лабораторию, далее – обработать его и отдать результат пациенту.',
      tasks: [
        {
          text: '– решать задачи с высоким уровнем свободы;',
          id: 0,
        },
        {
          text: '– разрабатывать с нуля новые фичи для автоматизации процесса диагностики генетических заболеваний;',
          id: 1,
        },
        {
          text: '– работать в распределенной команде;',
          id: 2,
        },
        {
          text: '– принимать участие в ежедневных синкапах, работать по Scrum.',
          id: 3,
        },
      ],
      skills: [
        {
          text: '– опыт разработки под стек:',
          id: 0,
        },
        {
          text: '• NodeJS, Express, Typescript, React',
          id: 1,
        },
        {
          text: '• REST, GraphQL, JSON',
          id: 2,
        },
        {
          text: '• Apache Kafka',
          id: 3,
        },
        {
          text: '• Webpack, Docker, AWS',
          id: 4,
        },
        {
          text: '• Confluence, JIRA, Bitbucket, Bamboo',
          id: 5,
        },
      ],
      curator: {
        name: 'Дина',
        image: curatorImage,
        link: 'https://t.me/DinaYakina',
        textLink: '@dinayakina',
      }
    },
    id: 0,
  },
  {
    position: 'Web developer<br />(Video Surveillance platform)',
    location: 'N. Novgorod/ Kazan/ Remote',
    description: {
      customer: 'Создаем облачную платформу для хранения записей видеонаблюдения. Отличная возможность поработать с облачными технологиями на базе AWS и прокачать английский язык в интернациональной команде.',
      tasks: [
        {
          text: '– разрабатывать архитектуру и дизайн продукта с нуля;',
          id: 0,
        },
        {
          text: '– использовать облачные технологии на базе AWS;',
          id: 1,
        },
        {
          text: '– улучшать свой уровень английского в интернациональной команде;',
          id: 2,
        },
        {
          text: '– работать удаленно или в офисе в Нижнем Новгороде.',
          id: 3,
        },
      ],
      skills: [
        {
          text: '– опыт работы с JavaScript/ Angular от 3х лет;',
          id: 0,
        },
        {
          text: '– приветствуется опыт работы с Flutter, Dart;',
          id: 1,
        },
        {
          text: '– знание протоколов WebRTC, Web Sockets, HLS;',
          id: 2,
        },
        {
          text: '– опыт с Cloud providers (Azure, Google).',
          id: 3,
        },
      ],
      curator: {
        name: 'Дина',
        image: curatorImage,
        link: 'https://t.me/DinaYakina',
        textLink: '@dinayakina',
      }
    },
    id: 1,
  },
  {
    position: 'Frontend developer<br />(Cloud Systems)',
    location: 'N. Novgorod/ Kazan/ Remote',
    description: {
      customer: 'Продукт объединяет функции обмена сообщениями, IP-телефонии, видео-конференций, а также интегрированных контакт-центров. У нас хорошо выстроены процессы, и четко поставлены цели и сроки.',
      video: {
        url: firstVideo,
        poster: firstPoster,
      },
      tasks: [
        {
          text: '– писать много чистого и красивого кода на Angular 8.0/TypeScript;',
          id: 0,
        },
        {
          text: '– активно участвовать в проектировании и реализации UX/UI;',
          id: 1,
        },
        {
          text: '– писать Unit и End-to-End тесты на написанную функциональность;',
          id: 2,
        },
        {
          text: '– использовать современное рабочее окружение (Git, GitLab, Docker).',
          id: 3,
        },
      ],
      skills: [
        {
          text: '– опыт разработки коммерческих веб-приложений с использованием Angular;',
          id: 0,
        },
        {
          text: '– умение делать адаптивную верстку;',
          id: 1,
        },
        {
          text: '– умение работать с Git.',
          id: 2,
        },
      ],
      curator: {
        name: 'Дина',
        image: curatorImage,
        link: 'https://t.me/DinaYakina',
        textLink: '@dinayakina',
      }
    },
    id: 2,
  },
];
