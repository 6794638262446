import React from 'react';
import PolicyPageWrapper from './PolicyPageWrapper';
import PolicyParagraph from './PolicyParagraph';
import { Container, ParagraphTitle, Title } from '@app/components/PageLayout';
import { ExternalLink } from '@app/components/Links';

const PolicyPage = () => {
  const policy = 'media/static/policy.pdf';

  return (
    <PolicyPageWrapper padding="135px 0 0" paddingTablet="70px 0 0" paddingMobile="60px 0 0">
      <Container>
        <Title>
          Политика кон&shy;фи&shy;ден&shy;ци&shy;аль&shy;но&shy;сти
        </Title>
      <Container padding="60px 0">
          <PolicyParagraph>
          Мы создали данную Политику конфиденциальности вследствие нашей приверженности защите
          персональных данных. Данная Политика конфиденциальности (далее - «Политика») применяется
          к персональным данным, которые могут быть получены на вебсайте career.orioninc.ru (далее -
          «Сайт»), включая имена, адреса электронной почты, телефонные номера и любые другие данные,
          которые могут считаться персональными и могут быть использованы в коммерческих целях.
          Пользование данным Сайтом означает, что Вы согласны с условиями настоящей Политики.
          </PolicyParagraph>
        </Container>
       <Container padding="0 0 60px">
          <ParagraphTitle>
            Информация о пользователях
          </ParagraphTitle>
          <PolicyParagraph>
          При посещении Сайта Вы можете пользоваться им без раскрытия своей личности или
          предоставления любой другой персональной информации, однако, чтобы получить все
          преимущества интерактивного пользования Сайтом Вам может потребоваться предоставить
          ограниченную личную или деловую информацию, к примеру, Ваше имя, адрес электронной почты или телефонный номер.
          </PolicyParagraph>
          <PolicyParagraph padding="5px 0 0">
          Мы используем подобные персональные данные для улучшения качества предоставляемых услуг
          на нашем Сайте либо предоставления информации о компании, которая Вам может
          потребоваться. Центр Разработки Orion Innovation (юр. лицо в России ООО «Мера-НН») обязуется
          не продавать или передавать подобную информацию каким-либо образом сторонним компаниям
          или пользователям, не связанным с Центром Разработки Orion Innovation (юр. лицо в России ООО
          «Мера-НН»), в том случае, если данная информация не требуется силовым структурам и/или
          необходима для защиты прав, собственности или безопасности наших сотрудников или сторонних
          компаний либо для предотвращения преступлений и других правонарушений.
          </PolicyParagraph>
        </Container>
       <Container padding="0 0 60px">
          <ParagraphTitle>
            Гарантии
          </ParagraphTitle>
          <PolicyParagraph>
          Центр Разработки Orion Innovation (юр. лицо в России ООО «Мера-НН») в полной мере осознает
          важность защиты персональной информации. Мы прилагаем все необходимые усилия для того,
          чтобы информация, которую Вы можете вводить при посещении нашего Сайта, оставалась
          конфиденциальной и была защищена всеми необходимым техническими средствами, такими как
          брандмауэры и антивирусное ПО, от несанкционированного доступа и/или изменения, раскрытия
          и любого другого использования ненадлежащим образом.
          </PolicyParagraph>
          <PolicyParagraph padding="5px 0 0">
          ООО «Мера НН» соблюдает политику обработки персональных данных в соответствии с
          Федеральным законом от 27 июля 2006 года № 149-ФЗ “Об информации, информационных
          технологиях и о защите информации”, Федеральным законом от 27 июля 2006 года № 152-ФЗ “О
          персональных данных”, постановлением Правительства РФ от 01.11.2012 № 1119 “Об
          утверждении требований к защите персональных данных при их обработке в информационных
          системах персональных данных”. Ознакомиться с Политикой ООО «Мера НН» по обработке
          персональных данных вы можете <ExternalLink
            onClick={()=>{window.open(policy, '_blank');}}>здесь</ExternalLink>.
          </PolicyParagraph>
        </Container>
         <Container padding="0 0 120px" paddingMobile="0 0 70px">
          <ParagraphTitle>
            Контроль за соблюдением политики конфиденциальности
          </ParagraphTitle>
          <PolicyParagraph >
          Если у Вас остались вопросы о конфиденциальности или Вы хотите удалить Ваши персональные
данные из нашей базы, отправьте запрос по адресу <ExternalLink href="mailto:privacy.rus@orioninc.com">privacy.rus@orioninc.com</ExternalLink>. Мы свяжемся с Вами в течение 30 дней.
          </PolicyParagraph>
        </Container>
      </Container>
    </PolicyPageWrapper>
  )
};

export default PolicyPage;
