import React from 'react';

import { theme } from '@app/styles/theme';

import { Title, Container } from '@app/components/PageLayout';
import ComponentsWrapper from './ComponentsWrapper';
import ComponentsItem from './ComponentsItem';
import ComponentsIcon from './ComponentsIcon';

import storage from '@assets/icons/components/storage_icon.svg';
import map from '@assets/icons/components/map_icon.svg';
import visibility from '@assets/icons/components/visibility_icon.svg';
import filter from '@assets/icons/components/filter_icon.svg';

const ComponentsWidget = () => (
  <Container padding="120px 0" paddingMobile="70px 0 70px">
    <Title margin="0 0 60px 0" marginMobile="0 0 40px" color={theme.color.primaryColorGrey}>Компоненты программного комплекса</Title>
    <ComponentsWrapper>
      <ComponentsItem>
        <ComponentsIcon src={storage} />Подсистема сбора, обработки и хранения данных
      </ComponentsItem>
      <ComponentsItem>
        <ComponentsIcon src={map} />Подсистема обработки картографических данных
      </ComponentsItem>
      <ComponentsItem>
        <ComponentsIcon src={visibility} />Подсистема визуализации данных
      </ComponentsItem>
      <ComponentsItem>
        <ComponentsIcon src={filter} />Подсистема прогнозирования на основе погодных условий и исторических данных
      </ComponentsItem>
    </ComponentsWrapper>
  </Container>
);

export default ComponentsWidget;