import styled from "styled-components";
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  imgUrl: string;
  tabletImgUrl: string;
}

const ImageContainer = styled.div<Props>`
  width: 570px;
  height: 380px;
  background-image: ${props => `url(${props.imgUrl})`};

  ${StyleMediaMax.tablet} {
    width: calc(100vw - 120px);
    height: calc((100vw - 120px) / 1.82);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${props => `url(${props.tabletImgUrl})`};
  }

  ${StyleMediaMax.mobileM} {
    width: calc(100vw - 40px);
    height: calc((100vw - 120px) / 1.4);
  }
`;

export default ImageContainer;