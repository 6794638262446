import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Wrapper = styled.div`
  position: relative;
  display:flex;
  z-index: 3;
  
  ${StyleMediaMax.tablet} {
    width: 100%;
    flex-direction: column;
  };

  ${StyleMediaMax.mobileM} {
    width: calc(100% - 20px);;
  };
`;

export default Wrapper;