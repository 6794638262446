import styles from 'styled-components';

interface IStyleWrapper {
  color: string;
  btn: {
    hover: string;
    fill: boolean;
    text: string;
    background: string;
  };
  type?: 'heisenbug' | 'jpoint' | 'holyjs';
};

const StyleWrapper = styles.div<IStyleWrapper>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @font-face {
    font-family: Gerbera Medium;
    font-style: normal;
    font-weight: normal;
    src: url(${require('@app/../assets/fonts/Gerbera-Medium.otf')});
  }

  @font-face {
    font-family: Gerbera Light;
    font-style: normal;
    font-weight: normal;
    src: url(${require('@app/../assets/fonts/Gerbera-Light.otf')});
  }

  @font-face {
    font-family: Gerbera Regular;
    font-style: normal;
    font-weight: normal;
    src: url(${require('@app/../assets/fonts/Gerbera-Regular.otf')});
  }

  * {
    font-family: Gerbera;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -0.04em;
  }

  .event-color {
    color: ${props => props.color};
  }

  .event-btn {
    border-radius: 40px;
    border-color: ${props => props.color};
  }

  .event-border {
    border-color: ${props => props.color};
  }

  .event-bcgr {
    background: ${props => props.color};
  }

  .event-svg-fill {
    & > svg > path {
      fill: ${props => props.color};
    }
  }

  .about-video-wrapper-bcgr:before {
    background: ${props => props.color};
  }

  .about-tabs-wrapper-bcgr {
    background: ${props => props.color};
  }

  .event-paragraph__small {
    font-family: Gerbera Light;
    font-size: 20px;
    line-height: 24px;
  }

  .event-paragraph__middle {
    font-family: Gerbera Light;
    font-size: 20px;
    line-height: 28px;

    @media (max-width: 420px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .event-paragraph__large {
    font-family: Gerbera Medium;
    font-size: 24px;
    line-height: 32px;
  }

  .event-title {
    font-family: Gerbera Regular;
    font-size: 64px;
    line-height: 72px;

    @media (max-width: 640px) {
      font-size: 55px;
      line-height: 60px;
    }

    @media (max-width: 550px) {
      font-size: 45px;
      line-height: 55px;
    }

    @media (max-width: 450px) {
      font-size: 40px;
    }

    @media (max-width: 420px) {
      font-size: 34px;
      line-height: 38px;
    }

    @media (max-width: 350px) {
      font-size: 30px;
    }
  }

  @media (max-width: 420px) {
    & > div {
      padding: 64px 0;
    }
  }

  #language:before {
    display: ${props => props.type === 'heisenbug' && 'none'};
  }

  .contact-form {
    .form-submit {
      & > button {

        & > div {
          background: #27C3F3;

          &:before {
            display: none;
          }
        }
      }
    }
  }

  & > div:not(#about-project), & > #about-project form {
    button {
      border-radius: 40px;
      border-color: ${props => props.color};

      & > div, & > div:before {
        border-radius: 28px;
      }

      &:hover {
        border-color: ${props => props.btn.hover};

        & > div {
          background: ${props => props.btn.hover};
        }
      }

      & > div {
        background: ${props => !props.btn.fill && props.btn.background};
        color: ${props => props.btn.text};

        &:before {
          display: ${props => !props.btn.fill && 'none'};
        }
      }
    }
  }

  form > .form-condition > a {
    color: ${props => props.color};
  }


  #game {
    .game-link {
      background-color: ${props => props.type === 'jpoint' ? '#7B61FF' : '#27C3F3'};
    }
  }

  // about list Heisenbug

  .about-list-tab-Heisenbug {
    width: 100%;
    display: flex;
    padding: 48px 180px 48px 64px;
    justify-content: space-between;
  
    .list-title {
      margin-bottom: 24px;
  
      @media (max-width: 500px) {
        font-size: 20px;
      }
  
      @media (max-width: 420px) {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  
    .list-element__wrapper:first-child {
      width: 33.5%;
  
      @media (max-width: 420px) {
        width: 100%;
  
        .tab-list__main {
          display: flex;
          flex-wrap: wrap;
  
          .list-element {
            width: 33%;
          }
        }
      }
    }
  
    .list-element__wrapper:last-child {
      width: 66.5%;
  
      @media (max-width: 420px) {
        width: 100%;
  
        .tab-list__main {
  
          .list-element {
            width: 33%;
          }
        }
      }
    }
  
    .list-element__wrapper:last-child {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
  
        li {
          width: 40%;
  
          @media (max-width: 500px) {
            width: 45%;
          }
        }
      }
    }
  
    ul {
      .list-element {
        list-style-position: inside;
  
        &:not(last-child) {
          margin-bottom: 15px;
        }
  
        &::marker {
          font-size: 15px;
          color: #27C3F3;
        }
  
        @media (max-width: 500px) {
          font-size: 16px;
        }
  
        @media (max-width: 420px) {
          margin-bottom: 10px !important;
          font-size: 14px;
          line-height: 24px;
  
          &::marker {
            font-size: 10px;
          }
        }
      }
    }
  
    @media (max-width: 1128px) {
      padding-right: 200px;
    }
  
    @media (max-width: 960px) {
      padding: 36px 100px 52px 36px;
    }
  
    @media (max-width: 640px) {
      padding: 16px;
      flex-wrap: wrap;
    }

    @media (max-width: 420px) {
      .list-element__wrapper:last-child {
        flex-direction: row;

        ul > .list-element:nth-child(8) {
          order: 1;
        }
      }
    }
  }

  // about list JPoint

  .about-list-tab-JPoint {
    width: 100%;
    display: flex;
    padding: 48px 11% 0 0;
    justify-content: space-between;
  
    .tech-image {
      width: 47%;
      margin-right: 12%;
  
      @media (max-width: 880px) {
        margin-right: 5%;
      }
  
      @media (max-width: 768px) {
        margin-right: 0;
      }
  
      @media (max-width: 740px) {
        display: none;
      }
  
      & > img {
        width: 100%;
      }
    }
  
    .list-title {
      margin-bottom: 24px;
  
      @media (max-width: 420px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  
    ul {
      .list-element {
        list-style-position: inside;
  
        &:not(last-child) {
          margin-bottom: 15px;
        }
  
        &::marker {
          font-size: 15px;
          color: #7B61FF;
        }
  
        @media (max-width: 550px) {
          font-size: 16px;
  
          &::marker {
            font-size: 12px;
          }
        }
  
        @media (max-width: 420px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  
    @media (max-width: 960px) {
      padding: 24px 5% 0 0;
    }
  
    @media (max-width: 750px) {
      padding-right: 2%;
    }
  
    @media (max-width: 640px) {
      padding: 20px 0 0;
      flex-wrap: wrap;
    }
  }

  // about list holyjs

  .about-list-tab-HolyJS {
    width: 100%;
    display: flex;
    padding: 48px 150px 0 0;
    justify-content: space-between;
  
    .list-title, .list-element__wrapper:nth-child(2) > ul > li:nth-child(4) {
      margin-bottom: 24px;
      font-family: Gerbera Medium;
      font-size: 24px;
      line-height: 32px;
  
      @media (max-width: 768px) {
        margin-bottom: 16px;
      }
  
      @media (max-width: 730px) {
        font-size: 20px;
        line-height: 28px;
      }
  
      @media (max-width: 600px) {
        font-size: 18px;
      }
  
      @media (max-width: 500px) {
        font-size: 16px;
      }
  
      @media (max-width: 420px) {
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 24px;
      }
    }
  
    .list-element__wrapper:nth-child(1), .list-element__wrapper:nth-child(2) {
      @media (max-width: 420px) {
        width: 47%;
      }
    }
  
    .list-element__wrapper:last-child {
      @media (max-width: 420px) {
        width: 100%;
      }
    }
  
    .list-element__wrapper:nth-child(2) > ul > .list-element:nth-child(4)::marker {
      font-size: 0;
    }
  
    ul {
      .list-element {
        list-style-position: inside;
        margin-bottom: 15px;
  
        &::marker {
          font-size: 15px;
          color: #27C3F3;
        }
  
        @media (max-width: 600px) {
          font-size: 18px;
        }
  
        @media (max-width: 500px) {
          font-size: 16px;
  
          &::marker {
            font-size: 12px;
          }
        }
  
        @media (max-width: 420px) {
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  
    @media (max-width: 1128px) {
      padding-right: 90px;
    }
  
    @media (max-width: 960px) {
      padding: 36px 0 0;
  
      .list-element__wrapper {
        width: 30%;
      }
    }

    @media (max-width: 768px) {
      padding: 24px 0 0;
    }
  
    @media (max-width: 640px) {
      padding: 16px 0 0;
      flex-wrap: wrap;
    }
  }
`;

export default StyleWrapper;
