import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  isLanding?: string;
};

const Wrapper = styled.div<Props>`
  padding-top: ${(props) => props.isLanding === 'landing' ? '100px' : '120px'};
  padding-top: ${(props) => props.isLanding === 'conferences' ? '0' : undefined};
  position: relative;

  ${StyleMediaMax.tablet} {
    padding-top: 60px;
    padding-top: ${(props) => props.isLanding === 'conferences' ? '0' : undefined};
  }

  ${StyleMediaMax.tabletS} {
    padding-top: ${(props) => props.isLanding === 'landing' ? '0px' : '60px'};
    padding-top: ${(props) => props.isLanding === 'conferences' ? '0' : undefined};
  }
  
`;

export default Wrapper;