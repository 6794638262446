import React, { useEffect, useContext } from 'react';

import IntroductionWidget from './widgets/IntroductionWidget';
import DevCentersWidget from './widgets/DevCentersWidget';
import IndustryWidget from './widgets/IndustryWidget';
import CustomerWidget from './widgets/CustomerWidget';
import CounterWidget from './widgets/CounterWidget';
import JoinUsWidget from './widgets/JoinUsWidget';
import LandingContext from '@app/components/Builder/LandingContext';

const AboutUsPage = () => {
  const { setIsLanding } = useContext(LandingContext);

  useEffect(()=>{
    setIsLanding('AboutUs');
    return () => setIsLanding('');
  }, [setIsLanding])

  return (
    <>
      <IntroductionWidget />
      {/* <DevCentersWidget /> */}
      <IndustryWidget />
      {/* <CustomerWidget /> */}
      <CounterWidget />
      {/* <JoinUsWidget /> */}
    </>
  )
};

export default AboutUsPage;