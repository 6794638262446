import styled from 'styled-components';
import empty_image from '@assets/illustrations/empty_news_image.svg';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  bg?: string;
}

const EmptyNewsImg = styled.div<Props>`
  min-width: 225px;
  background-color: ${props => props.bg ? undefined : props.theme.color.inputColor};
  background-image: ${ props => props.bg ? `url(${props.bg})` : `url(${empty_image})`};
  background-repeat: no-repeat;
  background-size: ${props => props.bg && 'cover'};
  background-position: center center;

  ${StyleMediaMax.mobileM} {
    display: none;
  }
`;

export default EmptyNewsImg;