import React from 'react';
import ContactsContainer from './ContactsContainer';
import ContactsWrapper from './ContactsWrapper';
import MailText from './MailText';
import SectionTitle from '../SectionTitle';

const Contacts = () => (
  <ContactsWrapper>
    <SectionTitle margin="0 0 60px 0">Контакты</SectionTitle>
    <ContactsContainer lineHeight="3.31">
      Юр лицо: ООО «Мера НН»<br />
      603163, Россия, г. Нижний Новгород ул. Деловая, 13<br />
    </ContactsContainer>
    <SectionTitle margin="60px 0 30px 0">HR-служба</SectionTitle>
    <ContactsContainer margin="30px 0" lineHeight="2.25">
      Email: <MailText href="mailto:jobs.rus@orioninc.com">jobs.rus@orioninc.com</MailText>
    </ContactsContainer>
  </ContactsWrapper>
);

export default Contacts;
