import React from 'react';

import { theme } from '@app/styles/theme';

import { Container, PageTitle, Paragraph } from '@app/components/PageLayout';
import IntroductionWrapper from './IntroductionWrapper';
import AwardIcon from './AwardIcon';
import AwardDescription from './AwardDescription';
import AwardContainer from './AwardContainer';
import AwardWrapper from './AwardWrapper';
import introWidget from '@assets/introWidget.png';
import rect from '@assets/Rectangle.svg';

const WHITE = theme.color.white;
const BORDER_TRANSPARENT = theme.color.transparentDisabled;

const IntroductionWidget = () => (
  <IntroductionWrapper layerOne={rect} layerTwo={introWidget} padding="0 0 120px">
    <Container borderColor={BORDER_TRANSPARENT} padding="194px 0 0" paddingTablet="70px 0 0">
      <PageTitle color={WHITE} margin="0 0 60px 0">
        About Mera NN<br/>(An Orion Innovation company)
      </PageTitle>
      <Paragraph borderColor={WHITE} color={WHITE}>
      We are a digital product development division at Orion Innovation formerly known as MERA.<br/><br/>
      Throughout a 30-year history, MERA has come an interesting way from a handful of enthusiasts to a global corporation, forming an impressive portfolio of clients all over the globe that includes industry leaders as well as small and medium business representatives. In 2019, the company joined forces with several international IT vendors under the brand name of Orion Innovation. For more information about Orion Innovation please visit global corporate website <a href="https://www.orioninc.com/">www.orioninc.com</a>  
      </Paragraph>
    </Container>
    <Container>
      <AwardWrapper>
        <AwardContainer>
          <AwardIcon />
          <AwardDescription>The European Software <br /> Testing Awards 2020</AwardDescription>
        </AwardContainer>
        <AwardContainer>
          <AwardIcon />
          <AwardDescription>IAOP’s 2021 Best of The <br /> Global Outsourcing 100</AwardDescription>
        </AwardContainer>
      </AwardWrapper>
    </Container>
  </IntroductionWrapper>
);

export default IntroductionWidget;
