import React from 'react';

import Wrapper from './Wrapper';
import EyeImg from './EyeImg';
import eye from '@assets/icons/outline_eye.svg';

interface Props {
  views: number;
}

const Views: React.FC<Props> = ({ views }) => (
  <Wrapper>
    <EyeImg src={eye} alt="" />{views}
  </Wrapper>
);

export default Views;