import styled from 'styled-components';
import { StyleMediaMax, StyleMediaMin } from '@app/components/StyleMedia';

interface Props {
  general?: boolean;
  margin?: string;
  lineHeight: string;
}

const ContactsContainer = styled.div<Props>`
  line-height: ${props => props.lineHeight};
  margin: ${props => props.margin ? props.margin : undefined};
  font-weight: 400;

  ${StyleMediaMax.tablet} {
    margin: ${props => props.margin ? props.margin : '0 0 60px'};

    ${StyleMediaMin.mobileM} {
      margin: ${props => props.margin ? props.margin : '0 0 60px'};
    }
  }

  ${StyleMediaMax.mobileM} {
    margin-bottom: 70px;
  }
`;

export default ContactsContainer;
