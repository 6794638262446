import React, { useState } from 'react';
import Input from '../../components/Input';
import Form from '../../components/Form';
import Textarea from '../../components/TextArea';

const ContactForm = ({ type }: {type: 'Heisenbug' | 'JPoint' | 'HolyJS'}) => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [question, setQuestion] = useState('');
  const [validate, setValidate] = useState<string[]>([]);

  const submit = () => {
    if (validate.length) {
      setValidate([]);
    }

    const fields = [
      {dataName: 'name', value: name},
      {dataName: 'surname', value: surname},
      {dataName: 'email', value: email},
    ];

    const tempValidate: string[] = [];
    
    fields.forEach((field) => {
      if (field.dataName === 'email' && (!field.value.includes('@') || !field.value)) {
        tempValidate.push(field.dataName);
      } else {
        if (!field.value) {
          tempValidate.push(field.dataName);
        }
      }
    });

    setValidate(tempValidate);

    if (!tempValidate.length) {
      const body = `Имя: ${name} Фамилия: ${surname} Email: ${email} Телефон: ${phone} Вопрос: ${question}`;
      const mailToLink = `mailto:event.rus@orioninc.ru?subject=${type}, сообщение&body=${body}`;
      window.location.assign(mailToLink);
    }
  };

  return (
    <Form
      titleForm='Есть вопросы? Напиши нам!'
      submit={submit}
    >
      <Input
        width='48%'
        required={true}
        placeholder='Имя'
        type='text'
        value={name}
        setValue={setName}
        dataFailure={validate.includes('name')}
      />
      <Input
        width='48%'
        required={true}
        placeholder='Фамилия'
        type='text'
        value={surname}
        setValue={setSurname}
        dataFailure={validate.includes('surname')}
      />
      <Input
        width='48%'
        required={true}
        placeholder='Email'
        type='text'
        value={email}
        setValue={setEmail}
        dataFailure={validate.includes('email')}
      />
      <Input
        width='48%'
        placeholder='Телефон'
        type='text'
        value={phone}
        setValue={setPhone}
      />
      <Textarea
        width='100%'
        placeholder='Хотите задать вопрос?'
        value={question}
        setValue={setQuestion}
      />
    </Form>
  );
};

export default ContactForm;
