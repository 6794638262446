import styled from "styled-components";
import { StyleMediaMax } from '@app/components/StyleMedia';
import { SubParagraph } from "@app/components/PageLayout";

import not_found_image from "@assets/illustrations/404.jpg";
import { Wrapper } from "@app/components/PageLayout";

const NotFoundWrapper = styled(Wrapper)`
  background-color: black;
  background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.6) 100%), url(${not_found_image}) center left repeat-x;
  ${StyleMediaMax.tablet} {
    background-position: 400px 195px;
    background-size: 475px;
  }

  ${StyleMediaMax.mobileM} {
    background-position: -25px 365px;
    background-size: 140%;

    ${SubParagraph} {
      margin: 30px 0;
    }
  }
`;

export default NotFoundWrapper;