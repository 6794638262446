import styled from 'styled-components';
import filterImg from '@assets/icons/filter_list.svg';

const Icon = styled.span`
  width: 24px;
  height: 24px;
  mask-image: url(${filterImg});
  background-color: ${props => props.theme.color.primaryColorGrey};
  margin: 0 15px 0 20px;
`;

export default Icon;
