import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const CounterWrapper = styled.div`
  display: flex;
  flex: 1 1;

  ${StyleMediaMax.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export default CounterWrapper;