import styled from 'styled-components';
import arrow_down from '@assets/icons/dark_arrow_down.svg';
import arrow_up from '@assets/icons/dark_arrow_up.svg';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  isOpen: boolean;
}

const SelectControl = styled.div<Props>`
  min-width: 270px;
  height: 50px;
  line-height: 50px;
  padding: 0 60px 0 20px;
  box-shadow: ${props => props.isOpen ? '0 8px 20px 0 rgba(8, 41, 74, 0.15)' : undefined};
  background-color: ${props => props.isOpen ? props.theme.color.white : '#f0f3f6'};
  background-image: ${props => props.isOpen ? `url(${arrow_up})` : `url(${arrow_down})`};
  background-repeat: no-repeat;
  background-position: right 20px center;
  cursor: pointer;

  ${StyleMediaMax.mobileM} {
    box-shadow: unset;
    background-color: #f0f3f6;
  }
`;

export default SelectControl;
