import styled from 'styled-components';

interface Props {
  src: string;
}
const Icon = styled.div<Props>`
  height: 100%;
  width: 54px;
  background: ${props => `url(${props.src}) no-repeat center center`};

  &:hover{
    cursor: pointer;
  }
`;

export default Icon;