import React from 'react';

import { theme } from '@app/styles/theme';

import { Wrapper, Container, Title } from '@app/components/PageLayout';
import NumberContainer from '@app/components/pages/HomePage/widgets/CounterWidget/NumberContainer';
import AboutCounterWrapper from './AboutCounterWrapper';
import CounterItem from '@app/components/pages/HomePage/widgets/CounterWidget/CounterItem';
import DescriptionContainer from '@app/components/pages/HomePage/widgets/CounterWidget/DescriptionContainer';
import SubDescription from './SubDescription';
import MobileDescription from './MobileDescription';

const COLOR = theme.color.primaryColorGrey;
const BORDER = theme.color.borderLight;
const BACKGROUND = theme.color.breadCrumbsText;
const IS_ABOUT = true;

const CounterWidget = () => (
  <Wrapper background={COLOR}>
    <Container borderColor={BORDER}>
      <Title position="absolute" color={COLOR} margin="0 0 60px" paddingTablet="0 60px 0 0" paddingMobile="0 20px 0 0">Центр Разработки Orion Innovation</Title>
      <AboutCounterWrapper>
        <CounterItem borderColor={BORDER} paddingTop="165px" isAbout={IS_ABOUT}>
          <NumberContainer showMobileBorder color={COLOR} backgroundColor={BACKGROUND} padding="0 0 0 15px" marginTablet="0 0 0 -1px">1200+</NumberContainer>
          <DescriptionContainer hideOnMobile borderColor={COLOR} color={COLOR}>специалистов <br /> в сфере IT</DescriptionContainer>
          <MobileDescription borderColor={COLOR} color={COLOR}>специалистов в сфере IT</MobileDescription>
          <SubDescription mobilePadding="20px 0 10px 15px">18% - Managers <br /> 32% - Senior <br /> 31% - Middle <br /> 19% - Junior </SubDescription>
        </CounterItem>
        <CounterItem borderColor={BORDER} borderMobile="true" isAbout={IS_ABOUT}>
          <NumberContainer showMobileBorder color={COLOR} backgroundColor={BACKGROUND} padding="0 0 0 15px" marginTablet="0 0 0 -1px">20+</NumberContainer>
          <DescriptionContainer hideOnMobile borderColor={COLOR} color={COLOR}>языков <br /> программирования</DescriptionContainer>
          <MobileDescription borderColor={COLOR} color={COLOR}>языков программирования</MobileDescription>
          <SubDescription mobilePadding="20px 0 10px 15px">75% - разработка ПО <br /> 25% - поддержка и багфикс</SubDescription>
        </CounterItem>
        <CounterItem borderColor={BORDER} borderMobile="true" isAbout={IS_ABOUT}>
          <NumberContainer showMobileBorder tablet color={COLOR} backgroundColor={BACKGROUND} padding="0 0 0 15px" marginTablet="0 0 0 -62px">150+</NumberContainer>
          <DescriptionContainer hideOnMobile borderColor={COLOR} color={COLOR}>проектов разного<br />уровня сложности</DescriptionContainer>
          <MobileDescription borderColor={COLOR} color={COLOR}>проектов разного уровня сложности</MobileDescription>
          <SubDescription mobilePadding="20px 0 70px 15px">действуют программы ротаций <br /> и развития инженерного резерва,<br />внутренний фриланс </SubDescription>
        </CounterItem>
        <CounterItem borderColor={BORDER} borderMobile="true" marginRight="60px">
          <NumberContainer last={true} backgroundColor={BACKGROUND} marginTablet="0 0 0 -1px"/>
        </CounterItem>
      </AboutCounterWrapper>
    </Container>
  </Wrapper>
);

export default CounterWidget;
