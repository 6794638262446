import styled from 'styled-components';
import { StyleMediaMin, StyleMediaMax } from '@app/components/StyleMedia';
import Dropdown from '@app/components/Dropdown/Container';

const Wrapper = styled.div`
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 176px;

  ${StyleMediaMin.tablet} {
    ${Dropdown} {
      width: max-content;
    }
  }

  ${StyleMediaMax.laptop} {
    margin-left: 50px;
  }

  ${StyleMediaMax.tablet} {
    position: absolute;
    top: 80px;
    right: 20px;
    width: 280px;
    padding: 20px;
    margin :0;
    flex-direction: column;
    border-radius: 8px;
    z-index: 3;
    box-shadow: ${props => `0 4px 10px 0 ${props.theme.color.shadow}`};
    background-color: ${props => props.theme.color.white};

    ${StyleMediaMin.mobileM} {
      > :not(:first-child) {
        margin-top: 30px;
      }
    }
  }

  ${StyleMediaMax.tablet} {
    left: 0;
    top: 0;
    padding: 60px;
    margin: 59px 0 0;
    width: 100vw;
    height: calc(100vh - 60px);
    justify-content: flex-start;
    border-top: 1px solid #e5e9ec;
    border-radius: 0;
    box-shadow: none;
    >* {
      margin-bottom: 40px;
    }
  }
`;

export default Wrapper;