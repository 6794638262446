import React from 'react';
import * as GameElement from './Style';
import gameImg from './assets/game-image.png';
import gameImgBlue from './assets/game_image_blue.png';
import { TLandingType } from '../../types';

type GameProps = {link: string, type?: TLandingType};

const Game = React.forwardRef<HTMLDivElement, GameProps>(({link, type}, ref) => (
  <GameElement.Wrapper id='game' ref={ref} type={type}>
    <GameElement.ContentWrapper>
      <GameElement.DescriptionContent>
        <h1 className='game-title-mini'>
          Побеждай <br />в Star Game!
        </h1>
        <GameElement.GameImage>
          <img src={type === 'JPoint' ? gameImg : gameImgBlue} alt="game"/>
        </GameElement.GameImage>
        <GameElement.GameDescription>
          <h1 className='game-title'>
            Побеждай <br />в Star Game!
          </h1>
          <div className='game-description'>
            <p className='event-paragraph__middle'>
              Космический корабль потерял управление. <br />
              Напиши правильный алгоритм и спаси команду!
            </p>
            <p className='event-paragraph__middle'>
              Награждаем победителя каждый день в 17:30. <br />
              После этого обновляем задание – у тебя есть шанс получить 5 призов, действуй!
            </p>
          </div>
          <div className='game-link'>
            <a href={link} target='_blank' rel='noopener noreferrer'>
              Играть!
            </a>
          </div>
        </GameElement.GameDescription>
      </GameElement.DescriptionContent>
    </GameElement.ContentWrapper>
  </GameElement.Wrapper>
));

export default Game;
