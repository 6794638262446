import React, { useState } from 'react';
import * as VacancyElement from './Style';
import VacancyCard from './VacancyCard';
import { IVacancies } from '../../JPoint/vacancies';

interface IVacancyState {
  show: boolean,
  id: null | number,
};

const Vacancy = ({ data, color } : { data: IVacancies, color: string }) => {
  const [currentVacancy, setCurrentVacancy] = useState<IVacancyState>({ show: false, id: null });

  return (
    <VacancyElement.Wrapper>
      <VacancyElement.ContentWrapper>
        <VacancyElement.Title className='vacancy-title event-title'>
          Вакансии
        </VacancyElement.Title>
        <VacancyElement.Description className='vacancy-title__description event-paragraph__middle'>
          Приглашаем IT-специалистов из России присоединиться к команде для работы в офисе или удаленно.
        </VacancyElement.Description>
        <VacancyElement.VacanciesWrapper color={color}>
          {data.map((info) => (
            <VacancyCard
              key={info.id}
              info={info}
              currentVacancy={currentVacancy}
              setVacancy={setCurrentVacancy}
              color={color}
            />
          ))}
        </VacancyElement.VacanciesWrapper>
      </VacancyElement.ContentWrapper>
    </VacancyElement.Wrapper>
  );
};

export default Vacancy;
