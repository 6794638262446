import styled from 'styled-components';
import DateContainer from './DateContainer';
import { FlexContainer } from '@app/components/PageLayout';

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 30px;

  ${DateContainer} {
    margin-bottom: 30px;
  }

  ${FlexContainer} {
    margin-top: 30px;
  }
`

export default ContentContainer;