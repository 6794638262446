import React from 'react';

import WelcomeWidget from './widgets/WelcomeWidget';
import CounterWidget from './widgets/CounterWidget';
import IndustryWidget from './widgets/IndustryWidget';
import VacancyWidget from './widgets/VacancyWidget';
import SliderWidget from './widgets/SliderWidget';
import ProjectsWidget from './widgets/ProjectsWidget';
import NewsWidget from './widgets/NewsWidget';
import { IDataStatus } from '@app/components/PagesRouter/PagesRouter';

const HomePage = ({ data, loading }: { data: IDataStatus, loading: boolean }) => (
  <>
    <WelcomeWidget data={data} loading={loading} />
    <CounterWidget />
    <IndustryWidget page="home" />
    {/* <VacancyWidget />
    <SliderWidget />
    <ProjectsWidget />
    <NewsWidget /> */}
  </>
);

export default HomePage;