import React, { useCallback, useRef } from 'react';

import Dropdown from '@app/components/Dropdown';
import search_default from '@assets/icons/search_default.svg';
import search_active from '@assets/icons/search_active.svg';

import StyledInput from './StyledInput';
import Container from './Container';
import HintsContainer from './HintsContainer';
import HintWrapper from './HintWrapper';
import EmptyHint from './EmptyHint';
import CloseIcon from './CloseIcon';
import SearchIcon from './SearchIcon';
import { Hint } from '@app/components/Header/SearchWrapper/SearchWrapper';
import { api } from "@app/api/api";
import debounce from 'lodash/debounce';

interface Props {
  value: string;
  placeholder: string;
  isHintOpen: boolean;
  hints: Hint[];
  setHints: (value: Hint[]) => void;
  setValue: (value: string) => void;
  setHintOpen: (value: boolean) => void;
  isRequestOpen?: boolean;
  setRequestOpen: (value: boolean) => void;
  closeSearch?: () => void;
  focusInput?: boolean;
}

interface VacanciesData {
  career_id: string;
  name: string;
  tags: number[];
}

const dropdownContent = (
  hints: Hint[],
  onChange: (val: string) => void,
  setHintOpen: (val: boolean) => void,
  setRequestOpen: (val: boolean) => void,
  closeSearch?: () => void) => {

  return (
    <HintsContainer>
      {
        hints.length === 0 ? <EmptyHint>По вашему запросу ничего не найдено</EmptyHint>
          :
          hints.map(hint =>
            <HintWrapper
              key={hint.to}
              to={hint.to}
              title={`${hint.title}`}
              onClick={() => {
                onChange('');
                setHintOpen(false);
                setRequestOpen(false);
                closeSearch && closeSearch();
              }}
            >
              <p>{hint.title}</p>
            </HintWrapper>)
      }
    </HintsContainer>
  )
};

export const filterHints = (
  value: string,
  setHints: (hints: Hint[]) => void,
  setHintOpen: (value: boolean) => void
) => {
  if (value.trim() !== '' && value.trim().length > 1) {
    api.get(`vacancy/search/?value=${value}`)
      .then(result => {
        const usedHints: Hint[] = result.data.map((hint: VacanciesData) => {
          return ({
            title: hint.name,
            to: `/vacancy/${hint.career_id}`
          })
        }).sort();
        setHints(usedHints);
        setHintOpen(true);
      })
  } else {
    setHintOpen(false);
  }
}

export const showSearchResult = (
  value: string
) => {
  if (value.trim() !== '' && value.trim().length > 1) {
    api.get(`vacancy/search/?value=${value}`)
      .then(result => {
        let tags: number[] = [];
        result.data.forEach((vacancy: VacanciesData) => {
          vacancy.tags.forEach((tag) => {
            tags.push(tag);
          });
        });
        const uniqTags: number[] = tags.filter((tag, i, ar) => ar.indexOf(tag) === i);
        window.open('/vacancy/?tags=' + uniqTags.join('&tags='));
      })
  }
}

const SearchInput = ({
  value,
  placeholder,
  isHintOpen,
  hints,
  setHints,
  setValue,
  setHintOpen,
  isRequestOpen,
  setRequestOpen,
  closeSearch,
  focusInput
}: Props) => {

  const ref = useRef<HTMLInputElement>(null);

  const debounceCallback = useCallback(
    debounce((item: string) => {
      filterHints(item, setHints, setHintOpen)
    }, 300),
    []
  );

  const debounceEvent = (e: any) => {
    setValue(e.target.value)
    debounceCallback(e.target.value)
  }

  const onClose = () => {
    setValue('');
    setHintOpen(false);
    setRequestOpen(false);
    closeSearch && closeSearch();
  }

  const focusHandler = () => {
    const input = ref.current;
    input && input.focus();
  }

  return (
    <Dropdown
      top="10px"
      isOpen={isHintOpen}
      onClose={() => { }}
      content={dropdownContent(hints, setValue, setHintOpen, setRequestOpen, closeSearch)}
      padding="20px 0 20px 20px"
    >
      <Container>
        <StyledInput ref={ref} autoFocus={focusInput}
          type="text"
          maxLength={30}
          value={value}
          placeholder={placeholder}
          onFocus={() => {
            setRequestOpen(true);
          }}
          onChange={debounceEvent}
          onKeyDown={(event) => {
            if (event.key === 'Escape') {
              setValue('');
              filterHints('', setHints, setHintOpen);
            } else if (event.key === 'Enter') {
              showSearchResult(value);
            }
          }}
        />
        {(value !== '' || closeSearch) && <CloseIcon onClick={onClose} />}
        <SearchIcon src={isRequestOpen || value !== '' ? search_active : search_default}
          onClick={() => {
            focusHandler();
            showSearchResult(value);
          }} />
      </Container>
    </Dropdown>
  );
}

export default SearchInput;
