import { css } from 'styled-components';
import sizes, { MediaQueries } from './sizes';


const StyleMediaMax: MediaQueries = {};

for (let [key, value] of Object.entries(sizes)) {
  StyleMediaMax[key] = css`@media(max-width: ${value}px)`;
}

export default StyleMediaMax;
