import React from 'react';
import InputContainer, { InputProps } from './InputContainer';
import Tooltip from '../Tooltip';
import InputMask from 'react-input-mask';

interface Props extends InputProps {
  name: string,
  placeholder: string,
  autoComplete: string,
  value: any,
  onChange: (e: React.ChangeEvent<any>) => void,
  onBlur: (e: React.FocusEvent<any>) => void,
  margin: string,
  errorMessage?: string;
  backgroundColor?: string;
  mask?: string;
}

const Input: React.FC<Props> = ({ margin, errorMessage, ...props }) => (
  <InputContainer margin={margin}>
    {(props.error && errorMessage) && <Tooltip id="tooltip">{errorMessage}</Tooltip>}
    <InputMask {...props} mask={props.mask || ""} />
  </InputContainer>
);

export default Input;