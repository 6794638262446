import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import Tooltip from '../Tooltip';

const TextareaContainer = styled.div`
  position: relative;
  width: 570px;

  ${Tooltip} {
    bottom: 120px;
  }

  ${StyleMediaMax.tablet} {
    max-width: calc(100vw - 40px);
    width: unset;
  }
`;

export default TextareaContainer;