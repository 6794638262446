import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

 interface Props{
   src: string;
   hover: string;
 }

const SocialMediaIcon = styled.a<Props>`
  display: inline-block;
  height: 35px;
  width: 35px;
  margin-right: 25px;
  cursor: pointer;
  background-image: ${props => `url(${props.src})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  
  &:hover{
    background-image: ${props => `url(${props.hover})`};
  }

  ${StyleMediaMax.mibileL} {
    &:last-child {
      margin-right: 0;
    }
  }
`;

export default SocialMediaIcon;