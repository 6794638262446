import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const HintsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(80vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar
  {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb
  {
    border-radius: 4px;
    background-color: ${p => p.theme.color.borderLight};
  }

  ${StyleMediaMax.tablet} {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    padding: 20px;
    box-shadow: ${props => `0 4px 10px 0 ${props.theme.color.shadow}`};
    background-color: ${props => props.theme.color.white};
    max-height: calc(100vh - 120px);
  }
`;
export default HintsContainer;