import styled from 'styled-components';
import {theme} from "@app/styles/theme";

const ListWrapper = styled.ul`
  list-style-position: inside;
  list-style-type: disc;
  li {
    color: ${theme.color.primaryColorGrey};
  }

`;

export default ListWrapper;