import React, { ComponentState, SetStateAction } from "react";

import { WithLoader } from "@app/components/Loader";
import { NewsModel } from "./NewsItemPage/NewsModel";
import { Title } from '@app/components/PageLayout';
import { getDate, scrollToPosition } from '@app/utils';
import Pagination from '@app/components/Pagination';
import Select from '@app/components/Select';
import NewsCard from '@app/components/NewsCard';
import NewsGrid from './NewsGrid';
import PaginationWrapper from './PaginationWrapper';
import LocalContainer from './LocalContainer';
import EmptyDataPlaceholder from "@app/components/EmptyDataPlacehodler";
import { options } from './NewsPage';

interface Props {
  data: {
    count: number,
    results: NewsModel[],
  };
  setUrl: SetStateAction<ComponentState>;
  pageNum: number;
  setPageNum: (page: number) => void;
  sortBy: string;
  setSortBy: (value: string) => void;
}

const NewsWidgetView = ({ data, setUrl, pageNum, setPageNum, sortBy, setSortBy }: Props) => {

  const handleOrdering = (selected: string, page: number = pageNum) => {
    setSortBy(selected);
    setPageNum(page);
    selected === options[1] ? setUrl(`news/?page=${page}&ordering=-views`) : setUrl(`news/?page=${page}&ordering=-date`);
    scrollToPosition('wrapper')
  };

  return (
    <LocalContainer padding="135px 0 120px 0" paddingMobile="60px 0" paddingTablet="70px 60px 120px 0">
      <Title margin="0 0 60px 0" marginMobile="0 20px 40px 0">Новости компании</Title>
      <Select
        selected={sortBy}
        placeholder={sortBy}
        options={options}
        onSelect={(selected) => { if (selected !== sortBy) handleOrdering(selected, 1) }}
        widthTablet="330px"
      />
      <NewsGrid paddingTablet="40px 0 60px 0" paddingMobile="10px 0 60px 0">
        {data.results && data.results.map((newsItem: any) =>
          <NewsCard
            key={newsItem.id}
            id={newsItem.id}
            newsImage={newsItem.image}
            title={newsItem.name}
            date={getDate(newsItem.date, "dayYear")}
            views={newsItem.views}
          />)
        }
      </NewsGrid>
      {data.count > 0 ?
        <PaginationWrapper widthTablet="100%">
          <Pagination
            totalPages={Math.ceil(data.count / 10)}
            step={1}
            currentPage={pageNum}
            onChange={(item) => handleOrdering(sortBy, item)}
          />
        </PaginationWrapper>
        : <EmptyDataPlaceholder children={"Список новостей пуст."} />}
    </LocalContainer>
  )
};

export default WithLoader(NewsWidgetView);