import React, { useState } from 'react';
import * as LanguageElement from './Style';
import Button from '../../components/Button';
import ModalWindow from '../../components/ModalWindow';
import LanguageForm from './LanguageForm';

type LanguageProps = {
  data: {
    answers: string[],
    name: string,
  },
};

const Language = React.forwardRef<HTMLDivElement, LanguageProps>(({data}, ref) => {
  const [showModal, setShowModal] = useState({ show: false, url: '', type: '' });

  return (
  <LanguageElement.Wrapper id='language' ref={ref}>
    <LanguageElement.ContentWrapper>
      <h1 className='language-description__title-mini'>
        Прокачай английский для IT
      </h1>
      <LanguageElement.LanguageImage />
      <LanguageElement.LanguageDescription>
        <h1 className='language-description__title'>
          Прокачай английский для IT
        </h1>
        <p className='language-description__text event-paragraph__middle'>
          Знание английского расширяет профессиональные возможности и повышает зарплату в IT.
          На нашей онлайн-встрече ты узнаешь, как лучше вести митинги и переписываться с заказчиком.
        </p>
        <div className='language-description__text event-paragraph__middle'>
          Регистрируйся на один или два воркшопа на темы:<br />
          <ul>
            <li className='event-paragraph__middle'>Cultural shock: why and how to be polite?</li>
            <li className='event-paragraph__middle'>Нacks on business communication </li>
          </ul>
        </div>
        <p className='language-description__text event-paragraph__middle'>
          Язык встречи: английский.
        </p>
        <p className='language-description__text event-paragraph__middle'>
          Продолжительность встречи: около 40 минут.
        </p>
        <Button
          type='button'
          text='Присоединиться'
          onClick={() => setShowModal({show: true, url: '', type: ''})}
        />
      </LanguageElement.LanguageDescription>
    </LanguageElement.ContentWrapper>

    {showModal.show && <ModalWindow setModal={setShowModal}><LanguageForm setModal={setShowModal} data={data} /></ModalWindow>}
  </LanguageElement.Wrapper>
  );
});

export default Language;
