import styled from 'styled-components';

interface Props {
  color?: string;
}

const BreadcrumbItem = styled.div<Props>`
  margin-right: 20px;
  font-size: 12px;
  line-height: 2.17;
  color: ${props => props.color};
`;

export default BreadcrumbItem;
