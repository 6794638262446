import React from 'react';
import { AboutListTabWrapper, AboutListTabTitle } from './Style';
import tabImageTech from './assets/jpointTabTech.jpg';
import { TLandingType } from '../../types';

interface IAboutListTab {
  data: {
    title: string,
    list: string[],
  }[];
  type: TLandingType;
};

const AboutListTab = ({ data, type }: IAboutListTab) => {
  return (
    <AboutListTabWrapper className={`about-list-tab-${type}`}>
      {type === 'JPoint' && (
        <div className='tech-image'>
          <img src={tabImageTech} alt="tech"/>
        </div>
      )}

      {data.map((item) => (
        <div className='list-element__wrapper' key={item.title}>
          <AboutListTabTitle
            className='event-paragraph__large list-title'
            title={item.title}
          >
            {item.title}
          </AboutListTabTitle>
          
          <ul className='tab-list__main'>
            {item.list.map((listItem) => (
              <li key={listItem} className='event-paragraph__middle list-element'>
                {listItem}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </AboutListTabWrapper>
  );
};

export default AboutListTab;
