import React, { useState } from 'react';
import { Wrapper } from './Style';
import Checkbox from '@app/components/Checkbox';
import ReactHtmlParser from 'react-html-parser';
import { parseText } from '@app/utils';

interface ISelector {
  question: string;
  answers: string[];
  selected: string[];
  width?: string;
  setSelected: (value: string[]) => void;
  theme?: 'white' | 'black';
  dataFailure?: boolean;
  type?: 'checkbox' | 'radio';
  option?: string | null;
};

const Selector = ({ question, answers, selected, width, setSelected, theme = 'black', dataFailure, type = 'radio', option = null }: ISelector) => {
  const [show, setShow] = useState(false);

  return (
    <Wrapper
      width={width}
      selected={selected}
      show={show}
      selectorTheme={theme}
      data-failure={dataFailure}
      className='event-selector'
    >
      <div className='selector__label'>
        <div className='selector__placeholder'>
          {question}
        </div>
        <input
          className='selector__answer'
          type="text"
          placeholder={question}
          value={
            option
              ? (selected.length === answers.length) ? option : parseText(selected.join(', '))
              : parseText(selected.join(', '))
          }
          readOnly
          onClick={() => setShow(!show)}
        />
        <div
          className='selector__answer-drop'
          onClick={e => {
            e.stopPropagation();
            setShow(!show);
          }}
        >
        </div>
      </div>
      <div
        className='selector__answers'
        onClick={e => e.stopPropagation()}
        onMouseLeave={() => show && setShow(false)}
      >
        {answers && answers.length && answers.map((answer) => (
          <div
            onClick={() => {
              if (type === 'checkbox') {
                const copySelected = selected.slice();
                const indexEl = copySelected.indexOf(answer);

                if (indexEl >= 0) {
                  copySelected.splice(indexEl, 1);
                } else {
                  copySelected.push(answer);
                }

                setSelected(copySelected);
              } else {
                setSelected([answer]);
                setShow(false);
              }
            }}
          >
            <Checkbox
              isChecked={selected.includes(answer)}
              label=''
              onClick={() => ''}
            />
            {ReactHtmlParser(answer)}
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default Selector;
