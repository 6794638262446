import styled from 'styled-components';
import Button from './Button';

const BlueButton = styled(Button)`
  color: ${props => props.theme.color.black};
  background-color: ${props => props.theme.color.white};
  font-weight: 500;

  :hover{
    color: ${props => props.theme.color.black};
    background-color: ${props => props.theme.color.whiteButtonHover};
  }

  :disabled {
    color: ${props => props.theme.color.searchGrey};
    background-color: ${props => props.theme.color.breadCrumbsText};
  }
`;

export default BlueButton;
