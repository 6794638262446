import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  alignCenter?: boolean;
}

const TextContainer = styled.div<Props>`
  line-height: 1.63;
  text-align: ${p => p.alignCenter ? "center" : "left"};
  color: ${props => props.theme.color.textLight};
  margin: ${p => p.alignCenter ? "40px 0 40px 0" : "30px 0 60px 0"};

  ${StyleMediaMax.mobileM} {
    text-align: center;
  }
`;

export default TextContainer;