import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Style= styled.section`
  & {
    background-color: #E1E8ED;;
    color: #000000;
    padding: 180px 0 40px;
    box-sizing: border-box;
    position: relative;
  }

  .wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }

  & h2 {
    font-family: Gerbera Medium, sans-serif;
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;
    color: #111111;
	  margin-bottom: 20px;
    text-transform: uppercase;
  }

  & p {
    font-family: Gerbera Regular, sans-serif;
    font-size: 32px;
    line-height: 124%;
    color: #111111;
    letter-spacing: -0.04em;
  }

  ${StyleMediaMax.tabletS} {
    & {
      padding: 160px 20px 60px;
      box-sizing: border-box;
    }
  }
  
  ${StyleMediaMax.mobileL} {
    & {
      padding: 100px 20px 32px;
    }

    & h2 {
      font-size: 16px;
    }

    & p {
      font-size: 24px;
    }
  }
  ${StyleMediaMax.mobileL} {
    & {
      padding: 100px 20px 32px;
    }
  }
`;

export default Style;