import styles from 'styled-components';

const Wrapper = styles.div`
  width: 100%;
  padding: 160px 0;

  @media (max-width: 768px) {
    padding: 120px 0;
  }
`;

const ContentWrapper = styles.div`
  max-width: 1128px;
  margin: 0 auto;

  @media (max-width: 1128px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const Title = styles.h1`
  margin-bottom: 65px;

  br {
    display: none;
  }

  @media (max-width: 800px) {
    br {
      display: block;
    }
  }

  @media (max-width: 768px) {
    margin: 0 0 52px 0;
  }

  @media (max-width: 600px) {
    margin: 0 0 52px 0;
  }

  @media (max-width: 420px) {
    margin: 0 0 32px 0;
  }
`;

const BonusesWrapper = styles.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div:nth-child(1) {
    margin-bottom: 50px;
  }

  @media (max-width: 900px) {
    & > div:nth-child(1), & > div:nth-child(2), & > div:nth-child(4), & > div:nth-child(5) {
      padding-right: 15px;
    }
  }

  @media (max-width: 768px) {
    & > div:nth-child(3), & > div:nth-child(6) {
      padding-right: 15px;
    }

    & > div:nth-child(1), & > div:nth-child(2), & > div:nth-child(3), & > div:nth-child(4) {
      margin-bottom: 45px;
    }
  }

  @media (max-width: 500px) {
    & > div {
      padding-right: 0 !important;
    }
  }

  @media (max-width: 420px) {
    padding: 0;

    & > div {
      margin-bottom: 32px !important;
    }

    & > div:last-child {
      margin-bottom: 0 !important;
    }
  }
`;

const BonusesEl = styles.div`
  width: 33%;

  .bonus__image {
    margin-bottom: 25px;

    @media (max-width: 500px) {
      margin-bottom: 12px;

      img {
        height: 56px;
        width: 56px;
      }
    }
  }

  .bonus__title {
    font-weight: 500;

    @media (max-width: 600px) {
      max-width: 200px;
      font-size: 20px;
    }

    @media (max-width: 450px) {
      max-width: 155px;
      font-family: Gerbera Light;
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 420px) {
    width: 100%;
    display: flex;

    .bonus__image {
      margin-right: 24px;
      margin-bottom: 0;
    }

    .bonus__title {
      max-width: max-content;
    }
  }
`;

export { Wrapper, ContentWrapper, Title, BonusesWrapper, BonusesEl };
