import styles from 'styled-components';
import btnClose from './assets/menu-close.svg';
import btnOpen from './assets/menu-open.svg';
import blackLogoMobile from './assets/logo-black-mobile.svg';
import whiteLogoMobile from './assets/logo-white-mobile.svg';
import blackLogoTablet from './assets/logo-black-tablet.svg';
import whiteLogoTablet from './assets/logo-white-tablet.svg';
import { TLandingType } from '../../types';

interface IWrapper {
  background: string;
  type: TLandingType;
};

const Wrapper = styles.div<IWrapper>`
  width: 100%;
  position: relative;
  background-image: url('${props => props.background}');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;

  ${props => {
    if (props.type === 'Heisenbug') {
      return `background-position-x: 75%;`;
    } else if (props.type === 'JPoint') {
      return `background-position-x: 50%;`;
    }
  }}

  @media (max-width: 1128px) {
    padding: 0 24px !important;
  }

  @media (max-width: 768px) {
    ${props => {
      if (props.type === 'Heisenbug') {
        return `background-position-x: 75%;`;
      } else {
        return `background-position-x: 55%;`;
      }
    }}
  }

  @media (max-width: 420px) {
    ${props => {
      if (props.type === 'Heisenbug') {
        return `background-position-x: 75%;`;
      } else {
        return `background-position-x: 60%;`;
      }
    }}
  }
`;

const Navigation = styles.div`
  position: relative;
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 960px) {
    position: static;
  }

  & > a {
    position: absolute;
    left: 72px;

    @media (max-width: 1650px) {
      display: none;
    }
  }
`;

interface INavigationLinks {
  type: TLandingType;
};

const NavigationLinks = styles.div<INavigationLinks>`
  max-width: 1128px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  color: white;

  @media (max-width: 960px) {
    display: none;
  }

  .event-paragraph__middle {
    font-family: Gerbera Regular;
  }

  & > p:nth-child(2), & > p:nth-child(3), & > p:nth-child(4) {
    margin-right: 32px;

    @media (max-width: 1000px) {
      margin-right: 16px;
    }
  }

  & > p:last-of-type {
    margin-right: auto;
  }

  & * {
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &:hover {
      border-color: ${props => props.type === 'JPoint' ? '#7B61FF' : '#27C3F3'};
    }
  }

  & > a:first-of-type {
    display: none;
    margin-right: 32px;

    @media (max-width: 1650px) {
      display: block;
    }
  }
`;

const DescriptionWrapper = styles.div`
  max-width: 1128px;
  width: 100%;
  display: flex;
  margin: 0 auto;

  & > div {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 1128px) {
    padding: 30px 0 30px;
  }

  @media (max-width: 768px) {
    padding: 160px 0 284px;
  }

  @media (max-width: 420px) {
    padding: 0;
  }
`;

interface ITitle {
  type: TLandingType;
};

const Title = styles.h1<ITitle>`
  max-width: 740px;
  margin-bottom: 24px;
  color: #FFFFFF;
  font-size: 64px;
  line-height: 117%;

  br:first-of-type {
    display: none;
  }

  @media (max-width: 768px) {
    max-width: 600px;

    ${props => props.type === 'Heisenbug' && `
      br:first-of-type {
        display: block;
      }
    `}
  }

  @media (max-width: 660px) {
    br:first-of-type {
      display: block;
    }
  }

  @media (max-width: 500px) {
    max-width: 315px;
    font-size: 32px;
    line-height: 36px;
  }
`;

const Description = styles.div`

`;

const DescriptionElement = styles.p`
  max-width: 550px;
  color: #FFFFFF;

  @media (max-width: 420px) {
    max-width: 270px;
  }
`;

interface IMobileNavigationWrapper {
  show: boolean;
};

const MobileNavigationWrapper = styles.div<IMobileNavigationWrapper>`
  width: 100%;
  display: none;

  .mobile-navigation {
    width: 100%;
    display: flex;
    align-items: center;

    & > a:first-child {
      z-index: 1;
      width: 100%;
      margin-right: auto;
      background-repeat: no-repeat !important;

      @media (max-width: 960px) {
        max-width: 200px;
        background: url(${whiteLogoTablet});

        ${props => props.show && `
          background: url(${blackLogoTablet});
        `}
      }

      @media (max-width: 420px) {
        max-width: 150px;
        background: url(${whiteLogoMobile});

        ${props => props.show && `
          background: url(${blackLogoMobile});
        `}
      }
    }

    .btn-menu {
      z-index: 1;
      height: 26px;
      width: 26px;
      background-repeat: no-repeat;
      cursor: pointer;

      &[data-show="false"] {
        background-image: url(${btnOpen});
      }

      &[data-show="true"] {
        background-image: url(${btnClose});
      }
    }
  }

  .mobile-navigation-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 100vh;
    height: 100%;
    background: white;

    .menu-navigation__wrapper {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;

      .menu-navigation {
        margin-top: 31%;
        margin-left: 24px;
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        align-items: center;

        @media (max-width: 420px) {
          margin-top: 13%;
        }

        .navigation-list {
          & > div {
            font-size: 32px;
            line-height: 40px;

            @media (max-width: 420px) {
              font-size: 16px;
              line-height: 24px;
            }
          }

          div:not(last-child) {
            margin-bottom: 64px;

            @media (max-width: 420px) {
              margin-bottom: 32px;
            }
          }
        }
      }

      .menu-right {
        width: 100%;
        margin-bottom: 48px;
        display: flex;
        justify-content: center;
        opacity: .6;

        @media (max-width: 420px) {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 960px) {
    display: block;
  }
`;

export { Wrapper, Navigation, DescriptionWrapper, Title, Description, DescriptionElement, NavigationLinks, MobileNavigationWrapper };
