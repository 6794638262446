import styles from 'styled-components';

const ButtonWrapper = styles.button`
  border: 2px solid #27C3F3;
  background: transparent;
  color: #27C3F3;
  cursor: pointer;
  outline: none;
`;

const ButtonTextWrapper = styles.div`
  position: relative;
  z-index: 1;
  padding: 20px 83px;
  display: flex;
  justify-content: center;
  transition: all .3s ease;
  font-size: 20px;
  line-height: 24px;
  font-family: Gerbera Regular;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background: #27C3F3;
    transition: all .3s ease;
  }

  &:hover {
    color: black;

    &:before {
      width: 100%;
    }
  }
`;

export { ButtonWrapper, ButtonTextWrapper };
