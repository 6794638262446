import styled from 'styled-components';

interface Props {
  src?: string;
  position?: string;
}

const MobileButton = styled.button<Props>`
  display: block;
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  object-fit: contain;
  background: ${props => props.src ? `url(${props.src}) no-repeat` : undefined};
  background-position: ${props => props.position};
  :disabled {
    cursor: no-drop;
  }

  &:hover {
    text-decoration: none;
  }
`;

export default MobileButton;