import React, { useEffect, useState } from 'react';
import Footer from '@app/components/Footer';
import Header from '@app/components/Header';
import HeaderMobile from '@app/components/HeaderMobile';
import HeaderMobileAboutUs from '@app/components/HeaderMobileAboutUs';
import Content from '@app/components/Content';
import Wrapper from './Wrapper';
import LandingContext from "./LandingContext";
import HeaderLanding from "@app/components/HeaderLanding";
import HeaderAboutUs from "@app/components/HeaderAboutUs";
import Axios from 'axios';

const Builder = () => {
  const [isLanding, setIsLanding] = React.useState('');
  const [data, setData] = useState<any>({btns: {}, scroll_to: {}});

  useEffect(() => {
    Promise.all([
      Axios.get('/fe-api/landing/btns'),
      Axios.get('/fe-api/landing/scroll-to-section')
    ])
      .then((res: any) => {
        setData({
          btns: res[0].data[0] || {},
          scroll_to: res[1].data[0] || {},
        });
      })
  }, []);

  return (
  <LandingContext.Provider value={{isLanding, setIsLanding}}>
    <Wrapper id="wrapper">
      {isLanding
        ? isLanding === 'landing'
          ? (
            <HeaderLanding
              btn_send_text={data.btns.btn_send_text || 'Отправить'}
              btn_recommend_text={data.btns.btn_recommend_text || 'Рекомендовать'}
              email={data.btns.btn_recommend_email || ''}
              body={data.btns.btn_recommend_body || 'Тело'}
              subject={data.btns.btn_recommend_subject || 'Тема'}
              alert_text={data.btns.btn_recommend_alert_text || 'Предупреждение'}
              scroll={data.scroll_to.section || 'form'}
            />
          )
          : isLanding === 'AboutUs'
            ? <HeaderAboutUs />
            : null
        : <Header />
      }
      {isLanding 
        ? isLanding === 'AboutUs'
          ? <HeaderMobileAboutUs />
          : null
        : <HeaderMobile />}
      {isLanding === 'AboutUs'
        ? <Content page={isLanding} />
        : <Content />
      }
      <Footer />
    </Wrapper>
  </LandingContext.Provider>
)};

export default Builder;
