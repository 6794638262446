import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  color?: string;
  borderColor?: string;
  isDev?: boolean;
  marginBottom?: string;
  fontWeight?: string;
  paddingTablet?: string;
  paddingMobile?: string;
}

const Paragraph = styled.div<Props>`
  font-size: ${props => props.isDev? '24px':'16px'};
  font-weight: ${props => props.fontWeight?  props.fontWeight : undefined};
  font-stretch: normal;
  font-style: normal;
  line-height: ${props => props.isDev? '1.42': '1.63'};
  padding-left: 30px;
  margin-left: -1px;
  color: ${props => props.color ? props.color: undefined};
  border-left: ${props => props.borderColor ? `2px solid ${props.borderColor}` : `2px solid ${props.theme.color.black}`};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom: "30px"};
  ${StyleMediaMax.tablet} {
    max-width: calc(100% - 60px);

    br {
      display: none;
    }
  }
  ${StyleMediaMax.tablet} {
    margin-bottom: 0;
    padding: ${props => props.paddingTablet ? props.paddingTablet: undefined};
  }

  ${StyleMediaMax.mobileM} {
    padding-right: 0;
    padding: ${props => props.paddingMobile ? props.paddingMobile: undefined};
    font-size: 16px;
    line-height: 1.63;
    max-width: calc(100% - 20px);
    margin-bottom: 0;

    a {
      font-size: inherit;
      line-height: inherit;
    }
  }
`;

export default Paragraph;