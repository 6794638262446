import React, { useState } from 'react';
import { IFAQ } from '../../types';
import {Style, Arrow} from './Style';
import questionImage from './assets/question.png';
import ReactHtmlParser from 'react-html-parser';

const FAQ = ({ faq }: { faq: IFAQ }) => {
  const [mapOfOpen, setMapOfOpen] = useState(Array(faq.length || 0).fill(false));

  const opened = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const name = evt.currentTarget.name;
    const newName = Number(name)

    let newMapOfOpen;
    if (!mapOfOpen[newName]) {
      newMapOfOpen = Array(6).fill(false)
    } else {
      newMapOfOpen = mapOfOpen.slice();
    }

    const previous = newMapOfOpen[newName]
    newMapOfOpen[newName] = !previous;
    setMapOfOpen(newMapOfOpen)
  }

	return (
		<Style id='section_faq'>
      <h2 className='faq-title'>FAQ</h2>

      <p className='faq-description'>
        Часто задаваемые вопросы
      </p>

      <ul className='question-list'>
        {faq.map((el, i) => (
          <li className='question-element' key={el.question}>
            <div className='question-image'>
              <img src={questionImage} alt="question"/>
            </div>

            <div className='answer-description'>
              <h4 className='answer-description__title'>{el.question}</h4>

              <Arrow isOpen={mapOfOpen[i]}>
                <button onClick={(evt) => opened(evt)} name={String(i)}></button>
              </Arrow>

              {mapOfOpen[i] && (
                <p className='answer-description__paragraph'>
                  {ReactHtmlParser(el.answer) || ''}
                </p>
              )}
            </div>
          </li>
        ))}
      </ul>
    </Style>
	);
};

export default FAQ;
