import styled from 'styled-components';
import award from '@assets/icons/award.svg';

const AwardIcon = styled.div`
  height: 70px;
  width: 70px;
  margin-right: 25px;
  border-radius: 60px;
  background: url(${award}) no-repeat center center;
  background-color: ${props => props.theme.color.transparent};
`;

export default AwardIcon;