import styled from 'styled-components';
import arrowDown from '@assets/icons/arrow_down.svg';

interface Props {
  isExpanded?: boolean;
  backgroundColor?: string;
  isHidden?: boolean;
}

const Arrow = styled.span<Props>`
  display: ${props => props.isHidden ?  'none': undefined};
  mask-image: url(${arrowDown});
  width: 14px;
  height: 16px;
  position: relative;
  top: 19px;
  right: 29px;
  transform: ${props => props.isExpanded ? 'rotate(180deg)' : undefined};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : props.theme.color.white};
`;

export default Arrow;