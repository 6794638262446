import styled from 'styled-components';
import { StyleMediaMax, StyleMediaMin } from '@app/components/StyleMedia';

const RadioWrapper = styled.div`
  display: flex;
  margin-top: 37px;
  align-items: center;
  justify-content: space-between;
  line-height: 1.63;

  ${StyleMediaMax.tablet} {
    display: grid;
    grid-template-areas: "div div";

    >:first-child {
      grid-area: div;
    }

    ${StyleMediaMin.mobileM} {
      grid-template-columns: 1fr 1fr 1fr 1fr;

      >:not(:first-child) {
        margin: auto;
      }
    }
  }

  ${StyleMediaMax.mobileM} {
    grid-template-columns: 1fr 1fr;

    >:first-child {
      margin-bottom: 10px;
    }
  }
`;

export default RadioWrapper