import React from 'react';
import ContactsContainer from './ContactsContainer';
import ContactsWrapper from './ContactsWrapper';
import MailText from './MailText';
import SectionTitle from '../SectionTitle';

const Contacts = () => (
  <ContactsWrapper>
    <SectionTitle margin="0 0 60px 0">Contacts</SectionTitle>
    <ContactsContainer lineHeight="3.31">
      Mera NN (An Orion Innovation company) <br />
      603163, 13 Delovaya Street, Nizhny Novgorod, Russia <br />
      <a href="https://corp.orioninc.ru/">corp.orioninc.ru</a><br />
      Email: <MailText href="mailto:info@orioninc.com">info@orioninc.com</MailText>
    </ContactsContainer>
    <SectionTitle margin="60px 0 30px 0">HR Department (Russia)</SectionTitle>
    <ContactsContainer margin="30px 0" lineHeight="2.25">
      <a href="https://career.orioninc.ru/">career.orioninc.ru</a><br />
      Email: <MailText href="mailto:jobs.rus@orioninc.com">jobs.rus@orioninc.com</MailText>
    </ContactsContainer>
  </ContactsWrapper>
);

export default Contacts;
