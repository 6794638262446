export const bonuses = [
  {
    title: 'Гибкий график<br />и возможность<br />работать из дома',
    id: 0,
  },
  {
    title: 'Поддержка релокации<br />в Нижний Новгород<br />и Казань',
    id: 1,
  },
  {
    title: 'Обучение и сертификация<br />за счет компании ',
    id: 2,
  },
  {
    title: 'Корпоративные курсы<br />английского языка',
    id: 3,
  },
  {
    title: 'Спорт и медицинская<br />страховка',
    id: 4,
  },
  {
    title: 'Бонусы за рекомендацию<br />друзей ',
    id: 5,
  },
];
