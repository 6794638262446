import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import ParagraphTitle from './ParagraphTitle';

const ParagraphTitleMobile = styled(ParagraphTitle)`
  display: none;

  ${StyleMediaMax.mobileM} {
    display: block;
  }
`;

export default ParagraphTitleMobile;