import styled from 'styled-components';
import LinkButton from './LinkButton';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  hideonmobile?: string;
}

const WhiteLinkButton = styled(LinkButton)<Props>`
  color: ${props => props.theme.color.black};
  background-color: ${props => props.theme.color.white};

  :hover{
    color: ${props => props.theme.color.black};
    background-color: ${props => props.theme.color.white};
  }

  ${StyleMediaMax.mobileM} {
    display: ${props => props.hideonmobile ? props.hideonmobile : undefined};
  }
`;

export default WhiteLinkButton;