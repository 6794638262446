import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface Props {
  color?: string;
}

const BreadcrumbLink = styled(NavLink)<Props>`
  margin-right: 20px;
  font-size: 12px;
  line-height: 2.17;
  color: ${props => props.color};
`;

export default BreadcrumbLink;
