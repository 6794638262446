import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import CopyrightContainer from './CopyrightContainer';

const year = new Date().getFullYear();

const Copyright = () => (
  <CopyrightContainer>
    &copy;{ReactHtmlParser(` ${year}. <br/>Все права защищены.`)}
  </CopyrightContainer>
);

export default Copyright;