import styled from 'styled-components';
import checkmark from '@assets/icons/checkmark_blue.svg';

interface Props {
  selected: boolean;
}

const OptionContainer = styled.div<Props>`
  white-space: nowrap;
  padding-left: 30px;
  margin-bottom: 20px;
  cursor: pointer;
  color: ${props => props.selected ? props.theme.color.primaryColor : undefined};
  background-image: ${props => props.selected ? `url(${checkmark})` : undefined};
  background-repeat: no-repeat;
  background-position: left center;

  &:last-child{
    margin-bottom: 0px;
  }
`;

export default OptionContainer;