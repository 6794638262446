import styled from 'styled-components';
import Button from './Button';

const GrayButton = styled(Button)`
  color: ${props => props.theme.color.primaryText};
  background-color: ${props => props.theme.color.borderLight};

  :disabled {
    color: ${props => props.theme.color.searchGrey};
    background-color: ${props => props.theme.color.breadCrumbsText};
  }
`;

export default GrayButton;