import React, { useState } from 'react';
import { LanguageFormWrapper } from './Style';
import Form from '../../components/Form';
import Input from '../../components/Input';
import Selector from '../../components/Selector';
import { parseText } from '@app/utils';

interface ILanguageForm {
  setModal: ({show, url, type}: { show: boolean, url: string, type: string }) => void;
  data: {
    answers: string[];
    name: string;
  };
};

const TeamForm = ({ setModal, data }: ILanguageForm) => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [selected, setSelected] = useState<string[]>([]);
  const [validate, setValidate] = useState<string[]>([]);

  const submit = () => {
    if (validate.length) {
      setValidate([]);
    }

    const fields = [
      {dataName: 'name', value: name},
      {dataName: 'surname', value: surname},
      {dataName: 'email', value: email},
      {dataName: 'selected', value: selected}
    ];

    const tempValidate: string[] = [];
    
    fields.forEach((field) => {
      if (field.dataName === 'email' && (!field.value.includes('@') || !field.value)) {
        tempValidate.push(field.dataName);
      } else {
        if (!field.value) {
          tempValidate.push(field.dataName);
        }
      }
    });

    setValidate(tempValidate);

    if (!tempValidate.length) {
      setModal({show: false, url: '', type: ''});
      const body = `Имя: ${name} Фамилия: ${surname} Email: ${email} Время: ${selected}`;
      const mailToLink = `mailto:event.rus@orioninc.ru?subject=${data.name}, регистрация на английский&body=${parseText(body)}`;
      window.location.assign(mailToLink);
    }
  };

  return (
    <LanguageFormWrapper onClick={e => e.stopPropagation()}>
      <Form
        titleForm='Заполни форму'
        padding='48px 96px'
        submit={submit}
        formTheme='white'
        setModal={setModal}
      >
        <Input
          required={true}
          width='48%'
          placeholder='Имя'
          type='text'
          value={name}
          setValue={setName}
          dataFailure={validate.includes('name')}
        />

        <Input
          required={true}
          width='48%'
          placeholder='Фамилия'
          type='text'
          value={surname}
          setValue={setSurname}
          dataFailure={validate.includes('surname')}
        />

        <Input
          required={true}
          width='48%'
          placeholder='Email'
          type='text'
          value={email}
          setValue={setEmail}
          dataFailure={validate.includes('email')}
        />

        <Selector
          width='48%'
          question='Время встречи в Zoom'
          answers={data.answers}
          selected={selected}
          setSelected={setSelected}
          theme='white'
          dataFailure={validate.includes('selected')}
          type='checkbox'
          option='Both workshops'
        />
      </Form>
    </LanguageFormWrapper>
  );
};

export default TeamForm;
