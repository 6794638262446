import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Wrapper = styled.div`

`;

const ContentWrapper = styled.div`

`;

interface ISlideElement {
    type: 'left' | 'right';
};

const SlideElement = styled.div<ISlideElement>`
    display: flex;
    flex-direction: row;
    position: relative;
    height: 800px;
    background: #E1E8ED;

    .left-element {
        position: absolute;
        height: 800px;
        width: 50%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        order: ${props => props.type === 'left' && '1'};

        img {
            width: auto;
            height: 100%;
            display: block;
        }
    }

    .right-element {
        max-width: 100%;
        width: 1200px;
        margin: auto;
        display: flex;
        align-items: center;
        background: #E1E8ED;

        .text-element {
            max-width: 50%;
            padding-left: ${props => props.type === 'left' ? '0px' : '60px'};
            padding-right: ${props => props.type === 'left' ? '60px' : '0'}

            & > p:first-child {
                margin-bottom: 20px;
                font-family: Gerbera Regular, sans-serif;
                font-weight: 400;
                font-size: 48px;
                line-height: 124%;
                letter-spacing: -0.04em;
                color: #111111;
            }

            .slide-description {
                font-family: Gerbera Light, sans-serif;
                font-size: 20px;
                line-height: 160%;
                letter-spacing: -0.04em;
                color: #333333;
            }
        }
    }

    ${StyleMediaMax.mobileL} {
        height: 540px;

        .left-element {
            height: 540px;
        }
        .right-element {
            margin-top: 46px;
    
            .text-element {
                
    
                & > p:first-child {
                    font-size: 32px;
                }
    
                .slide-description {
                    font-size: 16px;
                }
            }


        }
    }

    ${StyleMediaMax.mobile} {
        height: auto;
        &:last-child .text-element {
            padding-bottom: 60px;
        }

        .text-element {
            max-width: 100% !important;
            padding: 60px 20px !important;
            p {
                font-weight: bold;
            }
        }

        .right-element {
            margin: 0 !important;
        }

        .left-element {
            display: none;
        }

        &:nth-child(even) .right-element .text-element {
            margin-left: auto;
            padding: 0px 20px !important;
        }
    }

    &:nth-child(odd) .left-element {
        right: 0;
    }

    &:nth-child(even) .right-element .text-element {
        margin-left: auto;
    }

    @media (max-width: 1200px) {
        .right-element {
            .text-element {
                padding-left: ${props => props.type === 'left' ? '20px' : '60px'};
                padding-right: ${props => props.type === 'left' ? '60px' : '20px'}
            }
        }
    }
`;

export {Wrapper, ContentWrapper, SlideElement};
