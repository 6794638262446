import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Style = styled.section`
  & {
    padding: 100px 0 140px;
    box-sizing: border-box;
    background: #111111;
  }

  .section-contact-wrapper {
    max-width: 1200px;
    margin: 0 auto;

    .contact-title {
      margin-bottom: 20px;
      font-family: Gerbera Medium, sans-serif;
      font-size: 20px;
      line-height: 160%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #FFFFFF;
    }

    .foreword {
      margin-bottom: 80px;
      font-family: Gerbera Regular, sans-serif;
      font-size: 32px;
      line-height: 124%;
      letter-spacing: -0.04em;
      color: #FFFFFF;
    }

    .wrapper {
      display: flex;
      flex-wrap: wrap;

      .person {
        width: 50%;
        display: flex;

        .photo {
          width: 192px;
          height: 192px;
          border-radius: 50%;
        }

        .person_info {
          width: 100%;
          margin: 0 auto;
          padding-top: 50px;
          padding-left: 40px;

          .person_name {
            margin-bottom: 16px;
            font-family: Gerbera Light, sans-serif;
            font-size: 20px;
            line-height: 160%;
            letter-spacing: -0.04em;
            color: #FFFFFF;
          }

          .telegram {
            font-size: 20px;
            line-height: 160%;
            letter-spacing: -0.04em;
            color: #FFFFFF;

            img {
              margin-right: 16px;
              vertical-align: middle;
            }

            a {
              font-family: Gerbera Light, sans-serif;
              color: #FFFFFF;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  ${StyleMediaMax.mobileL} {
    & {
      padding: 80px 20px;
    }
  }

  ${StyleMediaMax.tabletS} {

    & .wrapper {
      box-sizing: border-box;
      
      .person {
        margin-top: 60px;
        flex-direction: column;

        .photo {
          width: 192px;
          height: auto;
          margin: 0;
        }

        .person_info {
          padding: 0;
          margin-top: 20px;
        }


        .telegram {
          font-size: 14px;
          line-height: 20px;
          height: 20px;

          img {
            height: 20px;
            width: 20px;
            margin-right: 10px;
          }


        }
      }
    }
  }
  
  ${StyleMediaMax.mobileL} {
    & h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 120%;
      width: 100%;
      margin-bottom: 40px;
    }

    & .foreword {
      font-size: 24px !important;
    }
    & .wrapper {
      box-sizing: border-box;
      flex-direction: column;
      

      .person {
        width: 100% !important;
        margin-top: 35px;
        flex-direction: row;

        .photo {
          width: 88px !important;
          height: 88px !important;
          margin: 0;
        }
        
        .person_info {
          padding: 0 24px !important;
          margin: 0px !important;
        }

        h5 {
          margin: 10px 0 0 !important;
        }

        .telegram {
          font-size: 14px;
          line-height: 20px;
          height: 20px;

          img {
            height: 20px;
            width: 20px;
            margin-right: 10px;            
          }

         
        }
      }
    }
  }

  & .wrapper .foreword a {
    font-size: 20px;
    line-height: 28px;
  }

  ${StyleMediaMax.tabletS} {
    & .wrapper .foreword a {
      font-size: 14px;
      line-height: 20px;
    }
  }

  ${StyleMediaMax.mobileL} {
    & .wrapper .foreword a {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export default Style;