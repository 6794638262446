import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import { BlueButton } from '@app/components/Buttons';

interface Props {
  width?: string;
  alignItems?: string;
}

const FormContainer = styled.form<Props>`
  width: ${props => props.width || undefined};
  display: flex;
  flex-direction: column;
  align-items: ${p => p.alignItems ? p.alignItems : undefined};

  ${StyleMediaMax.mobileM} {
    width:calc(100vw - 40px);

    ${BlueButton} {
      margin-top: 40px;
    }
  }
`;

export default FormContainer;