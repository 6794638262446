import React from 'react';

import facebook from '@assets/icons/social_media/facebook.svg';
import linkedin from '@assets/icons/social_media/linkedin.svg';
import vk from '@assets/icons/social_media/vk.svg';
import twitter from '@assets/icons/social_media/twitter.svg';

import Views from '@app/components/Views';
import NewsItemFooterWrapper from './NewsItemFooterWrapper';
import NewsShareContainer from './NewsShareContainer';
import FooterTitle from './FooterTitle';
import SocialMediaImg from './SocialMediaImg';

interface Props {
  views: number;
  newsTitle: string;
  logo: string;
}

const NewsItemFooter: React.FC<Props> = ({ views, newsTitle, logo }) => {
  const URL = window.location.href;

  return (
    <NewsItemFooterWrapper>
      <NewsShareContainer>
        <FooterTitle>Поделиться</FooterTitle>
        <SocialMediaImg src={vk} href={`http://vk.com/share.php?url=${URL}&title=${newsTitle}&image=${logo}`} />
        <SocialMediaImg src={facebook} href={`https://www.facebook.com/sharer/sharer.php?u=${URL}`} />
        <SocialMediaImg src={linkedin} href={`https://www.linkedin.com/shareArticle?mini=true&url=${URL}&title=${newsTitle}`} />
        <SocialMediaImg src={twitter} href={`https://twitter.com/intent/tweet?text=${newsTitle}%20${URL}`} />
      </NewsShareContainer>
      <Views views={views} />
    </NewsItemFooterWrapper>
  )
};

export default NewsItemFooter;