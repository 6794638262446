import React from 'react';
import Style from './Style';
import tg from './images/tg.svg';
import { IContacts } from '../../types';

const AndTalk = ({ contact }: { contact: IContacts }) => {
	return (
		<Style id='section_contact'>
			<div className='section-contact-wrapper'>
				<h2 className='contact-title'>Контакты</h2>

				<p className="foreword">
					Хочешь сначала узнать подробности? Не вопрос! <br />
					Наши рекрутеры с радостью все расскажут.
				</p>
				
				<div className="wrapper">
					{contact.map((el) => (
						<div className="person" key={el.full_name}>
							<img src={el.image} alt='person' className='photo'/>
							<div className="person_info">
								<h5 className='person_name'>{el.full_name}</h5>
								<p className='telegram'>
									<img src={tg} alt='telegram'/>
									<a href={el.link} target='_blank' rel='noopener noreferrer'>{el.text_link}</a>
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</Style>
	);
};

export default AndTalk;
