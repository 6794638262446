import styled from 'styled-components';
import { ExternalLink } from '@app/components/Links';

const FormLink = styled(ExternalLink)`
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
`;

export default FormLink;
