import React from 'react';

import LoadingElement from './LoadingElement';
import Button from './Button';

interface Props {
  width?: string,
  margin?: string,
  color?: string,
  icon: string,
}

const LoadingButton: React.FC<Props> = ({ width, margin, color, icon }) => (
  <Button width={width} margin={margin} color={color}>
    <LoadingElement icon={icon} />
  </Button>
);

export default LoadingButton;