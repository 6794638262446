import React from 'react';
import * as LuckElement from './Style';
import LuckForm from './LuckForm';
import stand from './assets/logo-stand.png';
import schedule from './assets/luck.jpg';
import calendar from './assets/calendar.svg';
import calendarBlue from './assets/calendarBlue.svg';
import { TLandingType } from '../../types';

interface ILuck {
  data: {
    answers: string[];
    name: string;
    times: string[];
  },
  type: TLandingType,
};

const Luck = React.forwardRef<HTMLDivElement, ILuck>(({ data, type }, ref) => {
  return (
    <LuckElement.Wrapper ref={ref} id='luck'>
      <LuckElement.ContentWrapper>
        <LuckElement.DescriptionWrapper>
          <LuckElement.DescriptionTextWrapper>
            <LuckElement.DescriptionTitle>
              Испытай удачу
            </LuckElement.DescriptionTitle>

            <LuckElement.DescriptionText>
              <LuckElement.DescriptionTextElement>
                В нашей компании есть традиция. Каждый год на весенней конференции мы дарим подарки.
                Для участников HolyJS мы подготовили новый мерч: от дженги до повербанка. Регистрируйся и проверь свое везенье!<br />
                Победителей определим с помощью рандомайзера и организуем курьерскую доставку по России.
              </LuckElement.DescriptionTextElement>
            </LuckElement.DescriptionText>
          </LuckElement.DescriptionTextWrapper>
          <LuckElement.DescriptionImage>
            <img src={schedule} alt="schedule"/>
          </LuckElement.DescriptionImage>
        </LuckElement.DescriptionWrapper>

        <LuckElement.ScheduleWrapper>
          <LuckElement.ScheduleImageWrapper>
            <img src={stand} alt="stand"/>
          </LuckElement.ScheduleImageWrapper>

          <LuckElement.ScheduleDescriptionWrapper>
            <div>
              Каждый день наш рекрутер ждет тебя на виртуальном стенде Orion Innovation. <br />
              Приходи пообщаться! 
            </div>

            {data.times.map((time) => (
              <p key={time}><img src={type === 'JPoint' ? calendar : calendarBlue} alt="time"/> {time}</p>
            ))}
          </LuckElement.ScheduleDescriptionWrapper>

          <LuckElement.ScheduleFooterWrapper>
            Оставляй заявку на призы и подключайся <br />к розыгрышу в Zoom {type === 'JPoint' ? '16 апреля в 14:00.' : '23 апреля в 16:00.'}
          </LuckElement.ScheduleFooterWrapper>
        </LuckElement.ScheduleWrapper>

        <LuckElement.FormWrapper>
            <LuckForm data={data} type={type} />
        </LuckElement.FormWrapper>
      </LuckElement.ContentWrapper>
    </LuckElement.Wrapper>
  );
});

export default Luck;
