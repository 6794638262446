import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const CopyrightContainer = styled.div`
  color: ${props => props.theme.color.white};
  font-size: 12px;
  line-height: 2.17;

  ${StyleMediaMax.tablet} {
    color: ${props => props.theme.color.primaryColorGrey};
  }

  ${StyleMediaMax.mobileM} {
    color: ${props => props.theme.color.primaryColorGrey};
    width: unset;
  }
`;

export default CopyrightContainer;
