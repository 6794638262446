import React, { useState, useEffect, useContext } from 'react';
import FAQ from './sections/FAQ';
import AcceptingStatements from './sections/AcceptingStatements';
import Begining from './sections/Begining';
import Steps from './sections/Steps';
import DescriptionOfProject from './sections/DescriptionOfProject';
import Demands from './sections/Demands';
import AndTalk from './sections/AndTalk';
import Style from './Style';
import LandingContext from "@app/components/Builder/LandingContext";
import NotFoundPage from "@app/components/pages/NotFoundPage";
import Axios from 'axios';
import LoadElement from '@app/components/Loader/LoadElement';
import { WhenContainer } from './sections/When/When';
import { ILandingData } from './types';
import { parseText } from '@app/utils/index';
import Slides from './sections/Slides';
import Poster from './sections/Poster';

const JavaHiring = () => {
  const { setIsLanding } = useContext(LandingContext);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ILandingData>();

  const getDataLanding = async () => {
    let arrPromise: any = [];
    let urls = [
      '/fe-api/landing/about-event',
      '/fe-api/landing/steps',
      '/fe-api/landing/about-project',
      '/fe-api/landing/technologies',
      '/fe-api/landing/candidate',
      '/fe-api/landing/faq',
      '/fe-api/landing/contact',
      '/fe-api/landing/btns',
      '/fe-api/landing/slider',
      '/fe-api/landing/poster',
      '/fe-api/landing/titles',
    ];

    urls.forEach((link) => {
      arrPromise.push(Axios.get(link));
    });

    Promise.all(arrPromise)
      .then((res: any) => {
        setData({
          aboutEvent: res[0].data[0] || {},
          steps: res[1].data[0] || [],
          aboutProject: res[2].data[0] || {},
          technologies: res[3].data || [],
          candidate: res[4].data || [],
          faq: res[5].data || [],
          contact: res[6].data || [],
          btns: res[7].data[0] || {},
          slider: res[8].data || [],
          poster: res[9].data[0] || {},
          titles: res[10].data[0] || {},
        });
      })
      .finally(() => setIsLoading(false))
  };

  useEffect(()=>{
      setIsLanding('landing');
      return () => setIsLanding('');
  }, [setIsLanding])

  useEffect(() => {
    getDataLanding();
  }, []);  

  return (
    <>
      {!isLoading
        ? data
          ? (
            <Style>
              <Begining
                date={data.aboutEvent.date || 'Дата'}
                for_level={data.aboutEvent.for_level}
                event_name={data.aboutEvent.event_name || 'Событие'}
                offer={data.aboutEvent.offer || 'Предложение...'}
                main_logo={data.aboutEvent.main_logo || ''}
                mobile_logo={data.aboutEvent.mobile_logo || ''}
                btn_send_text={data.btns.btn_send_text || 'Отправить'}
                btn_recommend_text={data.btns.btn_recommend_text || 'Рекомендовать'}
                email={data.btns.btn_recommend_email || ''}
                body={parseText(data.btns.btn_recommend_body) || 'Тело'}
                subject={data.btns.btn_recommend_subject || 'Тема'}
                alert_text={parseText(data.btns.btn_recommend_alert_text) || 'Предупреждение'}
              />
              <AcceptingStatements
                block_title={data.titles.accepting_statements_title || 'Как принять участие?'}
                main_description={parseText(data.steps.main_description) || ''}
              />
              <Steps
                steps={data.steps.part_step}
                btn_send={data.btns.btn_send_text || 'Отправить'}
                btn_recommend={data.btns.btn_recommend_text || 'Рекомендовать'}
                email={data.btns.btn_recommend_email || ''}
                body={parseText(data.btns.btn_recommend_body) || 'Тело'}
                subject={data.btns.btn_recommend_subject || 'Тема'}
                alert_text={parseText(data.btns.btn_recommend_alert_text) || 'Предупреждение'}
              />
              <DescriptionOfProject
                block_title={data.titles.about_project_title || 'О проекте'}
                project_logo={data.aboutProject.project_logo || ''}
                project_top_text={data.aboutProject.project_top_text || 'Первое описание проекта'}
                project_left_text={data.aboutProject.project_left_text || 'Второе описание проекта'}
                project_right_text={data.aboutProject.project_right_text || 'Третье описание проекта'}
                technologies={data.technologies}
              />
              <Demands
                block_title={data.titles.demands_title || 'Кого мы ищем'}
                candidates={data.candidate}
                btns={data.btns}
              />
              <Poster path={data.poster.poster_image || ''} />
              <WhenContainer block_title={data.titles.when_title || 'Отправляй заявку на Hiring Week!'} />
              <Slides elements={data.slider} />
              <FAQ faq={data.faq} />
              <AndTalk contact={data.contact} />
            </Style>
          )
          : (
            <NotFoundPage />
          )
        : (
          <LoadElement />
        )
      }
    </>
  )
};

export default JavaHiring;
