import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const DateContainer = styled.div`
  font-size: 12px;
  color: ${props => props.theme.color.textLight};
  margin-left: auto;
  text-align: right;
  min-width: 100px;

  ${StyleMediaMax.mobileM} {
    min-width: unset;
    margin-left: 0;
  }
`;

export default DateContainer;