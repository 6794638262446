import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  color?: string;
  margin?: string;
  display?: string;
  marginTablet?: string;
  marginMobile?: string;
}

const PageTitle = styled.div<Props>`
  font-size: 50px;
  font-weight: 600;
  line-height: 1.2;
  margin: ${props => props.margin ? props.margin : undefined};
  color: ${props => props.color ? props.color : props.theme.color.white};

  ${StyleMediaMax.tablet} {
    margin-bottom: 60px;
    margin: ${props => props.marginTablet ? props.marginTablet : undefined};
  }

  ${StyleMediaMax.mobileM} {
    font-size: 34px;
    margin-bottom: 30px;
    margin: ${props => props.marginMobile ? props.marginMobile : undefined};
    display: ${props => props.display ? props.display : undefined};
  }
`;

export default PageTitle;