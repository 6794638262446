import styled from 'styled-components';
import { StyleMediaMin, StyleMediaMax } from '@app/components/StyleMedia';

const Container = styled.div`
  height: 100%;
  width: 1770px;
  display: flex;
  margin: auto;
  align-items: center;

  ${StyleMediaMax.desktopS} {
    width: calc(100vw - 40px);
    ${StyleMediaMin.mobileM} {
      &> { margin: 0 20px 0 0; }
    }    
  }
`;

export default Container;