import React, {useContext, useEffect, useRef, useState, useImperativeHandle} from 'react';
import Style from './Style';
import {Options, IInputs} from '../../types';
import {api} from "@app/api/api";
import Axios from 'axios';
import TagManager from 'react-gtm-module';
import LandingBtn from '@app/components/HeaderLanding/LandingBtn';

const classLabelInput = 'form_label_input';
const classLabelCheckboxFlex = 'form_label_checkbox_flex';

let inputCheckbox = 'Согласие с условиями обработки моих персональных данных';

let hash = function(s : string) : string{
	let a = 1, c = 0, h, o;
	if (s) {
		a = 0;
		for (h = s.length - 1; h >= 0; h--) {
			o = s.charCodeAt(h);
			a = ((a<<6)&268435455) + o + (o<<14);
			c = a & 266338304;
			a = c!==0?a^c>>21:a;
		}
	}
	return `hash${String(a)}`;
};

interface ILandingContext {
	isLanding: boolean,
	setIsLanding: (type:boolean) => void,
}

const FormContext = React.createContext({} as any);


const ShowOptionsRadio = ({options}: Options) => {
	const { values, hash, setStateLabel, stateForm, setStateForm} = options;

	return (
		<div id={'options'}  >
			<div id={'options_1'}>
				{ values
					.map((value, i) => {
						return (
							<div key={value} className={classLabelCheckboxFlex} >
								<input name="rb" id={value} type="radio"
											 onChange={() => {
												 setStateLabel({ focus: false, value: value});
												 setStateForm({...stateForm, [hash]: { ...stateForm[hash], value: value }});
											 }}
								/>
								<label htmlFor={value} >{value}</label>
							</div>
						);
					})
				}

			</div>
		</div>
	)
};

const ShowOptionsCheckbox = ({options}: Options) => {
	const {values, hash, stateLabel, setStateLabel, stateForm, setStateForm} = options;

	const onChangeValue = (e: any, value: any) => {
		let currentValue;
		if (e.target.checked){
			currentValue = [...stateLabel.value, value];
			setStateLabel({ ...stateLabel, value: currentValue });
			setStateForm({...stateForm, [hash]: { ...stateForm[hash], value: currentValue }});
		} else {
			currentValue = [...stateLabel.value.filter( (v: []) => v !== value)]
			setStateLabel({ ...stateLabel, value: currentValue});
			setStateForm({...stateForm, [hash]: { ...stateForm[hash], value: currentValue }});
		}
		return void 0;
	}

	return (
		<div id={'options'}>
			<div id={'options_1'}>
				{ values
					.map((value, i) => {
						return (
							<div key={value} className={classLabelCheckboxFlex}>
								<input name="rb" id={value} type="checkbox" checked={stateLabel.value.includes(value) ? true : false}
											 onChange={(e) => {
												 onChangeValue(e, value);
											 }}
								/>
								<label htmlFor={value}>{value}</label>
							</div>
						);
					})
				}
			</div>
		</div>

	)
}


const InputFactory = React.forwardRef((props : any, ref: any) => {
	const {input} = props;
	const {hash, question, type, values} = input;
	const {stateForm, setStateForm} = useContext(FormContext);

	const [stateLabel, setStateLabel] = useState({focus: false, value: ''});
	const options = {type, values, hash, stateLabel, setStateLabel, stateForm, setStateForm};

	const setValueToStateForm = (e: any) => {
		setStateLabel({...stateLabel, value: e.target.value});
		setStateForm({...stateForm, [hash]: { ...stateForm[hash], value: [e.target.value] }});
	}

	useImperativeHandle(ref, () => ({
    resetLabel: () => {
      setStateLabel({focus: false, value: ''});
    }
  }));


	if (type === 'input'){
		return (
			<label className={classLabelInput} id={hash}>
				<span>{stateLabel.value || stateLabel.focus ? question : ''}</span>
				<input type="text" placeholder={question} ref={ref}
							 onChange={(e)=> setValueToStateForm(e)}
							 onFocus={(e)=> setStateLabel({...stateLabel, focus: true})}
							 onBlur={(e)=> setStateLabel({...stateLabel, focus: false})}
				/>
			</label>
		);
	}
	if (type === 'radio'){
		return (
			<label className={classLabelInput} id={hash} onMouseLeave={(e)=> setStateLabel({...stateLabel, focus: false})}>
				<span>{stateLabel.value || stateLabel.focus ? question : ''}</span>
				<input readOnly type="text" placeholder={question}  value={stateLabel.value}
							 onChange={(e)=> setValueToStateForm(e)}
							 onFocus={(e)=> setStateLabel({...stateLabel, focus: true})}
							 onClick={(e)=> setStateLabel({...stateLabel, focus: true})}
				/>
				<div className={'input_arrow'} onClick={(e)=> {
					e.preventDefault();
					setStateLabel({...stateLabel, focus: !stateLabel.focus})
				}}></div>
				{ stateLabel.focus ? <ShowOptionsRadio options={options}/> : null  }
			</label>
		);
	}
	if (type === 'checkbox'){
		return (
			<label className={classLabelInput} id={hash} onMouseLeave={(e)=> setStateLabel({...stateLabel, focus: false})}>
				<span>{ (stateLabel.value.length && stateLabel.value[0] !== '') || stateLabel.focus ? question : ''}</span>
				<input readOnly type="text" placeholder={question} value={stateLabel.value}
							 onChange={(e)=> setValueToStateForm(e)}
							 onFocus={(e)=> setStateLabel({...stateLabel, focus: true})}
							 onClick={(e)=> setStateLabel({...stateLabel, focus: true})}
				/>
				<div className={'input_arrow'} onClick={(e)=> {
					e.preventDefault();
					setStateLabel({...stateLabel, focus: !stateLabel.focus})
				}}></div>
				{ stateLabel.focus ? <ShowOptionsCheckbox options={options}/> : null }
			</label>
		);
	}
	return null;

})

export const WhenContainer = ({ block_title } : {block_title: string}) => {
	const [stateInputs, setStateInputs] = useState<IInputs[] | undefined>();
	const [stateForm, setStateForm] = useState();

	const setForm = (value: any) => setStateForm(value);

	const getData = () => {
		Axios.get('fe-api/landing/questions')
			.then((res) => {
				let tempData: any = [];

				res.data.forEach((el: any) => {
					let tempValues: any = [];
					el.answer.forEach((answerEl: any) => tempValues.push(answerEl.answer));
					tempData.push({
						question: `${el.question}${el.required ? '*' : ''}`,
						type: el.type,
						values: tempValues,
					});
				})

				tempData = tempData.map((input: any) => ({
					...input, hash: hash(input.question)
				}));

				const whenState = Object.assign(
					{
						[hash(inputCheckbox)] : {
							question: inputCheckbox,
							type: 'checkbox',
							value: 'false'
						}
					},
					...tempData
						.map((input: any) => ({
							[hash(input.question)]: {
								question: input.question,
								type: input.type,
								value: []
							}
						}))
				)

				setStateInputs(tempData);
				setStateForm(whenState);
			})
	};

	useEffect(() => {
		getData();
	}, [])

	return <When block_title={block_title} stateInputs={stateInputs} stateForm={stateForm} setStateForm={setForm} />
}

const When = ({ block_title, stateInputs, stateForm, setStateForm }: { block_title: string, stateInputs: IInputs[] | undefined, stateForm: any, setStateForm: any }) => {
	const [labelsRef, setLabelsRef] = useState<any>([]);
	const formRef = useRef<any>(null);

	const moveToForm = () => {
		const element = document.getElementById('section_form');
		if (element) {
			element.scrollIntoView()
		}
	}
	const sendForm = () => {
		const fields: string[] = [];

		let email = '';
		const message = Object.keys(stateForm)
			.map( key => {
				let question = stateForm[key].question;
				let value: any = stateForm[key].value;
				if (typeof value === 'string') value = [value];
				value = value.join(', ');
				if (question.includes('*')){
					if (value === ''){
						fields.push(question.slice(0,question.length-1));
					}
				}
				if (question === 'Email*') email = value;
				return `${question} : ${value}`
			})
			.join('\r\n');
		if (fields.length > 0){
			alert(`Заполните обязательные поля: ${fields.join(', ')}`);
		} else {
			const sendItem = {
				name : 'landing',
				email,
				phone: '89100000000',
				message,
				contactBy: 'landing'
			};

			api.post('feedback/hiring_response/', sendItem)
				.then(response => {
					const tagManagerArgs = {
						dataLayer: {
							event: 'java_hiring_form_is_sended'
						}
					};
					TagManager.dataLayer(tagManagerArgs);
					console.log('response', response);
					alert('Заявка отправлена!');

					formRef && formRef.current && formRef.current.reset();

					Object.keys(stateForm).forEach((key) => {
						if (stateForm[key].question === 'Согласие с условиями обработки моих персональных данных') {
							setStateForm((state: any) => ({...state, [key]: { ...state[key], value: 'false' }}));
						} else {
							setStateForm((state: any) => ({...state, [key]: { ...state[key], value: [] }}));
						}
					})
					
					labelsRef.forEach((refLabel: any) => {
						refLabel.current.resetLabel();
					})
				})
				.catch(reject => {
					alert('Произошла ошибка! Не верно указан email');
					console.log('reject', reject)
				})
		}
	}

	useEffect(() => {
		stateInputs && stateInputs.forEach(() => {
			const ref = React.createRef();
			setLabelsRef((state: any) => [...state, ref]);
		})
	}, [stateInputs]);

	return (
		<FormContext.Provider value={{stateForm, setStateForm}}>
			<Style id="section_form">
				<h2>{block_title}</h2>
				
				<form ref={formRef} className='landing_form' onSubmit={() => moveToForm()}>
					<div>
						{stateInputs && stateInputs
							.map( (input, i) => {

								return <InputFactory ref={labelsRef[i]} key={input.question} input={input} />
							})
						}

					</div>

					<div className='send-description'>
						Нажимая кнопку «Отправить заявку» я принимаю условия <a href='/policy' target='_blank'>Политики Конфиденциальности</a>
					</div>
				</form>

				<LandingBtn typeBtn='submit' onClick={() => sendForm()}>
					<span>
						Отправить заявку
					</span>
				</LandingBtn>
			</Style>
		</FormContext.Provider>
	)
}

export default When;
