import React from 'react';
import { TSlides } from '../../types';
import { Wrapper, ContentWrapper, SlideElement } from './Style';
import ReactHtmlParser from 'react-html-parser';

const Slides = ({ elements }: {elements: TSlides}) => (
    <Wrapper>
        <ContentWrapper>
            {elements.map((element, i) => (
                <SlideElement type={i % 2 === 0 ? 'left' : 'right'}>
                    <div className='left-element'>
                        <img src={element.slide_image} alt="slide"/>
                    </div>

                    <div className='right-element'>
                        <div className='text-element'>
                            {ReactHtmlParser(element.slide_title)}

                            <p className='slide-description'>
                                {ReactHtmlParser(element.slide_description)}
                            </p>
                        </div>
                    </div>
                </SlideElement>
            ))}
        </ContentWrapper>
    </Wrapper>
);

export default Slides;
