import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import Button from '@app/components/Buttons/Button';

interface Props {
  marginMobile?: string;
}

const FiltersButton = styled(Button)`
  display: none;
  background-color: #f0f3f6;
  margin-bottom: 30px;

  ${StyleMediaMax.mobileM} {
    display: block;
    margin: ${props => props.marginMobile ? props.marginMobile : undefined};
  }
`;

export default FiltersButton;
