import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
    isVacancy?: boolean;
}

const DarkBackground = styled.div<Props>`
  position: fixed;
  display:flex;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-color: rgba(8, 41, 74, 0.30);
  z-index: ${props => props.isVacancy? '2': '4'};

  &:hover{
    cursor: ${props => props.isVacancy? 'default': 'pointer'};
  }
  ${StyleMediaMax.mobileM} {
    z-index: ${props => props.isVacancy? '0': '4'};
  }
`;

export default DarkBackground;
