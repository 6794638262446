import React from "react";

import useAxiosFetch from "@app/api/useAxiosFetch";
import NewsMoreView from "./NewsMoreView";
import { NewsModel } from "@app/components/pages/NewsPage/NewsItemPage/NewsModel";

interface Props {
  newsId: number;
}

const NewsMore = ({newsId}: Props) => {
  const {data, loading, error, setReload} = useAxiosFetch('news/?page_size=5');
  let newsData = data && data.results.filter((newsItem: NewsModel) => newsItem.id !== newsId).slice(0, 4);

  return <NewsMoreView
            isError={error}
            isLoading={loading}
            toggleReload={setReload}
            newsData={newsData}
         />
};

export default NewsMore;