import styled from 'styled-components';
import Button from './Button';
import { StyleMediaMax } from '@app/components/StyleMedia';

const OrangeButton = styled(Button)`
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.black};

  :hover{
    color: ${props => props.theme.color.white};
    background-color: ${props => props.theme.color.blackButtonHover};
  }

  :disabled {
    color: ${props => props.theme.color.searchGrey};
    background-color: ${props => props.theme.color.breadCrumbsText};
  }
  ${StyleMediaMax.mobileM} {
  }
`;

export default OrangeButton;
