import React from 'react';
import Wrapper from './Wrapper';
import Container from './Container';
import HeaderLogo from './HeaderLogo';
import LandingBtn from './LandingBtn';
import { openMail, scrollTo } from '../pages/JavaHiring/btnUtils';
import ym from 'react-yandex-metrika';

const HeaderLanding = ({ btn_send_text, btn_recommend_text, email, subject, body, alert_text, scroll }: {[key: string] : string}) => {  
  return (
    <Wrapper>
      <Container>
        <HeaderLogo to="/" />
        <button id="landing-mobile-menu" className='landing-mobile-menu' onClick={(e) => {e.currentTarget.classList.toggle('opened');}}>
          <span></span>
          <span></span>
        </button>
        <div className='landing-header-btns'>
          <button className='landing-scroll-btn' onClick={() => scrollTo('steps')}>
            <span>
              Как принять участие
            </span>
          </button>

          <button className='landing-scroll-btn' onClick={() => scrollTo('project')}>
            <span>
              О проекте
            </span>
          </button>

          <LandingBtn typeBtn='submit' onClick={() => scrollTo(scroll)}>
            <span>
              {btn_send_text || ''}
            </span>
          </LandingBtn>

          <LandingBtn typeBtn='recommend' onClick={() => {openMail(email, subject, body, alert_text); ym('reachGoal','java-hiring-recommend-friend');}}>
            <span>
              {btn_recommend_text || ''}
            </span>
          </LandingBtn>

          <p className="copyright">© 2021 Orion Innovation. Все права защищены.</p>
        </div>
      </Container>
    </Wrapper>
  );
};

export default HeaderLanding;
