import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import sizes from '@app/components/StyleMedia/sizes';

import Container from './Container';
import Wrapper from './Wrapper';


interface Props {
  children?: React.ReactNode;
  top?: string;
  isOpen: boolean;
  onClose?: () => void;
  content: React.ReactNode;
  padding?: string;
  left?: string;
}


const Dropdown: React.FC<Props> = ({ children, isOpen, onClose, content, padding, left }) => {
  const node = useRef(null);
  useEffect(() => {
    const handleClick = (event: any) => {
      const domNode = ReactDOM.findDOMNode(node.current);
      if (!(domNode && (domNode.contains(event.target) || domNode === event.target)) && window.innerWidth > sizes.mobileM && onClose) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  return (
    children ?
      <Wrapper ref={node}>
        {children}
        {isOpen && <Container padding={padding} left={left}>{content}</Container>}
      </Wrapper>
      : isOpen ?
        <Container ref={node} padding={padding} left={left}>{content}</Container>
        : null);
}

export default Dropdown;