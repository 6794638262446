import moment from "moment";
import "moment/locale/ru";

type DateType = 'hour' | 'day' | "dayYear";

const getDate = (utcTime: string, type: DateType) => {
  const format = type === 'day' ? 'D MMMM' : type === 'dayYear' ? 'D MMMM YYYY' : type === 'hour' ? 'H[:]mm' : '';
  return moment(utcTime).format(format)
};

export default getDate;