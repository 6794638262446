import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface Props {
  color?: string;
}
const DetailNavLink = styled(NavLink)<Props>`
  color: ${props => props.color === 'blue' ? props.theme.color.black : props.theme.color.primaryColorGrey};
  text-decoration: none;

  &:hover{
    text-decoration: underline;
  }
`;

export default DetailNavLink;