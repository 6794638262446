import styled from 'styled-components';
import {theme} from "@app/styles/theme";

interface Props {
  multiSelect?: boolean;
  isChecked?: boolean;
}

const Wrapper = styled.div<Props>`
  color: ${props => props.isChecked? theme.color.primaryColor : theme.color.primaryColorGrey};
  display: flex;
  cursor: pointer;
  padding-bottom: ${props => props.multiSelect && "20px"};
`;

export default Wrapper;
