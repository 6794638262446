import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  flex?: string;
  padding?: string;
  paddingMobile?: string;
  paddingTablet?: string;
  color?:string;
  linkColor?:string;
  fontWeight?: string;
  margin?: string;
  display?: string;
}

const FlexItem = styled.div<Props>`
  flex: ${props => props.flex ? props.flex : undefined};
  padding: ${props => props.padding ? props.padding : undefined};
  font-weight: ${props => props.fontWeight ? props.fontWeight : undefined};
  margin: ${props => props.margin ? props.margin : undefined};
  p {
    color: ${props => props.color ? props.color : undefined};
  }
  p a {
    color: ${props => props.linkColor ? props.linkColor : undefined};
  }
  p b {
    font-weight: "600";
  }

  ${StyleMediaMax.tablet} {
    padding: ${props => props.paddingTablet ? props.paddingTablet : undefined};
    display: ${props => props.display ? props.display : undefined};
    vertical-align: ${props => props.display ? "bottom" : undefined};
  }

  ${StyleMediaMax.mobileM} {
    padding: ${props => props.paddingMobile ? props.paddingMobile : undefined};
  }
`;

export default FlexItem;
