import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
import { FiltersProvider } from '@app/components/FiltersContext/Context';

import CrumbRoute from '@app/components/CrumbRoute';

import VacancyWidget from './VacancyWidget';
import Vacancy from './Vacancy';
import { ParagraphTitle } from '@app/components/PageLayout';
import { theme } from "@app/styles/theme";

const VacancyPage: React.FC<RouteComponentProps> = ({ location, match }) => {

return(
  // <FiltersProvider>
  //   <Switch>
  //     <Route path={`${match.url}`} exact render={(props) => <VacancyWidget {...props.location} />}/> 
  //     <CrumbRoute path={`${match.url}/:vacancyId`} Component={Vacancy} />
  //   </Switch>
  // </FiltersProvider>
  <ParagraphTitle color={theme.color.textLight} fontWeight="bold" padding="10px 80px">
    На данный момент открытых вакансий нет.
  </ParagraphTitle>
  )
};

export default VacancyPage;