import styled from 'styled-components';

const StyledRadio = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  border-radius: 13px;
  border: ${props => `2px solid ${props.theme.color.inputBorder}`};
  margin-right: 15px;
`;

export default StyledRadio;