import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  paddingTablet?: string;
  paddingMobile?: string;
}

const NewsGrid = styled.div<Props>`
  display: grid;
  padding: 40px 0 60px 0;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  ${StyleMediaMax.tablet} {
    grid-template-columns: 1fr;
    max-width: calc(100vw - 120px);
    padding: ${props => props.paddingTablet ? props.paddingTablet : undefined};
  }

  ${StyleMediaMax.mobileM} {
    grid-template-columns: 1fr;
    max-width: calc(100vw - 40px);
    grid-row-gap: 20px;
    padding: ${props => props.paddingMobile ? props.paddingMobile : undefined};
  }
`;

export default NewsGrid;