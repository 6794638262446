import React from 'react';
import Style from './Style';
import { ILandingBtnsData, TCandidates } from '../../types';
import { parseText } from '@app/utils';
import ReactHtmlParser from 'react-html-parser';
import LandingBtn from '@app/components/HeaderLanding/LandingBtn';
import { openMail, scrollTo } from '../../btnUtils';

const Demands = ({ block_title, candidates, btns } : {block_title: string, candidates: TCandidates, btns: ILandingBtnsData}) => {
	return (
		<Style id='section_candidate'>
			<h2 className='candidate-section-title'>{block_title}</h2>

			<div className='candidate-wrapper'>
				{candidates.map((candidate, i) => (
					<div className='candidate-card' key={i}>
						<div className='candidate-title'>
							{ReactHtmlParser(candidate.search_text)}
						</div>
						
						<div className='candidate-description'>
							<ul className='candidate-description__list'>
								{candidate.candidate_description.map((item) => (
									<li key={item.description}>
										{ReactHtmlParser(item.description)}
									</li>
								))}
							</ul>
						</div>
					</div>
				))}
			</div>

			<div className='candidate-btns'>
				<LandingBtn typeBtn='submit' onClick={() => scrollTo('form')}>
					<span>
						{parseText(btns.btn_send_text)}
					</span>
				</LandingBtn>

				<LandingBtn
					typeBtn='recommend'
					onClick={() => openMail(btns.btn_recommend_email, btns.btn_recommend_subject, btns.btn_recommend_body, btns.btn_recommend_alert_text)}
				>
					<span>
						{parseText(btns.btn_recommend_text)}
					</span>
				</LandingBtn>
			</div>
	  </Style>
	);
};

export default Demands;
