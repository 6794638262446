const scrollToPosition = (id: string, top: number = 0, sideEffect?: (close: boolean) => void) => {

  const wrapper = document.getElementById("wrapper");
  const element = document.getElementById(id);

  const scrollFunc = (elem: HTMLElement, topValue: number) => {
    elem.scrollTo({
      top: topValue,
      left: 0,
      behavior: "smooth"
    });
  }

  if (sideEffect) {
    sideEffect(false);

    const content = document.getElementById("content");
    const paddingOfContent = content && getComputedStyle(content).paddingTop;
    const toInt = paddingOfContent && parseInt(paddingOfContent.slice(0, -2));

    if (wrapper && element && toInt) {
      const computedTop = element.getBoundingClientRect().top + wrapper.scrollTop - toInt - top;
      scrollFunc(wrapper, computedTop);
    }
  }
  else {
    element && scrollFunc(element, top);    
  }
};

export default scrollToPosition;