import styled, { keyframes } from 'styled-components';

interface Props {
  icon: string;
}

const animation = keyframes`
{
  0% { transform: rotate(0deg); }
  50% { transform: rotate(180deg); }
  100% { transform: rotate(360deg); }
}
`;

const LoadingElement = styled.div<Props>`
  margin: auto;
  height: 50px;
  background: url(${props => props.icon}) no-repeat center center;
  animation: ${animation} 0.7s infinite linear;
`;

export default LoadingElement;