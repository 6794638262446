import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import ViewsWrapper from '@app/components/Views/Wrapper';

const NewsItemFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-top: 30px;
  justify-content: space-between;

  ${StyleMediaMax.mobileM} {
    padding-top: 20px;
    max-width: 360px;

    ${ViewsWrapper}{
      position: absolute;
      top: 95px;
      right: 20px;
    }
  }
`;

export default NewsItemFooterWrapper;