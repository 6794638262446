import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Style= styled.section`
  & {
    max-width: 1200px;
    margin: 0 auto;
    padding: 160px 0;
    box-sizing: border-box;
  }

  .send-description {
    display: block;
    margin: 64px 0 40px;
    font-family: Gerbera Regular, sans-serif;
    font-size: 14px;
    line-height: 168%;
    letter-spacing: -0.04em;
    color: #333333;
  }
 

  ${StyleMediaMax.tabletS} {
    & {
      padding: 0;
      margin: 180px auto;
    }
  }

  ${StyleMediaMax.mobileL} {
    & {
      padding: 0 24px;
      margin: 80px auto;
    }
  }

  & h2 {
    font-family: Gerbera Medium, sans-serif;
    font-weight: 400;
    font-size: 48px;
    line-height: 124%;
    color: #111111;
    letter-spacing: -0.04em;
    margin-bottom: 96px;
  }

  ${StyleMediaMax.tabletS} {
    & h2 {
      margin-bottom: 40px;
    }
  }
  
  ${StyleMediaMax.mobileL} {
    & h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 120%;
      width: 100%;
      margin-bottom: 40px;
    }
  }
  
  & input {
    font-family: Gerbera Light, sans-serif;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.04em;
    color: #111111;
  }
  & .landing_form {
    padding-top: 1px;
    color: #3B3E48;
  }

  & .landing_form > div:first-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  & #options {
    position: absolute;
    top: 67px;
    width: 100%;
  }
  
  & #options_1 {
    position: relative;
    background: #FFFFFF;
    z-index: 100;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 4px 11px 5px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 4px 11px 5px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 4px 11px 5px rgba(34, 60, 80, 0.2);
  }
  
  & .form_label_checkbox_flex {
    position: relative;
    width: 100%;
    height: 56px;
    box-sizing: border-box;
  }

  & .form_label_checkbox_flex div {
    
    position: relative;
    width: 100%;
    top: 13px;
    left: 15px;
  }

  
  & .form_label_checkbox {
    margin-top: 80px;
    width: 1000px;
    height: 56px;
  }

  
  & .form_label_checkbox label {
    padding-left: 48px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #3B3E48;
  }

  & .form_label_checkbox input[type="checkbox"]:checked + label,
  & .form_label_checkbox input[type="checkbox"]:not(:checked) + label {
    padding-left: 48px;
  }

  & input[type="checkbox"]:checked,
  & input[type="checkbox"]:not(:checked),
  & input[type="radio"]:checked,
  & input[type="radio"]:not(:checked) {
    display: none;
  }

  & input[type="checkbox"]:checked + label,
  & input[type="checkbox"]:not(:checked) + label {
    display: inline-block;
    position: relative;
    padding-left: 28px;
    padding-top: 2px;
    line-height: 20px;
    cursor: pointer;
  }

  & input[type="checkbox"]:checked + label:before,
  & input[type="checkbox"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 24px;
    height: 24px;
    border: 3px solid #3B3E48;
    background-color: #ffffff;
    box-sizing: border-box;
  }

  & input[type="checkbox"]:checked + label:after,
  & input[type="checkbox"]:not(:checked) + label:after {
    content: "";
    position: absolute;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    left: 6px;
    top: 6px;
    width: 10px;
    height: 5px;
    border-left: 3px solid #3B3E48;
    border-bottom: 3px solid #3B3E48;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  & input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
  }

  & input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }


  & .form_label_checkbox_flex input[type="checkbox"]:checked + label,
  & .form_label_checkbox_flex input[type="checkbox"]:checked + label:before,
  & .form_label_checkbox_flex input[type="radio"]:checked  + label {
    background-color: #F2F2F2;
  }

  & .form_label_checkbox_flex:hover,
  & .form_label_checkbox_flex:hover input[type="checkbox"]:checked + label:before,
  & .form_label_checkbox_flex:hover input[type="checkbox"]:not(:checked) + label:before  {
    background-color: #F2F2F2;
  }

  & .form_label_checkbox_flex input[type="checkbox"]:checked + label,
  & .form_label_checkbox_flex input[type="checkbox"]:not(:checked) + label,
  & .form_label_checkbox_flex input[type="radio"]:checked + label,
  & .form_label_checkbox_flex input[type="radio"]:not(:checked) + label {
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 0;
    padding-left: 58px;
    vertical-align: middle;
    cursor: pointer;
    width: 100%;
    height: 56px;
    font-family: Gerbera Light, sans-serif;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.04em;
    color: #111111;
    border-bottom: 0;
  }
  & .form_label_checkbox_flex input[type="radio"]:checked + label,
  & .form_label_checkbox_flex input[type="radio"]:not(:checked) + label {
    padding-left: 16px;
  }

  & .form_label_checkbox_flex input[type="checkbox"]:checked + label:after,
  & .form_label_checkbox_flex input[type="checkbox"]:not(:checked) + label:after {
    left: 22px;
    top: 26px;
  }
  & .form_label_checkbox_flex input[type="checkbox"]:checked + label:before,
  & .form_label_checkbox_flex input[type="checkbox"]:not(:checked) + label:before {

    left: 16px;
    top: 20px;
  }
  & .form_label_select {
    position: relative;
    display: block;
    width: 456px;
    height: 64px;
    border: 1px solid black;
    border-bottom: 3px solid #AAB8C2;
  }

  & .form_label_select select {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    box-shadow: none;
    border-color: transparent;
    outline: none;
  }

  & form div label {
    position: relative;
    display: block;
    padding-bottom: 8px;
    width: 30%;
    border-bottom: 3px solid #BDBDBD;
  }

  & form div .form_label_input {
    margin-top: 64px;
    padding-right: 38px;
  }

 
  & .form_label_input:focus-within {
    border-bottom: 3px solid #333333;
  }

  & .form_label_input:active {
    border-bottom: 3px solid #333333;
  }
  & .form_label_input:hover {
    border-bottom: 3px solid #333333;
  }
  

  & .form_label_input span{
    position: relative;
    height: 23px;
    display: block;
    padding: 0 0 0 8px;
    font-family: Gerbera Regular, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 168%;
    letter-spacing: -0.04em;
    color: #BDBDBD;
  }

  & .form_label_input input[type="text"]::-webkit-input-placeholder {color:#111111;}
  & .form_label_input input[type="text"]::-moz-placeholder          {color:#111111;}/* Firefox 19+ */
  & .form_label_input input[type="text"]:-moz-placeholder           {color:#111111;}/* Firefox 18- */
  & .form_label_input input[type="text"]:-ms-input-placeholder      {color:#111111;}

  & .form_label_input input[type="text"][placeholder]          {text-overflow:ellipsis;}
  & .form_label_input input[type="text"]::-moz-placeholder     {text-overflow:ellipsis;}
  & .form_label_input input[type="text"]:-moz-placeholder      {text-overflow:ellipsis;}
  & .form_label_input input[type="text"]:-ms-input-placeholder {text-overflow:ellipsis;}

  & .form_label_input input[type="text"]:focus::-webkit-input-placeholder {color: transparent}
  & .form_label_input input[type="text"]:focus::-moz-placeholder          {color: transparent}
  & .form_label_input input[type="text"]:focus:-moz-placeholder           {color: transparent}
  & .form_label_input input[type="text"]:focus:-ms-input-placeholder      {color: transparent}

  & .form_label_input input[type="text"] {
    display: block;
    width: 100%;
    padding-left: 8px;
    border: 0;
    box-shadow: none;
    border-color: transparent;
    outline: none;
  }
  & .form_label_input input[type="text"]:focus{
    box-shadow: none;
    border-color: transparent;
    outline: none;
  }
  
  & .form_label_input_error {
    border-bottom: 3px solid #CD0A23;
    span {
      color: #CD0A23;
    }   
    :focus-within, :hover, :active {
      border-bottom: 3px solid #CD0A23;
    }
  }

  & .form_label_checkbox {
    position: relative;
    display: flex;
    flex-direction: row;
    label {
      display: block;      
      width: 30px;
      border: none;
      height: 24px;
      margin: 0;
    }
    p {
      position: absolute;
      left: 40px;
      display: block;
      
    }
  }
  
  ${StyleMediaMax.mobileL} {
    & form {
      & < div:first-child {
        flex-direction: column;
      }
    }
    & form .form_label_checkbox {
      margin-top: 30px;
      font-size: 12px;
      p {
        width: 230px;
        font-size: 12px;
        a {
          font-size: 12px;
        }
      }
    }
    & .form_label_checkbox_flex {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 100%;
    }
    & #options, & #options_1 {
      bottom: 0;
      width: 272px;
      font-size: 14px;
      line-height: 100%;
      div {
        width: 272px;
        bottom: 0;
        label {
          font-size: 14px;
          line-height: 100%;
          width: 272px;
          padding-top: 20px;
          :hover, :active, :focus-within {
            width: 272px;
          }
        }
      }
    }
    & .form_label_checkbox_flex input[type="checkbox"]:checked + label,
    & .form_label_checkbox_flex input[type="checkbox"]:not(:checked) + label,
    & .form_label_checkbox_flex input[type="radio"]:checked + label,
    & .form_label_checkbox_flex input[type="radio"]:not(:checked) + label {
      font-size: 14px;
      line-height: 100%;
    }
    & #options {
      top: 56px;
    }
    & form div .form_label_input {
      font-weight: normal;
      font-size: 12px;
      line-height: 100%;
      height: 56px;
      width: 100%;
      margin-top: 15px;
      bottom: 0;      
      border-bottom: 1px solid #AAB8C2;
      :hover, :active, :focus-within {
        border-bottom: 1px solid black;
      }
    }
    & form div .form_label_input input[type="text"] {
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      width: 220px;
    }

    & form div .form_label_input div {
      bottom: 10px;
    }
    
  } 

  & .input_arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 16px;
    bottom: 14px;
    border-radius: 10px;
    border: 2px solid #3B3E48;
    cursor: pointer;
  }

  & .input_arrow:after {
    content: '';
    position: absolute; 
    left: 4px; 
    bottom: 1px; 
    border: 4px solid transparent; 
    border-top: 4px solid #3B3E48;     
  }
 
   

  & .statement {
    margin-top: 70px;
  	padding: 18px 98px;
  	box-sizing: border-box;
  	border: none;

  	color: #ffffff;
  	background-color: #000000;
  	cursor: pointer;


    font-weight: bold;
    font-size: 16px;
    line-height: 100%;

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }

  ${StyleMediaMax.tabletS} {
    & .statement {
      margin-top: 50px;
      width: 288px;
      height: 60px;
    }    
    & form div label {
      width: 48%;
    }
  }

  ${StyleMediaMax.mobileL} {
    & .statement {
      margin-top: 50px;
      width: 278px;
      height: 45px;
      font-weight: bold;
      font-size: 12px;

      :hover, :focus {
        color: #000000;
        background-color: #ffffff;
        border: 1px solid #000000;
        padding: 0;
      }
    }
  }
`;

export default Style;