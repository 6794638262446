import React from "react";
import { NewsModel } from "@app/components/pages/NewsPage/NewsItemPage/NewsModel";
import NewsCard from "@app/components/NewsCard";
import { getDate } from "@app/utils";
import { WithLoader } from "@app/components/Loader";

interface Props {
  newsData: NewsModel[];
}

const NewsMoreView = ({ newsData }: Props) => (
  <>
    {newsData.map((newsItem: any) =>
      <NewsCard
        {...newsItem}
        key={newsItem.id}
        newsImage={newsItem.image}
        title={newsItem.name}
        date={getDate(newsItem.date, 'dayYear')}
      />)}
  </>
);

export default WithLoader(NewsMoreView);