import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  src?: string;
}

const ComponentsIcon = styled.div<Props>`
  height: 70px;
  width: 70px;
  min-width: 12%;
  margin-right: 30px;
  background: ${props => props.src ? `url(${props.src}) no-repeat` : undefined};
  background-position: center center;
  background-color: ${props => props.theme.color.inputColor};

  ${StyleMediaMax.mobileM} {
    flex-shrink: 0;
  }
`;

export default ComponentsIcon;