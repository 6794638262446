import styled from "styled-components";

import { theme } from "@app/styles/theme";
import { StyleMediaMax } from '@app/components/StyleMedia';
import { FlexContainer } from "@app/components/PageLayout";
import DropParagraph  from './DropParagraph';

const BACKGROUND_COLOR = theme.color.inputColor;

const TemplateContainer = styled(FlexContainer)`
  background: ${BACKGROUND_COLOR};
  justify-content: center;
  align-items: center;

  ${StyleMediaMax.tablet} {
    ${DropParagraph} {
      padding: 0 11vw;
      text-align: center;
    }
  }

  ${StyleMediaMax.mobileM} {
    width: calc(100vw - 40px);
    padding: 20px;

    ${DropParagraph} {
      padding: 0;
    }
  }
`;

export default TemplateContainer;