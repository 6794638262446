import React from 'react';
import * as VacancyElement from './Style';
import ReactHtmlParser from 'react-html-parser';
import telegram from '../../assets/logo/logo_telegram.svg';

interface IVacancyCard {
  info: {
    position: string,
    location: string,
    description: {
      customer: string,
      video?: {url: string, poster: string},
      tasks: {text: string, id: number}[],
      skills: {text: string, id: number}[],
      curator: {
        name: string,
        image: string,
        link: string,
        textLink: string,
      }
    }
    id: number,
  },
  currentVacancy: {
    show: boolean,
    id: number | null,
  },
  setVacancy: ({ show, id }: { show: boolean, id: number | null }) => void,
  color: string,
};

const VacancyCard = ({ info, currentVacancy, setVacancy, color }: IVacancyCard) => {
  return (
    <VacancyElement.VacancyDescription
      active={currentVacancy.id === info.id}
      show={currentVacancy.show}
      color={color}
      onClick={() => currentVacancy.id !== info.id
        ? setVacancy({ show: true, id: info.id })
        : setVacancy({ show: !currentVacancy.show, id: info.id })
      }
    >
      <div className='vacancy-card__content'>
        <p className='vacancy-description__position'>{ReactHtmlParser(info.position)}</p>
        <p className='vacancy-description__location event-paragraph__middle'>{info.location}</p>
        <p className='vacancy-description__arrow'>
          <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.5147 0.439279L25.0607 9.98522C25.6464 10.571 25.6464 11.5208 25.0607 12.1065L15.5147 21.6525C14.9289 22.2383 13.9792 22.2383 13.3934 21.6525C12.8076 21.0667 12.8076 20.1169 13.3934 19.5312L20.3787 12.5459L0 12.5459L0 9.54588L20.3787 9.54588L13.3934 2.5606C12.8076 1.97481 12.8076 1.02507 13.3934 0.439279C13.9792 -0.146508 14.9289 -0.146508 15.5147 0.439279Z" fill="white"/>
          </svg>
        </p>
      </div>

      <div className='vacancy-description__content'>
          <div className='vacancy-content__left'>
            <div className='event-paragraph__large'>
              Описание проекта
            </div>
            <div className='vacancy-customer'>
              <p className='vacancy-customer__item event-paragraph__middle'>
                {info.description.customer}
              </p>
            </div>
            {info.description.video && (
              <>
                <div className='vacancy-about-video-title event-paragraph__large'>
                  О проекте
                </div>
                <div
                  className='vacancy-about-video'
                  onClick={e => e.stopPropagation()}
                >
                  <video
                    controls
                    height='100%'
                    width='100%'
                    src={info.description.video.url}
                    poster={info.description.video.poster}
                  >
                  </video>
                </div>
              </>
            )}
          </div>

          <div className='vacancy-content__right'>
            <div className='event-paragraph__large'>
              Чем предстоит заниматься?
            </div>
            <div className='vacancy-tasks'>
              {info.description.tasks.map((task) => (
                <p key={task.id} className='vacancy-tasks__item event-paragraph__middle'>
                  {ReactHtmlParser(task.text)}
                </p>
              ))}
            </div>
            <div className='event-paragraph__large'>
              Что понадобится в работе? 
            </div>
            <div className='vacancy-skills'>
              {info.description.skills.map((skill) => (
                <p key={skill.id} className='vacancy-skills__item event-paragraph__middle'>
                  {ReactHtmlParser(skill.text)}
                </p>
              ))}
            </div>
            <div className='event-paragraph__large'>
              Интересно? Напиши куратору вакансии 
            </div>
            <div className='vacancy-curator'>
              <div className='curator-image-wrapper'>
                <img src={info.description.curator.image} alt="curator"/>
              </div>
              <div className='curator-contact-wrapper'>
                <div className='event-paragraph__middle'>
                  {info.description.curator.name}
                </div>
                <a
                  href={info.description.curator.link}
                  className='event-paragraph__middle'
                  onClick={e => e.stopPropagation()}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={telegram} alt="telegram"/>
                  {info.description.curator.textLink}
                </a>
              </div>
            </div>
          </div>
        </div>
    </VacancyElement.VacancyDescription>
  );
};

export default VacancyCard;
