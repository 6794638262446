import styled from "styled-components";
import { StyleMediaMax } from '@app/components/StyleMedia';

import { Container } from "@app/components/PageLayout";

const AboutContainer = styled.div`
  background-color: #E1E8ED;
  padding: 60px 0;
`;

const StyledContainer = styled(Container)`
  ${StyleMediaMax.tablet} {
    max-width: calc(100vw - 120px);
  }

  ${StyleMediaMax.mobileM} {
    max-width: calc(100vw - 40px);
  }
`;

export { AboutContainer, StyledContainer };