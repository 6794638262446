import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import smoothscroll from 'smoothscroll-polyfill';
import TagManager from 'react-gtm-module';
import { YMInitializer } from 'react-yandex-metrika';

smoothscroll.polyfill();

const tagManagerArgs = {
  gtmId: 'GTM-KDP3XVW'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <>
    <YMInitializer accounts={[57279067]} />
    <App />
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
