import styled from 'styled-components';
import arrow_up from '@assets/icons/arrow_up.svg';

const UpIcon = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background: url(${arrow_up});
  background-position: center;
  background-repeat: no-repeat;
  border: ${props => `1px solid ${props.theme.color.borderGrey}`};
  margin-right: 15px;
`;

export default UpIcon;