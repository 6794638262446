import React from 'react';

export interface Path {
  name: string;
  to: string;
  param?: string;
}

export const BreadcrumbContext = React.createContext({
  path: [],
  routeName: '',
  pushPath: (newPath: Path) => { },
  clearPath: () => { },
  setPath: (path: Path[]) => { },
  setRouteName: (routeName: string) => { },
});

export interface BreadcrumbContextProvider {
  path: Path[];
  routeName: string;
  pushPath: (pathName: Path) => void;
  clearPath: () => void;
  setPath: (path: Path[]) => void;
  setRouteName: (routeName: string) => void;
}

class BreadcrumbProvider extends React.Component<{}, BreadcrumbContextProvider> {
  state = {
    path: [],
    routeName: '',
    pushPath: (newPath: Path) => {
      this.setState(state => {
        const path: Path[] = [newPath, ...state.path,];
        return ({
          path,
        });
      })
    },
    clearPath: () => this.setState({ path: [] }),
    setPath: (path: Path[]) => this.setState({ path }),
    setRouteName: (routeName: string) => this.setState({ routeName }),
  }

  render() {
    return (
      <BreadcrumbContext.Provider value={this.state}>
        {this.props.children}
      </BreadcrumbContext.Provider>
    )
  }
}
export default BreadcrumbProvider;