import React from 'react';
import Wrapper from './Wrapper';
import FormContainer from './FormContainer';
import Form from './Form';
import Contacts from './Contacts';
import UpIcon from './UpIcon';
import UpContainer from './UpContainer';
import { scrollToPosition } from "@app/utils/";

const ContactFormAboutUs = () => (
  <Wrapper>
    <UpContainer
      onClick={() => scrollToPosition("wrapper")}>
      <UpIcon />
      Up
    </UpContainer>
    <FormContainer>
      <Form />
      <Contacts />
    </FormContainer>
  </Wrapper>
);

export default ContactFormAboutUs;