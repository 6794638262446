import styled from "styled-components";
import { StyleMediaMax } from '@app/components/StyleMedia';

const ContentContainer = styled.div`

  ${StyleMediaMax.tablet} {
    background-color: #ffffff;
    padding: 20px;
    margin-top: 10px;
  }

  ${StyleMediaMax.mobileM} {
    padding: 20px;
  }
`;

export default ContentContainer;
