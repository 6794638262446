import axios from 'axios';

/** Instance of axios for http requests 
* 
* @info
* http://127.0.0.1:8000 is the localhost, using it for the tests purposes
* 
* https://dev1-career.mera.ru - is the address we will use, when server will run on real machine
* 
* @usage
* import { api } from '@app/api/api';

  api.get('meetups')
  .then((data) => {
  console.log(data);
  })
*/

declare module 'axios' {
  export interface AxiosResponse<T = any> extends Promise<T> { }
}

export const api = axios.create({
  baseURL: '/fe-api',
  timeout: 30000,
  headers: {
    'Content-type': 'application/json',
  }
});