import styled from 'styled-components';
import arrow_right from '@assets/icons/black_slide_right.svg';
import arrow_left from '@assets/icons/black_slide_left.svg';

interface Props{
  direction?: string;
}
const PaginationControl = styled.div<Props>`
  height: 17px;
  width: 17px;
  background-image: ${props => props.direction === 'left' ? `url(${arrow_left})` : `url(${arrow_right})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${props => props.direction === 'left' ? 'left center' : 'right center'};
  margin: ${props => props.direction === 'left' ? '0 10px 0 0' : '0 0 0 10px'};
`;

export default PaginationControl;