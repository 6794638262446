import React from 'react';
import { ModalWindowWrapper, ModalWindowContentWrapper } from './Style';

interface IModalWindow {
  children: any,
  setModal: ({show, url, type}: { show: boolean, url: string, type: string }) => void,
};

const ModalWindow = ({ children, setModal }: IModalWindow) => (
  <ModalWindowWrapper onClick={() => setModal({show: false, url: '', type: ''})}>
    <ModalWindowContentWrapper>
      {children}
    </ModalWindowContentWrapper>
  </ModalWindowWrapper>
);

export default ModalWindow;
