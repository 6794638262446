import React, { useState } from 'react';
import { FormContentWrapper } from './Style';
import Form from '../../components/Form';
import Input from '../../components/Input';
import Selector from '../../components/Selector';
import { parseText } from '@app/utils';
import { TLandingType } from '../../types';

interface ILuckForm {
  data: {
    answers: string[];
    name: string;
  },
  type: TLandingType,
};

const LuckForm = ({ data, type }: ILuckForm) => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [selected, setSelected] = useState<string[]>([]);
  const [validate, setValidate] = useState<string[]>([]);

  const submit = () => {
    if (validate.length) {
      setValidate([]);
    }

    const fields = [
      {dataName: 'name', value: name},
      {dataName: 'surname', value: surname},
      {dataName: 'city', value: city},
      {dataName: 'email', value: email},
      {dataName: 'phone', value: phone},
      {dataName: 'selected', value: selected.join(' ')},
    ];

    const tempValidate: string[] = [];
    
    fields.forEach((field) => {
      if (field.dataName === 'email' && (!field.value.includes('@') || !field.value)) {
        tempValidate.push(field.dataName);
      } else {
        if (!field.value) {
          tempValidate.push(field.dataName);
        }
      }
    });

    setValidate(tempValidate);

    const exp = type === 'JPoint' ? 'Опыт разработки на Java' : 'Опыт на JS фреймворках';

    if (!tempValidate.length) {
      const body = `Имя: ${name} Фамилия: ${surname} Город: ${city} Email: ${email} Номер телефона: ${phone} ${exp}: ${selected}`;
      const mailToLink = `mailto:event.rus@orioninc.ru?subject=${data.name}, розыгрыш&body=${parseText(body)}`;
      window.location.assign(mailToLink);
    }
  };

  return (
    <FormContentWrapper>
      <Form
        titleForm=''
        submit={submit}
      >
        <Input
          required={true}
          width='48%'
          placeholder='Имя'
          type='text'
          value={name}
          setValue={setName}
          dataFailure={validate.includes('name')}
        />

        <Input
          required={true}
          width='48%'
          placeholder='Фамилия'
          type='text'
          value={surname}
          setValue={setSurname}
          dataFailure={validate.includes('surname')}
        />

        <Input
          required={true}
          width='48%'
          placeholder='Город'
          type='text'
          value={city}
          setValue={setCity}
          dataFailure={validate.includes('city')}
        />

        <Input
          required={true}
          width='48%'
          placeholder='Email'
          type='text'
          value={email}
          setValue={setEmail}
          dataFailure={validate.includes('email')}
        />

        <Input
          required={true}
          width='48%'
          placeholder='Номер телефона'
          type='text'
          value={phone}
          setValue={setPhone}
          dataFailure={validate.includes('phone')}
        />

        <Selector
          width='48%'
          question={type === 'JPoint' ? 'Опыт разработки на Java *' : 'Опыт на JS фреймворках *'}
          answers={data.answers}
          selected={selected}
          setSelected={setSelected}
          theme='black'
          dataFailure={validate.includes('selected')}
          type='radio'
        />
      </Form>
    </FormContentWrapper>
  );
};

export default LuckForm;
