import styled from 'styled-components';
import { theme } from "@app/styles/theme";
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  last?: boolean;
  tablet?: boolean;
  showMobileBorder?: boolean;
  border?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  background?: string;
  color?: string;
  marginTablet?: string;
}

const NumberContainer = styled.div<Props>`
  height: 100px;
  display: flex;
  align-items: center;
  font-size: 50px;
  padding-left: 30px;
  font-weight: 600;
  line-height: 1.2;
  color: ${props => props.color ? props.color : props.theme.color.primaryColorGrey};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : props.theme.color.inputColor};
  margin: ${props => props.margin ? `${props.margin}` : undefined};

  ${StyleMediaMax.tablet} {
    padding: ${props => props.padding ? `0 0 0 90px` : `0 0 0 30px`};
    border-radius: ${props => props.tablet ? `unset` : undefined};
    margin: ${props => props.marginTablet ? `${props.marginTablet}` : undefined};
  }

  ${StyleMediaMax.mobileM} {
    height: 70px;
    font-size: 34px;
    border: ${props => props.border ? `${props.border}` : undefined};
    padding: ${props => props.padding ? `${props.padding}` : `0 0 0 20px`};
    margin: ${props => props.margin ? `${props.margin}` : undefined};
    background: ${props => props.background ?
    `linear-gradient(to right, ${props.theme.color.inputColor} 19px, ${theme.color.borderLight} 20px, ${props.theme.color.inputColor} 21px)` : undefined};
  }
`;

export default NumberContainer;