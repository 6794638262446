export const tabs: any = [
  {
    title: 'Индустрии',
    type: 'imagetab',
    data: [
      {
        title: 'Автомобильная<br />промышленность',
        id: 0,
      },
      {
        title: 'Здравоохранение',
        id: 1,
      },
      {
        title: 'Индустриальная<br />автоматизация',
        id: 2,
      },
      {
        title: 'Телекоммуникации',
        id: 3,
      },
      {
        title: 'Финансы',
        id: 4,
      },
    ],
    description: '',
    id: 0,
  },
  {
    title: 'Технологии',
    type: 'list',
    description: '',
    data: [
      {
        title: 'Языки и фреймворки: ',
        list: ['JavaScript', 'TypeScript', 'React', 'Angular', 'Vue'],
      },
      {
        title: 'Протоколы: ',
        list: ['REST', 'GraphQL (Apollo)', 'WebSocket', 'UI компоненты:', 'Material', 'Bootstrap'],
      },
      {
        title: 'Backend: ',
        list: ['NodeJS (express, NestJs, Inversify)', 'MongoDB (Mongoose)', 'MySQL (TypeORM, MikroORM)'],
      },
    ],
    id: 1,
  },
  {
    title: 'У нас интересно работать',
    type: 'default',
    image: 'job',
    description: 'Мы одновременно ведем 100+ проектов и можем похвастаться разнообразным стеком технологий. Работаем над задачами, связанными с искусственным интеллектом, машинным обучением, IoT, индустриальной автоматизацией. В компании действует программа инженерного резерва, есть возможность внутреннего перехода между проектами без понижения грейда.',
    data: [],
    id: 2,
  },
  {
    title: 'Мы ценим таланты',
    type: 'default',
    image: 'talant',
    description: 'В компании нет громоздких процедур ассесмента, а карьерные вопросы решаются через беседу с руководителем. Талантливые и амбициозные сотрудники быстро повышают степень ответственности и доход, двигаясь вверх по экспертной или управленческой ветке. Так, 90% наших руководителей и технических экспертов выросли внутри компании с позиций стажеров и инженеров.',
    data: [],
    id: 3,
  },
  {
    title: 'Мы развиваем',
    type: 'default',
    image: 'team',
    description: '80% сотрудников – опытные эксперты в технологиях и проектном управлении. Вместе они формируют развитое профессиональное сообщество: проводят техтоки, участвуют в пилотных проектах, объединяются в группы по интересам. Другие форматы обмена знаниями включают внутренние воркшопы, курсы, конференции и митапы, доступ к образовательным платформам (Pluralsight) и постоянно дополняются.',
    data: [],
    id: 4,
  },
];
