import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 180px;
  left: 0;

  ${StyleMediaMax.tablet} {
    display: none;
  }
`;

export default Wrapper;