import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import logo from '@assets/logo.svg';

const Logo = styled.div`
  height: 34px;
  width: 129px;
  display: block;
  margin: auto;
  background: url(${logo}) no-repeat center center;
  background-size: cover;
`;

const HeaderLogo = Logo.withComponent(NavLink);

export default HeaderLogo;