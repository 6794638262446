import React from 'react';

import DropDownWidget from './DropDownWidget';
import { Title } from '@app/components/PageLayout';
import StyledContainer from './StyledContainer';

const FunctionsWidget = () => (  
    <StyledContainer>
      <Title>
      Функции программного комплекса
      </Title>
      <DropDownWidget/>
    </StyledContainer> 
);

export default FunctionsWidget;