import styles from 'styled-components';
import { TLandingType } from '../../types';
import background from './assets/background-game.jpg';
import backgroundTablet from './assets/background-tablet.jpg';
import backgroundBlue from './assets/background-game-blue.jpg';

interface IWrapper {
  type?: TLandingType;
};

const Wrapper = styles.div<IWrapper>`
  min-height: 100vh;
  width: 100%;
  background-image: url('${props => props.type === 'JPoint' ? background : backgroundBlue}');
  background-repeat: no-repeat;
  background-position-x: center;

  @media (min-height: 921px) {
    background-size: cover;
  }

  @media (min-width: 1890px) {
    background-size: cover;
  }

  @media (max-width: 800px) {
    padding: 120px 0 148px;
    background-image: url('${props => props.type === 'JPoint' ? backgroundTablet : backgroundBlue}');
    background-size: cover;
  }

  @media (max-width: 599px) {
    padding: 80px 0;
  }
`;

const ContentWrapper = styles.div`
  height: 100%;
  max-width: 1128px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    padding: 0 24px;
  }
`;

const GameImage = styles.div`
  width: 48%;

  img {
    width: 100%;
  }

  @media (max-width: 599px) {
    display: none;
  }
`;

const GameDescription = styles.div`
  width: 48%;
  max-width: 555px;
  color: white;

  .game-title {
    margin-bottom: 36px;
    font-size: 48px;
    line-height: 56px;
    font-family: Gerbera Medium;

    @media (max-width: 800px) {
      display: none;
    }
  }

  .game-description {
    margin-bottom: 48px;

    p {
      @media (max-width: 800px) {
        br {
          display: none;
        }
      }
    }

    & > p:first-child {
      margin-bottom: 36px;
    }

    & > p:last-child {
      @media (max-width: 800px) {
        max-width: 325px;
      }

      @media (max-width: 599px) {
        max-width: max-content;
      }
    }

    @media (max-width: 420px) {
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .game-link {
    display: inline-block;
    padding: 20px 97px;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;

    a {
      font-size: 20px;
      line-height: 24px;
      color: white;
      text-decoration: none;
    }

    @media (max-width: 420px) {
      padding: 11px 97px;
      width: 100%;
    }
  }

  @media (max-width: 800px) {
    max-width: 350px;
  }

  @media (max-width: 599px) {
    max-width: 500px;
    width: 100%;
  }
`;

const DescriptionContent  = styles.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .game-title-mini {
    display: none;
    color: white;
    font-size: 64px;

    @media (max-width: 800px) {
      width: 100%;
      display: block;
      margin-bottom: 80px;

      br {
        display: none;
      }
    }

    @media (max-width: 740px) {
      font-size: 56px;
    }

    @media (max-width: 650px) {
      margin-bottom: 60px;
      font-size: 45px;
    }

    @media (max-width: 550px) {
      margin-bottom: 40px;
      font-size: 40px;
    }

    @media (max-width: 500px) {
      font-size: 35px;
    }

    @media (max-width: 420px) {
      margin-bottom: 24px;
      font-size: 32px;
      line-height: 36px;

      br {
        display: block;
      }
    }
  }
`;

export { Wrapper, ContentWrapper, GameImage, GameDescription, DescriptionContent };
