import React from 'react';

import { theme } from '@app/styles/theme';

import { Container, PageTitle, Paragraph } from '@app/components/PageLayout';
import IntroductionWrapper from './IntroductionWrapper';
import AwardIcon from './AwardIcon';
import AwardDescription from './AwardDescription';
import AwardContainer from './AwardContainer';
import AwardWrapper from './AwardWrapper';
import introWidget from '@assets/introWidget.png';
import rect from '@assets/Rectangle.svg';

const WHITE = theme.color.white;
const BORDER_TRANSPARENT = theme.color.transparentDisabled;

const IntroductionWidget = () => (
  <IntroductionWrapper layerOne={rect} layerTwo={introWidget} padding="0 0 120px">
    <Container borderColor={BORDER_TRANSPARENT} padding="194px 0 0" paddingTablet="70px 0 0">
      <PageTitle color={WHITE} margin="0 0 60px 0">О нас</PageTitle>
      <Paragraph borderColor={WHITE} color={WHITE}>
        Мы – центр ключевой экспертизы услуг по аутсорсингу разработки и поддержки ПО международной компании
        Orion Innovation, ранее известный под брендом MERA.
      </Paragraph>
      <Paragraph borderColor={WHITE} color={WHITE}>
        За свою 30-летнюю историю компания MERA прошла интереснейший путь от небольшой группы энтузиастов до корпорации
        со штаб-квартирой в Цюрихе, создала внушительное портфолио заказчиков по всему миру, среди которых как лидеры
        индустрии, так и представители малого и среднего бизнеса. В 2019 мы объединились с рядом других международных
        IT компаний под брендом Orion Innovation.
      </Paragraph>
    </Container>
    <Container>
      <AwardWrapper>
        <AwardContainer>
          <AwardIcon />
          <AwardDescription>The European Software <br /> Testing Awards 2020</AwardDescription>
        </AwardContainer>
        <AwardContainer>
          <AwardIcon />
          <AwardDescription>IAOP’s 2021 Best of The <br /> Global Outsourcing 100</AwardDescription>
        </AwardContainer>
      </AwardWrapper>
    </Container>
  </IntroductionWrapper>
);

export default IntroductionWidget;
