import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.color.black};
  height: 155px;
  width: 1170px;
  align-items: center;
  min-width: 750px;

  ${StyleMediaMax.tablet} {
    width: calc(100vw - 120px);
    min-width: unset;
  }

  ${StyleMediaMax.mobileM} {
    text-align: center;
    padding: 40px 0;
    height: auto;
    width: 100%;
    min-width: auto;
    flex-direction: column;

    img {
      width: 100px;
      order: 1;
    }

    >:nth-child(2) {
      order: 3;
    }
    
    >:nth-child(3) {
      margin: 40px 0 30px;
      order: 2;
      >:last-child {
        margin-right: 0;
      }
    }
  }
`;

export default Container;