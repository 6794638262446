import styled from "styled-components";

import { FlexContainer } from "@app/components/PageLayout";
import { theme } from "@app/styles/theme";

const DropContainer = styled(FlexContainer)`
  padding: 24px 0;
  border-bottom: 1px solid ${theme.color.borderLight};
  position: relative;
  flex-flow: column;
`;

export default DropContainer;