import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const NewsShareContainer = styled.div`
  display: flex;
  font-weight: 600;
  line-height: 1.63;
  align-items: center;

  ${StyleMediaMax.mobileM} {
    width: 100%;
  }
`;

export default NewsShareContainer;