import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const AwardContainer = styled.div`
  height: 120px;
  width: 300px;
  display: flex;
  align-items: center;
  margin-right: 30px;
  padding-left: 30px;
  background-color: ${props => props.theme.color.transparentDisabled};

  ${StyleMediaMax.mobileM} {
    margin: 0 0 20px;
    width: calc(100% - 20px);
  }
`;

export default AwardContainer;