import styled from 'styled-components';
import plus from './assets/plus.png';
import minus from './assets/minus.png';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Style = styled.section`
  & {
    width: 1200px;
    max-width: 100%;
    margin: 160px auto;
  }

  .faq-title {
    margin-bottom: 20px;
    font-family: Gerbera Medium, sans-serif;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #111111;
  }

  .faq-description {
    padding-bottom: 80px;
    font-family: Gerbera Medium, sans-serif;
    font-size: 48px;
    line-height: 124%;
    letter-spacing: -0.04em;
    color: #111111;
    border-bottom: 2px solid #BDBDBD;
  }

  .question-list {

    .question-element {
      margin-top: 48px;
      padding-bottom: 48px;
      display: flex;
      flex-wrap: nowrap;
      border-bottom: 2px solid #BDBDBD;

      .question-image {
        padding-right: 50px;
        width: 70px;
      }

      .answer-description {
        display: flex;
        flex-wrap: wrap;
        width: 89vw;

        .answer-description__title {
          font-family: Gerbera Regular, sans-serif;
          font-weight: 400;
          font-size: 32px;
          line-height: 124%;
          letter-spacing: -0.04em;
          color: #111111;
        }

        .answer-description__paragraph {
          max-width: 960px;
          margin-top: 32px;
          font-family: Gerbera Light, sans-serif;
          font-size: 20px;
          line-height: 148%;
          letter-spacing: -0.04em;
          color: #333333;
        }
      }
    }
  }

  ${StyleMediaMax.tabletS} {
    & .question-list .question-element {
      margin: 0;
      padding: 20px 0;
      & h4 {
        font-size: 24px !important;
      }
    }
    .faq-description {
      font-size: 32px;
      padding-bottom: 30px;
    }
  }

  ${StyleMediaMax.mobileL} {
    & {
      margin: 80px auto;
    }
    & .answer-description__paragraph {
      font-size: 16px !important;
    }
  }

  ${StyleMediaMax.mobile} {
    & .question-image {
      display: none;
    }
    & .question-list .question-element {
      & h4 {
        font-size: 18px !important;
      }
    }
  }

  & h2 {
    font-weight: bold;
    font-size: 60px;
    line-height: 112%;
    color: #3B3E48;
    margin-bottom: 80px;
  }


  ${StyleMediaMax.mobileL} {
    & h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 120%;
      margin-bottom: 40px;
    }
  }

  & ul {
  	list-style: none;

  	li {		
  		border-bottom: 2px solid #AAB8C2;
  		margin-bottom: 24px;
  		padding-bottom: 30px;
  		box-sizing: border-box;
  		display: flex;
  		flex-wrap: wrap;
  	}

  	h4 {
      font-size: 32px;
      line-height: 100%;
      color: #3B3E48;
  	}

  	p {
      font-size: 18px;
      line-height: 140%;
      color: #657786;
  		margin-top: 60px;

      width: 1000px;

      a {
        font-size: 20px;
        line-height: 28px;
        text-decoration: none;
      }
  	}
  }


  ${StyleMediaMax.mobileL} {
    & ul {

      li {
        border-bottom: 1px solid #AAB8C2;
        margin: 0;
        padding: 17px 0px;
      }
      span {
        height: 20px;
      }
      p {
        font-size: 14px;
        line-height: 20px;

        margin-top: 20px;
        margin-bottom: 0;

        a {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }


`;

type Props = {
  isOpen: boolean,
}

const Arrow = styled.span<Props>`
  margin-left: auto;

  & button {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
    outline: none;
  }
  
  ${StyleMediaMax.mobileL} {
    & button {
      width: 24px;
      height: 24px;
      vertical-align: top;
    }
  }

  & button:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: url(${props => props.isOpen ? minus : plus});
  }

  ${StyleMediaMax.mobileL} {
    & button:before {
      top: 0;
      left: -15px;
    }
  }
`

export {Style, Arrow};

