import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  alignCenter?: boolean;
}

const ResponseFormWrapper = styled.div<Props>`
  max-width: 440px;
  height: 100%;
  padding: ${p => p.alignCenter ? "133px 0 133px 0" : undefined};

  h2{
    text-align: ${p => p.alignCenter ? "center" : "left"};
  }

  ${StyleMediaMax.mobileM} {
    padding: 0;

    h2{
      font-size: 24px;
      line-height: 1.42;
      text-align: center;
    }
  }
`;

export default ResponseFormWrapper;