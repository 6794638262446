import React from 'react';
import * as InputElement from './Style';

interface IInput {
  value: string;
  type: string;
  placeholder: string;
  setValue: (value: string) => void;
  width?: string;
  required?: boolean;
  dataFailure?: boolean;
  key?: number | string;
};

const Input = ({ value, setValue, type, placeholder, width, required, dataFailure, key }: IInput) => {
  return (
    <InputElement.InputWrapper
      className='event-input'
      width={width}
      data-fill={!!value}
      data-failure={dataFailure}
      key={key}
    >
      <InputElement.LabelInput isValue={value} >
        {`${placeholder}${required ? ' *' : ''}`}
      </InputElement.LabelInput>
      <input
        required={required}
        type={type}
        placeholder={`${placeholder}${required ? ' *' : ''}`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
        value={value}
      />
    </InputElement.InputWrapper>
  );
};

export default Input;
