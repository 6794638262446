import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  margin?: string;
  marginMobile?: string;
  marginTablet?: string;
  borderColor?: string;
  color?: string;
  hideOnMobile?: boolean;
}

const SubParagraph = styled.div<Props>`
  margin: ${props => props.margin ? props.margin : undefined};
  padding-left: 30px;
  margin-left: -1px;
  color: ${props => props.color ? props.color : props.theme.color.white};
  border-left: ${props => props.borderColor ? `2px solid ${props.borderColor}` : `2px solid ${props.theme.color.borderLight}`};

  ${StyleMediaMax.tablet} {
    margin: ${props => props.marginTablet ? props.marginTablet : undefined};
  }
  ${StyleMediaMax.mobileM} {
    padding-left: 20px;
    max-width: calc(100vw - 40px);
    display: ${props => props.hideOnMobile ? 'none' : undefined};
    margin: ${props => props.marginMobile ? props.marginMobile : undefined};
  }
`;

export default SubParagraph;