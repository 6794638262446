export const digits = [
  {
    title: '30+',
    description: 'лет на рынке<br />IT-услуг',
    id: 1,
  },
  {
    title: '5000',
    description: 'специалистов<br />в сфере IT',
    id: 2,
  },
  {
    title: '200+',
    description: 'заказчиков<br />по всему миру',
    id: 3,
  },
  {
    title: '10',
    description: 'стран<br />присутствия',
    id: 4,
  },
];
