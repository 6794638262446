import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import lineIcon from '@assets/icons/line_icon.svg'

const Container = styled.div`
  height: 100%;
  width: 1200px;
  max-width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-between;

  .landing-header-btns {
    display: flex;

    .copyright {
      display: none;
    }

    button:nth-child(1), button:nth-child(2) {
      padding-bottom: 4px;
      background: transparent;
      border-width: 0 0 2px 0;
      border-color: transparent;

      span {
        color: #FFFFFF;
      }

      &:hover {
        border-color: #27C3F3;
      }
    }

    button:nth-child(1), button:nth-child(3) {
      margin-right: 40px;
    }

    button:nth-child(2) {
      margin-right: 96px;
    }

    button:nth-child(3) {
      background: transparent;
      border: 1px solid #FFFFFF;

      &:active {
        background: #27C3F3;
      }

      &:hover {
        background: #333333;
      }
    }

    button:nth-child(4) {
      background: #FFFFFF;

      :hover {
        span {
          color: #666666;
        }
      }

      :active {
        span {
          color: #27C3F3;
        }
      }
    }

    .landing-scroll-btn {
      cursor: pointer;
  
      span {
        font-size: 20px;
        line-height: 160%;
        letter-spacing: -0.04em;
      }
    }

    ${StyleMediaMax.tablet} {
      button {
        margin: 0 15px !important;
        white-space: nowrap;
        span {
          font-size: 16px !important;
        }
      }
    }
    ${StyleMediaMax.tabletS} {
      button {
        span {
          font-size: 20px !important;
        }
      }
      button:nth-child(3), button:nth-child(4) {
        display: none;
      }
    }
  }

  .landing-mobile-menu {
    display: none;
  }

  ${StyleMediaMax.mobileM} {
    .landing-header-btns {
      button span {
        font-size: 24px !important;
      }
    }
  }

  ${StyleMediaMax.mobile} {
    .landing-header-btns {
      display: none;
      position: fixed;
      align-items: flex-start;
      top: 64px;
      flex-direction: column;
      height: 100%;
      width: 100%;
      background: #fff;
      left: 0;
      padding: 30px 15px;

      .copyright {
        position: absolute;
        bottom: 90px;
        left: 20px;
        display: block;
        color: #828282;
        font-size: 12px;
      }

      button span {
        color: #000 !important;
      }
    }
    .landing-mobile-menu.opened ~ .landing-header-btns {
      display: flex;
    }

    .landing-mobile-menu {
      outline: none;
      display: flex;
      background: none;
      border: navajowhite;
      width: 28px;
      height: 28px;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      & > span {
        background: url(${lineIcon}) no-repeat center;
        display: inline-block;
        width: 100%;
        height: 2px;
      }
    }

    .landing-mobile-menu.opened {
      justify-content: center;

      span:nth-child(1) {
        transform: rotate(45deg);
        margin-bottom: -2px;
      }
      span:nth-child(2) {
        transform: rotate(-45deg);
      }
    }
  }
`;

export default Container;
