import React from 'react';
import ResponseFormWrapper from './ResponseFormWrapper';
import StateIcon from './StateIcon';
import TextContainer from './TextContainer';
import { BlueButton, OrangeButton } from '@app/components/Buttons';

interface Props {
  title: string;
  success: boolean;
  buttonText: string;
  textComponent?: React.ReactNode;
  onClick: (e: React.MouseEvent) => void;
  alignCenter?: boolean;
}

const ResponseForm: React.FC<Props> = ({ title, success, buttonText, textComponent, onClick, alignCenter }) => (
  <ResponseFormWrapper alignCenter={alignCenter}>
    <StateIcon success={success} alignCenter={alignCenter} />
    <h2>{title}</h2>
    <TextContainer alignCenter={alignCenter}>{textComponent}</TextContainer>
    {
      success ?
        <BlueButton
          margin={alignCenter ? "0 auto 0 auto" : undefined}
          width="270px"
          onClick={onClick}
        >
          {buttonText}
        </BlueButton> :
        <OrangeButton
          margin={alignCenter ? "0 auto 0 auto" : undefined}
          width="270px"
          onClick={onClick}
        >
          {buttonText}
        </OrangeButton>
    }
  </ResponseFormWrapper>
);

export default ResponseForm;