import styled from 'styled-components';

interface Props {
  color?: string;
  margin?: string;
  padding?: string;
}

const ButtonText = styled.span<Props>`
  padding: ${props => props.padding ? props.padding : '0 0 0 30px'};
  margin: ${props => props.margin ? props.margin : '0'};
  font-weight: 600;
  color: ${props => props.color ? props.color : props.theme.color.white};
`;

export default ButtonText;