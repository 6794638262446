import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  hideOnMobile?: boolean;
  color?: string;
  borderColor?: string;
  fontWeight?: string;
}

const DescriptionContainer = styled.div<Props>`
  font-weight: bold;  
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 20px;
  font-size: 24px;
  line-height: 1.42;
  margin-left: -1px;
  color: ${props => props.color ? props.color : undefined};
  border-left: ${props => props.borderColor ? `2px solid ${props.borderColor}` : `2px solid ${props.theme.color.primaryColorGrey}`};
  font-weight: ${props => props.fontWeight ? props.fontWeight : undefined};

  ${StyleMediaMax.mobileM} {
    display: ${props => props.hideOnMobile ? `none` : undefined};
    font-size: 16px;
    padding-left: 20px;
    font-weight: 500;  
  }
`;

export default DescriptionContainer;