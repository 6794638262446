import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from "react-router";

import Dropdown from '@app/components/Dropdown';
import primary_arrow_down from '@assets/icons/primary_arrow_down.svg';
import primary_arrow_up from '@assets/icons/primary_arrow_up.svg';
import mobile_arrow_down from '@assets/icons/mobile_arrow_down.svg';
import mobile_arrow_up from '@assets/icons/mobile_arrow_up.svg';

import Wrapper from './Wrapper';
import MenuLink from './MenuLink';
import MenuText from './MenuText';
import Container from './Container';
import MenuIcon from './MenuIcon';
import DropdownItem from './DrpodownItem';
import DropdownContainer from './DropdownContainer';

export const NAV_ITEMS = [
  { name: 'Employment', to: '/career/employment' },
  { name: 'Traineeship', to: '/career/start' },
  { name: 'Education center', to: '/career/education' },
  { name: '«Bring friend»', to: '/career/bring_friend' },
  { name: 'Relocation', to: '/career/relocation' },
  { name: 'Success stories', to: '/career/success_stories' },
  { name: 'FAQ', to: '/career/faq' },
];

const dropdownContent = (onClose: () => void) => (
  <DropdownContainer>
    {NAV_ITEMS.map(navItem =>
      <DropdownItem
        to={navItem.to}
        key={navItem.to}
        onClick={e => onClose}>
        {navItem.name}
      </DropdownItem>
    )}
  </DropdownContainer>
);

const containsCareerRoute = (selected: string) => !!NAV_ITEMS.find(item => item.to === selected);

const NavigationMenuAboutUs: React.FC<RouteComponentProps> = ({ location }) => {
  const [isOpen, setOpen] = useState(false);
  const [linkTransition, setlinkTransition] = useState(location.pathname);
  const isCareerRoute = containsCareerRoute(location.pathname);
  const arrowUp = primary_arrow_up;
  const arrowDown = primary_arrow_down;
  const menuIcon = isOpen ? arrowUp : arrowDown;
  const menuIcon_mobile = isOpen ? mobile_arrow_up : mobile_arrow_down;

  if (linkTransition !== location.pathname) {
    setlinkTransition(location.pathname);
    setOpen(false);
  }

  return (
    <Wrapper>
      <MenuLink to="/about">
        About Us
      </MenuLink>
      <MenuLink
        to="/vacancy"
        isActive={(match, locationValue) => {
          return (locationValue.pathname.includes("/projects") || locationValue.pathname.includes("/vacancy")) ? true : false;
        }}
      >
        Vacancies and projects
      </MenuLink>
      {/* <Dropdown
        left="-50%"
        content={dropdownContent(() => setOpen(false))}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      >
        <Container
          onClick={(event) => {
            event.stopPropagation();
            setOpen(!isOpen)
          }}
        >
          <MenuText selected={isCareerRoute}>Career</MenuText>
          <MenuIcon src={menuIcon} src_mobile={menuIcon_mobile}/>
        </Container>
      </Dropdown> */}
      {/* <MenuLink to="/meetups">
        Meetups
      </MenuLink> */}
      <MenuLink to="/contacts">
        Contacts
      </MenuLink>
    </Wrapper>
  );
}

export default withRouter(NavigationMenuAboutUs);
