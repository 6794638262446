import React, { useEffect } from 'react';
import { RouteComponentProps } from "react-router";

import { WithBreadcrumb } from '@app/components/BreadcrumbContext';
import { BreadcrumbContextProvider, Path } from '@app/components/BreadcrumbContext/BreadcrumbProvider';
import useAxiosFetch from '@app/api/useAxiosFetch';
import NewsItemView from "./NewsItemView";

interface matchProps {
  newsId: string;
}

export interface match<matchProps> {
  params: matchProps;
  isExact: boolean;
  path: string;
  url: string;
}

const News: React.FC<BreadcrumbContextProvider & RouteComponentProps<matchProps>> = ({ match, path, setPath }) => {
  const { data = {}, loading, error, setReload } = useAxiosFetch(`news/${match.params.newsId}`);

  useEffect(() => {
    if (path !== []) {
      if (path.filter(route => route.name === '' && route.to === match.url).length > 0 && !loading) {
        const newPath: Path[] = path.map((route: Path) =>
          route.to === match.url && route.name === '' ?
            ({
              ...route,
              name: `${data ? data.name : ''}`,
            }) :
            route);
        setPath(newPath);
      }
    }
    // eslint-disable-next-line
  }, [data, loading]);

  return <NewsItemView
    isLoading={loading}
    isError={error}
    toggleReload={setReload}
    data={data}
  />
};

export default (props: RouteComponentProps) => <WithBreadcrumb {...props} Component={News} />;