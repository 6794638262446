import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import CopyrightContainer from './CopyrightContainer';

const year = new Date().getFullYear();

const CopyrightAboutUs = () => (
  <CopyrightContainer>
    &copy;{ReactHtmlParser(` ${year} Mera NN (An Orion Innovation company)`)}
  </CopyrightContainer>
);

export default CopyrightAboutUs;