import styled from 'styled-components';

const ButtonContent = styled.div`
  height: 100%;
  width: 100%; 
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export default ButtonContent;