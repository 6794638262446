import React from 'react';

import PaginationWrapper from './PaginationWrapper';
import PaginationItem from './PaginationItem';
import PaginationItemsContainer from './PaginationItemsContainer';
import PaginationControl from './PaginationControl';
import ControlContainer from './ControlContainer';
import EllipsisWrapper from './EllipsisWrapper';
import DirectionTitle from './DirectionTitle';

interface Props {
  totalPages: number;
  currentPage: number;
  step: number;
  onChange: (pageNum: number) => void;
}

const hasLeftEllipsis = (first: number, last: number, step: number, current: number) => {
  if (current === last && current - step - 1 > first) {
    return true;
  }
  if (current !== last && current - step - 1 > first) {
    return true;
  }
  return false;
}

const hasRightEllipsis = (first: number, last: number, step: number, current: number) => {
  if (current === first && current + step + 1 < last) {
    return true;
  }
  if (current !== first && current + step + 1 < last) {
    return true;
  }
  return false;
}

const isCurrentPageRange = (pageNum: number, first: number, last: number, step: number, current: number) => {
  if (pageNum === current) {
    return true;
  }
  if (current === first && pageNum <= current + step && pageNum !== last) {
    return true;
  }
  if (current === last && pageNum >= current - step && pageNum !== first) {
    return true;
  }
  if (pageNum <= current + step && pageNum >= current - step) {
    return true;
  }
  return false;
}

const Pagination: React.FC<Props> = ({ totalPages, step, currentPage, onChange }) => {
  const pageNumbers: number[] = Array.from(Array(totalPages + 1).keys()).slice(1);
  const firstPage = pageNumbers[0];
  const lastPage = pageNumbers[pageNumbers.length - 1];

  const flipToLeftMiddlePage = () => {
    currentPage = Math.floor((currentPage - step - 1) / 2 + 1);
    onChange(currentPage);
  };

  const flipToRightMiddlePage = () => {
    currentPage = Math.floor((currentPage + step + totalPages) / 2);
    onChange(currentPage);
  };

  return (totalPages > 1 ?
    <PaginationWrapper>
      <ControlContainer
        onClick={() => currentPage - 1 < firstPage ? null : onChange(currentPage - 1)}
      >
        <PaginationControl direction="left" />
        <DirectionTitle>Назад</DirectionTitle>
      </ControlContainer>
      <PaginationItemsContainer>
        {
          currentPage > firstPage + step &&
          <PaginationItem
            onClick={() => onChange(firstPage)}
            selected={firstPage === currentPage}
          >
            {firstPage}
          </PaginationItem>
        }
        {hasLeftEllipsis(firstPage, lastPage, step, currentPage) && <EllipsisWrapper onClick={flipToLeftMiddlePage}>...</EllipsisWrapper>}
        {
          pageNumbers.map(pageNum =>
            isCurrentPageRange(pageNum, firstPage, lastPage, step, currentPage) &&
            <PaginationItem
              key={pageNum}
              onClick={() => currentPage === pageNum ? null : onChange(pageNum)}
              selected={pageNum === currentPage}
            >
              {pageNum}
            </PaginationItem>)
        }
        {hasRightEllipsis(firstPage, lastPage, step, currentPage) && <EllipsisWrapper onClick={flipToRightMiddlePage}>...</EllipsisWrapper>}
        {
          currentPage < lastPage - step &&
          <PaginationItem
            selected={lastPage === currentPage}
            onClick={() => onChange(lastPage)}

          >
            {lastPage}
          </PaginationItem>
        }
      </PaginationItemsContainer>
      <ControlContainer
        onClick={() => currentPage + 1 > lastPage ? null : onChange(currentPage + 1)}
      >
        <DirectionTitle>Вперед</DirectionTitle>
        <PaginationControl direction="right" />
      </ControlContainer>
    </PaginationWrapper> : null
  );
}

export default Pagination;