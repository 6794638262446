import React from "react";
import ReactHtmlParser from "react-html-parser";

import { OrangeLinkButton } from '@app/components/Buttons';
import { Title } from '@app/components/PageLayout';
import { WithLoader } from "@app/components/Loader";
import { getDate } from "@app/utils";

import NewsGrid from '../NewsGrid';
import NewsItemPageContainer from './NewsItemPageContainer';
import NewsTitleWrapper from './NewsTitleWrapper';
import MobileTitle from './MobileTitle';
import DateContainer from './DateContainer';
import NewsItemContainer from './NewsItemContainer';
import NewsItemFooter from './NewsItemFooter';
import NewsMore from "./NewsMore";
import { NewsModel } from "@app/components/pages/NewsPage/NewsItemPage/NewsModel";

interface Props {
  data: NewsModel;
}
const NewsItemView = ({ data }: Props) => (
  <NewsItemPageContainer padding="135px 0 120px" paddingTablet="70px 60px 120px 0" paddingMobile="40px 20px 70px 0">
    <NewsTitleWrapper>
      <Title paddingTablet="0 40px 0 0">{data.name}</Title>
      <DateContainer>{getDate(data.date, 'dayYear')}</DateContainer>
    </NewsTitleWrapper>
    <MobileTitle>{data.name}</MobileTitle>
    <NewsItemContainer>{ReactHtmlParser(`${data.content}`)}</NewsItemContainer>
    <NewsItemFooter views={data.views} newsTitle={data.name} logo={data.image} />
    <Title margin="110px 0 60px" marginTablet="110px 0 20px" marginMobile="70px 0 0">Читайте также</Title>
    <NewsGrid paddingTablet="40px 0 0 0">
      <NewsMore newsId={data.id} />
    </NewsGrid>
    {data && <OrangeLinkButton to="/news" width="270px" margin="0" marginTablet="60px 0 0" marginMobile="40px 0 0 0">Все новости</OrangeLinkButton>}
  </NewsItemPageContainer>
);

export default WithLoader(NewsItemView);