import styled from 'styled-components';

const Tooltip = styled.div`
  position: absolute;
  bottom: 60px;
  left: 20px;
  max-width: calc(100% - 40px);
  padding: 8px 12px;
  background-color: ${p => p.theme.color.toolTipBack};
  color: ${p => p.theme.color.toolTipText};
  border-radius: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.45;
`;

export default Tooltip;