import styled from 'styled-components';

const EmptyHint = styled.div`
  max-width: calc(100%);
  padding-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.color.secondaryTextLight};
  text-decoration: none;

  :last-child {
    padding-bottom: 0;
  }

  &:hover {
    text-decoration: none;
  }
`;


export default EmptyHint;
