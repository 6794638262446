import styled from 'styled-components';
import LinkButton from './LinkButton';

const TransparentLinkButton = styled(LinkButton)`
  color: ${props => props.theme.color.black};
  background-color: ${props => props.theme.color.white};

  :hover{
    color: ${props => props.theme.color.black};
    background-color: ${props => props.theme.color.whiteButtonHover};
  }
`;

export default TransparentLinkButton;