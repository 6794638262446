import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  height?: string;
  width?: string;
  widthMobile?: string;
  flexFlow?: string;
  alignItems?: string;
  justifyContent?: string;
  margin?: string;
  bottomBorder?: string;
  padding?: string;
  paddingMobile?: string;
  paddingTablet?: string;
  flexDirectionMobile?: boolean;
  color?: string;
  fontWeight?: string;
  marginTablet?: string;
  marginMobile?: string;
  display?: string;
}

const FlexContainer = styled.div<Props>`
  height: ${props => props.height ? props.height : '100%'};
  width: ${props => props.width ? props.width : '100%'};
  display: flex;
  flex-flow: ${props => props.flexFlow ? props.flexFlow : undefined};
  align-items: ${props => props.alignItems ? props.alignItems : undefined};
  justify-content: ${props => props.justifyContent ? props.justifyContent : undefined};
  margin: ${props => props.margin ? props.margin : undefined};
  border-bottom: ${props => props.bottomBorder ? `1px solid ${props.theme.color.secondaryTextLight}` : undefined};
  padding: ${props => props.padding ? props.padding : undefined};
  font-weight: ${props => props.fontWeight ? props.fontWeight : "400"};

  p {
    color: ${props => props.color ? props.color : undefined};
  }

  ${StyleMediaMax.tablet} {
    padding: ${props => props.paddingTablet ? props.paddingTablet : undefined};
    margin: ${props => props.marginTablet ? props.marginTablet : undefined};
    display: ${props => props.flexDirectionMobile ? "table-cell" : undefined};
  }

  ${StyleMediaMax.mobileM} {
    width: ${props => props.widthMobile ? props.widthMobile : 'auto'};
    padding: ${props => props.paddingMobile ? props.paddingMobile : undefined};
    flex-direction: ${props => props.flexDirectionMobile ? "column" : undefined};
    margin: ${props => props.marginMobile ? props.marginMobile : undefined};
    display: ${props => props.display ? props.display : undefined};
  }
`

export default FlexContainer;
