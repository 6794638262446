import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import CrumbRoute from '@app/components/CrumbRoute';
import NewsWidget from './NewsWidget';
import NewsItemPage from './NewsItemPage';
import NewsBox from './NewsBox';

export const options = ['По дате публикации', 'По популярности'];

const NewsPage = () => {
  const [pageNum, setPageNum] = useState(1);
  const [sortBy, setSortBy] = useState(options[0]);
  return (
    <Switch>
      <Route path="/news" exact render={(props) =>
        <NewsBox>
          <NewsWidget
            {...props}
            pageNum={pageNum}
            setPageNum={setPageNum}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </NewsBox>}
      />
      <CrumbRoute path="/news/:newsId" Component={NewsItemPage} />
    </Switch>
  )
};

export default NewsPage;