import React from 'react';
import SchemeWidget from './widgets/SchemeWidget';
import AboutWidget from './widgets/AboutWidget';
import ComponentsWidget from './widgets/ComponentsWidget';
import FunctionsWidget from './widgets/FunctionsWidget';
import TutorialLink from './widgets/TutorialLink';
import { Wrapper } from '@app/components/PageLayout';

const SmartHeatingSystem = () => {
  return (
    <Wrapper padding="135px 0 0" paddingTablet="70px 0 0" paddingMobile="60px 0 0">
      <SchemeWidget/>
      <AboutWidget/>
      <ComponentsWidget/>
      <FunctionsWidget/>
      <TutorialLink/>
    </Wrapper>
  )
};

export default SmartHeatingSystem;
