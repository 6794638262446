import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const NewsCardLink = styled(NavLink)`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export default NewsCardLink;