import styled from "styled-components";

export interface ParagraphProps {
  noMargin?: boolean;
  tel?: boolean;
  isUnderline?: boolean;
}

const StyledParagraph = styled.p<ParagraphProps>`
  margin-bottom: ${props => props.noMargin ? "undefined" : "30px"};
  display: ${props => props.tel? "inline-block" : "inherit"};
  
  a {
    font-size: ${props => props.tel ? "24px" : "inherit"};
    line-height: ${props => props.tel ? "1.42" : "inherit"};
    color: ${props => props.tel ? props.theme.color.textLight : ""};
    text-decoration:  ${props => props.isUnderline ? "underline" : "none"};
  }
  a:hover {
    text-decoration: ${props => props.isUnderline ? "underline" : "underline"};
  }
`;

export default StyledParagraph;
