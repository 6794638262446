import React from 'react';
import StyledTextarea, { TextareaProps } from './StyledTextarea';
import TextareaContainer from './TextareaContainer';
import Tooltip from '../Tooltip';

interface Props extends TextareaProps {
  name: string,
  placeholder: string,
  value: any,
  onChange: (e: React.ChangeEvent<any>) => void,
  onBlur: (e: React.FocusEvent<any>) => void,
  errorMessage?: string;
  backgroundColor?: string;
}

const Textarea: React.FC<Props> = ({ errorMessage, ...props }) => (
  <TextareaContainer>
    {(props.error && errorMessage) && <Tooltip>{errorMessage}</Tooltip>}
    <StyledTextarea {...props} />
  </TextareaContainer>
);

export default Textarea;