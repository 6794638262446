import styles from 'styled-components';
import drop from './assets/drop-image.svg';

const Wrapper = styles.div`
  width: 100%;
  background: black;

  @media (max-width: 420px) {
    padding: 0 !important;
  }
`;

const ContentWrapper = styles.div`
  max-width: 1128px;
  margin: 0 auto;
  padding: 160px 0 140px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1128px) {
    padding: 120px 24px;
  }

  @media (max-width: 420px) {
    padding: 64px 24px;
  }
`;

const DescriptionWrapper = styles.div`
  width: 100%;
  display: flex;
  margin-bottom: 36px;
  justify-content: space-between;

  @media (max-width: 1000px) {
    margin-bottom: 40px;
  }

  @media (max-width: 700px) {
    margin-bottom: 24px;
  }
`;

const DescriptionTextWrapper = styles.div`
  color: white;

  @media (max-width: 1000px) {
    width: 100%;
    margin-right: 0;
  }
`;

const DescriptionTitle = styles.h1`
  margin-bottom: 36px;
  font-family: Gerbera Regular;
  font-size: 64px;
  line-height: 72px;

  @media (max-width: 420px) {
    font-size: 32px;
    line-height: 36px;  
  }
`;

const DescriptionText = styles.div`
  margin-bottom: 92px;

  & > p {
    max-width: 535px;

    @media (max-width: 960px) {
      padding-right: 24px;
    }

    @media (max-width: 700px) {
      max-width: fit-content;
      padding-right: 0;

      br {
        display: none;
      }
    }

    @media (max-width: 420px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media (max-width: 700px) {
    margin-bottom: 0;
  }
`;

const DescriptionTextElement = styles.p`
  font-family: Gerbera Light;
  font-size: 20px;
  line-height: 28px;
`;

const DescriptionImage = styles.div`
  width: 41%;

  img {
    width: 100%;

    @media (max-width: 768px) {
      height: 300px;
    }
  }

  @media (max-width: 960px) {
    width: 55%;
    margin-top: 108px;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

const ScheduleWrapper = styles.div`
  width: 100%;
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 960px) {
    justify-content: initial;
  }

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }

  @media (max-width: 420px) {
    margin-bottom: 32px;
  }
`;

const ScheduleImageWrapper = styles.div`
  width: 43%;

  img {
    width: 100%;
  }

  @media (max-width: 960px) {
    width: 36%;
    margin-right: 24px;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

const ScheduleDescriptionWrapper = styles.div`
  width: 41%;
  color: white;

  & > div {
    margin: 44px 0 36px;
    font-size: 20px;
    line-height: 28px;

    @media (max-width: 1000px) {
      margin-top: 0;
    }

    @media (max-width: 700px) {
      br {
        display: none;
      }
    }

    @media (max-width: 420px) {
      font-size: 16px;
      line-height: 24px;

      br {
        display: block;
      }
    }
  }

  & > p {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 28px;

    & > img {
      margin-right: 24px;
    }

    @media (max-width: 420px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & > p:nth-child(2), & > p:nth-child(3), & > p:nth-child(4) {
    margin-bottom: 20px;
  }

  @media (max-width: 960px) {
    width: 52%;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const ScheduleFooterWrapper = styles.div`
  width: 100%;
  margin-top: 120px;
  font-family: Gerbera Medium;
  font-size: 24px;
  line-height: 32px;
  color: white;

  @media (max-width: 960px) {
    margin-top: 80px;
  }

  @media (max-width: 700px) {
    margin-top: 24px;
  }

  @media (max-width: 420px) {
    margin-top: 48px;
    font-size: 16px;
    line-height: 24px;

    br {
      display: none;
    }
  }
`;

const FormWrapper = styles.div`
  width: 100%;

  .event-title {
    display: none;
  }
`;

const FormContentWrapper = styles.div`
  form {
    flex-direction: row;

    .form-condition {
      margin-top: 64px;

      @media (max-width: 768px) {
        margin-top: 24px;
      }

      @media (max-width: 700px) {
        margin-top: 48px;
      }
    }

    .form-submit {
      margin: 32px 0 0;
      order: 10;
    }

    .event-selector .selector__answer-drop {
      background: url(${drop});
    }

    .event-selector .selector__answers {
      background: white;

      & > div {
        color: black;
        cursor: pointer;

        &:hover {
          background: rgba(0, 0, 0, 0.08);
        }

        & > div {
          display: none;
        }
      }
    }

    & > .event-input:nth-child(1), & > .event-input:nth-child(2), & > .event-input:nth-child(3) {
      margin-bottom: 36px;
    }

    @media (max-width: 768px) {
      & > .event-input:nth-child(4) {
        margin-bottom: 36px;
      }
    }

    @media (max-width: 700px) {
      & > .event-input {
        margin-bottom: 24px;
      }
    }

    .event-input, .event-selector {
      width: 30%;

      @media (max-width: 768px) {
        width: 48%;
      }

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
`;

export {
  Wrapper,
  ContentWrapper,
  DescriptionWrapper,
  DescriptionTextWrapper,
  DescriptionTitle,
  DescriptionText,
  DescriptionTextElement,
  DescriptionImage,
  FormWrapper,
  FormContentWrapper,
  ScheduleWrapper,
  ScheduleImageWrapper,
  ScheduleDescriptionWrapper,
  ScheduleFooterWrapper,
};
