import React, {useContext} from 'react';
import { theme } from "@app/styles/theme";

import Wrapper from './Wrapper';
import Container from './Container';
import BreadcrumbLink from './BreadcrumbLink';
import BreadcrumbItem from './BreadcrumbItem';
import BreadcrumbArrow from './BreadcrumbArrow';
import BreadcrumbContainer from './BreadcrumbContainer';
import { BreadcrumbContext } from '@app/components/BreadcrumbContext';
import { Path } from '@app/components/BreadcrumbContext/BreadcrumbProvider';
import arrow_right from '@assets/icons/arrow_right.svg';
import arrow_right_light from '@assets/icons/arrow_right_light.svg';
import arrow_right_white from '@assets/icons/arrow_right_white.svg';



const StyledBreadcrumb = () => {
  const context = useContext(BreadcrumbContext);
  const isLightBreadCrumb = /\/projects\/\d+$/g.test(context.routeName);
  const colors = context.routeName === "/about"||isLightBreadCrumb ? theme.color.breadCrumbsText : context.routeName === "/meetups" ? theme.color.inputBorder : theme.color.textLight;
  const icons = context.routeName === "/about"||isLightBreadCrumb ? arrow_right_light: context.routeName === "/meetups" ? arrow_right_white : arrow_right;

  return (
        context.path.length > 1 ?
            <Wrapper>
              <Container>
                {
                  context.path.map(
                      (path: Path, i) =>
                          i === context.path.length - 1 ?
                              <BreadcrumbItem key={`${path.name}+${path.to}`} color={colors}>{path.name}</BreadcrumbItem> :
                              <BreadcrumbContainer key={`${path.name}+${path.to}`}>
                                <BreadcrumbLink to={path.to} color={colors}>{path.name}</BreadcrumbLink>
                                <BreadcrumbArrow icon={icons}/>
                              </BreadcrumbContainer>
                  )
                }
              </Container>
            </ Wrapper> : null
  )
};

export default StyledBreadcrumb;