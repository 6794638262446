import styled from 'styled-components';

const Wrapper = styled.div`

`;

const ContentWrapper = styled.div`
    img {
        width: 100%;
        display: block;
    }
`;

export {Wrapper, ContentWrapper};
