import styled from 'styled-components';
import DetailNavLink from '@app/components/DetailNavLink';
import { StyleMediaMax } from '@app/components/StyleMedia';

const CardContainer = styled.div`
  position: relative;
  min-height: 225px;
  display: flex;
  text-decoration: none;
  background-color: ${props => props.theme.color.white};
  box-shadow: ${props => `0 8px 20px 0 ${props.theme.color.shadow}`};

  &:hover {
    ${DetailNavLink} {
      text-decoration: underline;
    }
  }

  ${StyleMediaMax.mobileM} {
    max-width: calc(100vw - 40px);
  }
`;

export default CardContainer;
