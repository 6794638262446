import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props{
  layerOne?: string;
  layerTwo?: string;
  background?: string;
  backgroundColor?: string;
  padding?: string;
  paddingMobile?: string;
  paddingTablet?: string;
}

const Wrapper = styled.div<Props>`
  width: 100%;
  padding: ${props => props.padding ? props.padding : undefined};
  background: ${props => props.layerOne ? `url(${props.layerOne})` : undefined}, ${props => props.layerTwo ? `url(${props.layerTwo})` : undefined} no-repeat center;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : undefined};
  background-size: cover;

  ${StyleMediaMax.tablet} {
    padding: ${props => props.paddingTablet ? props.paddingTablet : undefined};
  }

  ${StyleMediaMax.mobileM} {
    padding: ${props => props.paddingMobile ? props.paddingMobile : undefined};
  }
`;

export default Wrapper;