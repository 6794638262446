import styled from 'styled-components';
import img from '@assets/welcome_image1.jpg';
import { Wrapper } from '@app/components/PageLayout';
import { StyleMediaMax, StyleMediaMin } from '@app/components/StyleMedia';

const WelcomeWrapper = styled(Wrapper)`
  margin: 0 auto;
  background-image: url(${img});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;

  ${StyleMediaMax.desktopS} {
    ${StyleMediaMin.tablet} {
    }
  }

  ${StyleMediaMax.tablet} {
    height: auto;
    background-position: -1500px 0px;
  }

  ${StyleMediaMax.mobileM} {
    height: auto;
    background-position: -990px 0px;
  }
`;

export default WelcomeWrapper;
