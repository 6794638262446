import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import success from '@assets/icons/successfull_send.svg';
import error from '@assets/icons/error_send.svg';

interface Props {
  success: boolean;
  alignCenter?: boolean;
}
const StateIcon = styled.div<Props>`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  margin: ${p => p.alignCenter ? "0 auto 40px auto" : "0 0 60px 0"};
  background: ${props => props.success ? `url(${success}) no-repeat` : `url(${error}) no-repeat`};
  background-position: center center;
  background-color: ${props => props.success ? props.theme.color.inputColor : props.theme.color.primaryColorLight};

  ${StyleMediaMax.mobileM} {
    margin: 0 auto 40px auto;
  }
`;

export default StateIcon;