import React, { FC } from 'react';

import StyledParagraph from './StyledParagraph';
import { ParagraphProps } from './StyledParagraph';
import { ExternalLink } from '@app/components/Links';

interface Props extends ParagraphProps{
  href: string;
  isUnderline?: boolean;
}

const Link:FC<Props> = ({ children, href, noMargin, tel, isUnderline }) => (
  <StyledParagraph noMargin={noMargin} tel={tel} isUnderline={isUnderline}>
    <ExternalLink href={href}>
      {children}
    </ExternalLink>
  </StyledParagraph>
);

export default Link;