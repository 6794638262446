import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import { theme } from '@app/styles/theme';
import { Title } from '@app/components/PageLayout';
import CounterWrapper from '@app/components/pages/HomePage/widgets/CounterWidget/CounterWrapper';
import CounterItem from '@app/components/pages/HomePage/widgets/CounterWidget/CounterItem';
import NumberContainer from '@app/components/pages/HomePage/widgets/CounterWidget/NumberContainer';

const AboutCounterWrapper = styled(CounterWrapper)`
  ${CounterItem}:last-child {
    display: none;
  }

  ${StyleMediaMax.tablet} {
    grid-template-columns: 1fr 1fr;
    max-width: calc(100vw - 40px);
    padding-bottom: 120px;

    ${CounterItem} {
      padding-top: 100px;
      border-left: 1px solid ${theme.color.transparent};
    }

    ${CounterItem}:not(:nth-child(3)) {
      ${NumberContainer} {
        padding-left: 30px;
      }
    }

    ${CounterItem}:nth-child(3), ${CounterItem}:nth-child(4) {
      padding-top: 60px;
    }

    ${CounterItem}:last-child {
      display: flex;
    }
  }

  ${StyleMediaMax.mobileM} {
    grid-template-columns: 1fr;
    max-width: calc(100vw - 40px);
    padding-bottom: 0;

    ${CounterItem} {
      padding-top: 30px;
      border-left: none;

      ${Title} {
        margin: -85px 0 0;
      }

      ${NumberContainer} {
        margin: 0 0 0;
      }
    }

    ${CounterItem}:first-child {
      padding-top: 145px;
    }

    ${CounterItem}:last-child {
      display: none;
    }
  }
`;

export default AboutCounterWrapper;