import styled from 'styled-components';

interface Props {
  padding?: string;
}

const PolicyParagraph = styled.p<Props>`
  padding: ${props => props.padding ? props.padding : undefined};

  a {
    cursor: pointer;
  }
`;

export default PolicyParagraph;