import styled from 'styled-components';
import { PageTitle } from '@app/components/PageLayout';
import { StyleMediaMax } from '@app/components/StyleMedia';

const MobileTitle = styled(PageTitle)`
  display: none;

  ${StyleMediaMax.mobileM} {
    display: block;
  }
`;

export default MobileTitle;