import styled from 'styled-components';
import checkmark from '@assets/icons/checkmark_blue.svg';

interface Props {
  isChecked?: boolean;
}

const StyledCheckbox = styled.div<Props>`
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  border: ${props => `2px solid ${props.theme.color.breadCrumbsText}`};
  margin-right: 11px;
  background-image: ${props => props.isChecked ? `url(${checkmark})` : null};
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;
`;

export default StyledCheckbox;