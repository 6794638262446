import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  width?: string,
  maxWidth?: string;
  widthTablet?: string;
  margin?: string,
  marginMobile?: string,
  marginTablet?: string
}

const Button = styled.button<Props>`
  display: block;
  height: 50px;
  width: ${props => props.width ? props.width : '270px'};
  margin: ${props => props.margin ? props.margin : '0'};
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 50px;
  :disabled {
    cursor: no-drop;
  }

  &:hover {
    text-decoration: none;
  }

  ${StyleMediaMax.tablet} {
    width: ${props => props.widthTablet ? props.widthTablet : '270px'};
    max-width: ${props => props.maxWidth ? props.maxWidth : undefined};
    margin: ${props => props.marginTablet ? props.marginTablet : undefined};
  }

  ${StyleMediaMax.mobileM} {
    margin-top: 30px;
    margin: ${props => props.marginMobile ? props.marginMobile : undefined};
    width: 100%;
    max-width: calc(100vw - 40px);
    min-width: 170px;
  }
`;

export default Button;
