import styled from 'styled-components';
import { PageTitle } from '@app/components/PageLayout';
import { StyleMediaMax } from '@app/components/StyleMedia';

const DisplayTitle = styled(PageTitle)`
  display: block;

  ${StyleMediaMax.mobileM} {
    display: none;
  }
`;

export default DisplayTitle;