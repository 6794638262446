import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import { Container } from '@app/components/PageLayout';
import DropdownContainer from '@app/components/Dropdown/Container';
import DropdownWrapper from '@app/components/Dropdown/Wrapper';

const LocalContainer = styled(Container)`
  ${DropdownContainer}{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 20px;
    margin-top: 10px;
    display: flex;
    border-radius: 8px;
    z-index: 3;
    box-shadow: ${props => `0 4px 10px 0 ${props.theme.color.shadow}`};
    background-color: ${props => props.theme.color.white};
  }

  ${StyleMediaMax.tablet} {
    ${DropdownWrapper}{
      width: 330px;
    }
  }

  ${StyleMediaMax.mobileM} {
    ${DropdownWrapper}{
      width: calc(100% - 20px);
    }
  };
  
`;

export default LocalContainer;