import React from 'react';
import OfficesWidget from "./widgets/OfficesWidget";
import LinksWidget from "./widgets/LinksWidget";
import { Wrapper } from '@app/components/PageLayout';
import useAxiosFetch from "@app/api/useAxiosFetch";

const ContactsPage = () => {
  const { data = [] } = useAxiosFetch('vacancy/offices/');

  return (
    <Wrapper padding="135px 0 0" paddingTablet="70px 0 0" paddingMobile="60px 0 0">
      <OfficesWidget/>
      {/* <LinksWidget offices={data}/> */}
    </Wrapper>
  )
};

export default ContactsPage;