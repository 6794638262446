import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { StyleMediaMax, StyleMediaMin } from '@app/components/StyleMedia';

const DropdownItem = styled(NavLink)`
  text-decoration: none;
  margin: 20px 0 0;
  font-weight: 600;
  color: ${props => props.theme.color.primaryColorGrey};

  &:hover {
    color: ${props => props.theme.color.lightBlue};
  }

  &:first-of-type {
    margin: 0;
  }

  ${StyleMediaMax.tablet} {
    color: ${props => props.theme.color.primaryColorGrey};
    ${StyleMediaMin.mobileM} {
      color: ${props => props.theme.color.primaryColorGrey};
      margin: 30px 0 0;
      width: fit-content;
      display: table;
    }
  }

  ${StyleMediaMax.mobileM} {
    color: ${props => props.theme.color.primaryColorGrey};
    margin: 10px 0 0;
  }
`;

export default DropdownItem;
