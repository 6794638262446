import React from 'react';
import * as BonusElement from './Style';
import { bonuses } from './data';
import { purpleData } from './purpleData';
import { blueData } from './blueData';
import ReactHtmlParser from 'react-html-parser';

const Bonus = ({ color }: {color: 'blue' | 'purple'}) => (
  <BonusElement.Wrapper>
    <BonusElement.ContentWrapper>
      <BonusElement.Title className='event-title'>
        Преимущества <br />и бонусы
      </BonusElement.Title>
      <BonusElement.BonusesWrapper>
        {bonuses.map((el) => (
          <BonusElement.BonusesEl key={el.id}>
            <div className='bonus__image'>
              <img
                src={color === 'purple' ? purpleData[el.id] : blueData[el.id]}
                alt="bonus"
              />
            </div>
            <div className='bonus__title event-paragraph__large'>
              {ReactHtmlParser(el.title)}
            </div>
          </BonusElement.BonusesEl>
        ))}
      </BonusElement.BonusesWrapper>
    </BonusElement.ContentWrapper>
  </BonusElement.Wrapper>
);

export default Bonus;
