import React, { useState } from 'react';
import { Formik, FormikActions } from 'formik';
import * as Yup from "yup";
import { api } from "@app/api/api";
import { scrollToPosition } from "@app/utils/";

import Radiobutton from '@app/components/Radiobutton';
import { BlueButton } from '@app/components/Buttons';
import Checkbox from '@app/components/Checkbox';
import LoadingButton from '@app/components/LoadingButton';

import {
  FormContainer,
  Fieldset,
  Input,
  InputWrapper,
  Textarea,
  ResponseForm,
  FormLink,
  LoadingWrapper,
  AcceptText,
} from '@app/components/Form';

import SectionTitle from '../SectionTitle';
import RadioWrapper from './RadioWrapper';
import CheckboxWrapper from './CheckboxWrapper';

import blueIcon from '@assets/icons/blue_spinner.svg';

const initialValues = {
  'name': '',
  'email': '',
  'phone': '',
  'message': '',
};

export const errorMessage = {
  name: 'Длина имени не должна превышать 100 символов',
  email: 'Неверный формат Еmail',
  phone: 'Введен некорректный номер телефона',
  message: 'Длина сообщения не должна превышать 900 символов'
}

export const phoneRegExp = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/;
export const emailRegExp = /\.[A-Za-zА-Яа-я]{2,15}$/;

interface FormValues {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const removeTooltipID = () => {
  const tooltip = document.getElementById("tooltip");
  tooltip && tooltip.removeAttribute('id');
}

const SendMessageSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, errorMessage.name),
  email: Yup.string()
    .email(errorMessage.email)
    .matches(emailRegExp, errorMessage.email),
  phone: Yup.string()
    .matches(phoneRegExp, errorMessage.phone),
  message: Yup.string()
    .max(900, errorMessage.message),
});

const isEmpty = (field: string) => (field === '');

const Form = () => {
  const [isChecked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [contactBy, setContactBy] = useState<string>('Email');

  const onSubmit = ({ name, email, phone, message }: FormValues, { setStatus, setErrors, setFieldTouched }: FormikActions<FormValues>) => {
    const sendItem = {
      name,
      email,
      phone,
      message,
      contactBy,
    };

    SendMessageSchema.validate({ name, email, phone, message }).then(
      (value) => {
        setStatus('sending');
        setDisabled(true);
        api.post('feedback/contact_hr/', sendItem)
          .then(response => {
            setStatus("success");
            setFieldTouched('name', false);
            setFieldTouched('email', false);
            setFieldTouched('phone', false);
            setFieldTouched('message', false);
            setContactBy('');
            setChecked(false);
          })
          .catch(reject => setStatus("error"))
      }).catch(
      (reject) => {
        removeTooltipID();
        setErrors({
          name: reject.errors[0],
          email: reject.errors[0],
          phone: reject.errors[0],
          message: reject.errors[0],
        });
        scrollToPosition("tooltip", 10, () => {});
      })
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: FormValues, actions: FormikActions<FormValues>) => onSubmit(values, actions)}
      render={({ errors, status, touched, values, setValues, handleBlur, setStatus, setFieldTouched, handleSubmit, handleChange }) => (
        status === "success" ?
          <FormContainer width="570px">
            <ResponseForm
              success={true}
              title="Message sent"
              buttonText="Ready"
              onClick={e => {
                e.preventDefault();
                setStatus('primary');
                setValues({
                  name: '',
                  email: '',
                  phone: '',
                  message: ''
                });
                setFieldTouched('name', false);
                setFieldTouched('email', false);
                setFieldTouched('phone', false);
                setFieldTouched('message', false);
                setDisabled(false);
              }}
              textComponent={<>Thanks! The message was sent successfully.<br />We will contact you. </>} />
          </FormContainer>
          :
          status === "error" ?
            <FormContainer>
              <ResponseForm
                success={false}
                title="Sending error"
                buttonText="Send again"
                onClick={e => {
                  e.preventDefault();
                  setStatus('primary');
                  setFieldTouched('name', false);
                  setFieldTouched('email', false);
                  setFieldTouched('phone', false);
                  setFieldTouched('message', false);
                  setDisabled(false);
                }}
                textComponent={<>There was a failure during sending. <br /> Please try again. </>}
              />
            </FormContainer>
            :
            <Fieldset disabled={disabled}>
              <LoadingWrapper isSending={status === "sending"}>
                <SectionTitle margin="0 0 60px 0">Contact us</SectionTitle>
              </LoadingWrapper>
              <FormContainer
                onSubmit={handleSubmit}
                id="form1">
                <LoadingWrapper isSending={status === "sending"}>
                  <Input
                    error={errors.name === errorMessage.name || (isEmpty(values.name) && touched.name)}
                    name="name"
                    placeholder="Name"
                    autoComplete="off"
                    margin="0 0 20px 0"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={errors.name}
                  />
                  <InputWrapper>
                    <Input
                      error={errors.email === errorMessage.email || (isEmpty(values.email) && isEmpty(values.phone) && touched.email)}
                      name="email"
                      placeholder="Email"
                      autoComplete="off"
                      margin="0 20px 20px 0"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorMessage={errors.email}
                    />
                    <Input
                      error={errors.phone === errorMessage.phone || (isEmpty(values.phone) && isEmpty(values.email) && touched.phone)}
                      name="phone"
                      placeholder="Phone"
                      autoComplete="off"
                      margin="0 0 20px 0"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorMessage={errors.phone}
                    />
                  </InputWrapper>
                  <Textarea
                    error={errors.message === errorMessage.message || (isEmpty(values.message) && touched.message)}
                    name="message"
                    placeholder="Enter your message here"
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorMessage={errors.message}
                  />
                  <RadioWrapper>
                    <div>Preferred way of communication</div>
                    <Radiobutton name="connection" value="Email" label={"Email"} checked={contactBy === "Email"} onChange={(value) => setContactBy(value)} />
                    <Radiobutton name="connection" value="Phone" label={"Phone"} checked={contactBy === "Phone"} onChange={(value) => setContactBy(value)} />
                  </RadioWrapper>
                  <CheckboxWrapper>
                    <Checkbox isChecked={isChecked} onClick={() => (!disabled && setChecked(!isChecked))} label={''} />
                    <AcceptText>
                      I consent to the processing of the <FormLink href="/policy">personal data</FormLink> that I have provided 
                    </AcceptText>
                  </CheckboxWrapper>
                </LoadingWrapper>
                {
                  status === "sending" ?
                    <LoadingButton width="270px" margin="55px 0 0 0" icon={blueIcon} />
                    :
                    <BlueButton
                      width="270px"
                      margin="60px 0 0 0"
                      type="submit"
                      disabled={values.name === '' || (values.email === '' && values.phone === '') || values.message === '' || contactBy === '' || !isChecked}
                    >
                      Send
                    </BlueButton>
                }
              </FormContainer>
            </Fieldset>
      )}
    />
  );
}

export default Form;