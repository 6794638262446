import styled from 'styled-components';
import { ExternalLink } from '@app/components/Links';
import { StyleMediaMax } from '@app/components/StyleMedia';

const WelcomeTitleLink = styled(ExternalLink)`
  font-size: 50px;
  font-weight: 600;
  line-height: 1.2;
  color: ${props => props.theme.color.white};

  ${StyleMediaMax.mobileM} {
    font-size: 34px;
  }
`;

export default WelcomeTitleLink;
