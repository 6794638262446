import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import logo from '@assets/logo.svg';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Logo = styled.div`
  margin-right: 130px;
  height: 46px;
  width: 170px;
  background: url(${logo}) no-repeat center center;
  background-size: cover;

  ${StyleMediaMax.desktopS} {
    margin-right: calc(100vw / 20);
  }
`;

const HeaderLogo = Logo.withComponent(NavLink);

export default HeaderLogo;