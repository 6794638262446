import React from 'react';

import DetailNavLink from '@app/components/DetailNavLink';
import Views from '@app/components/Views';
import { FlexContainer } from '@app/components/PageLayout';

import CardContainer from './CardContainer';
import NewsImg from './NewsImg';
import ContentContainer from './ContentContainer';
import DateContainer from './DateContainer';
import NewsTitle from './NewsTitle';
import NewsCardLink from './NewsCardLink';

interface Props {
  id: number;
  date: string;
  title: string;
  views: number;
  newsImage?: string;
}

const NewsCard: React.FC<Props> = ({ id, date, title, views, newsImage }) => (
  <CardContainer>
    <NewsCardLink to={`/news/${id}`} />
    <NewsImg bg={newsImage} />
    <ContentContainer>
      <DateContainer>{date}</DateContainer>
      <NewsTitle>{title}</NewsTitle>
      <FlexContainer justifyContent="space-between" height="unset" fontWeight="500">
        <DetailNavLink to={`/news/${id}`}>Подробнее</DetailNavLink>
        <Views views={views} />
      </FlexContainer>
    </ContentContainer>
  </CardContainer>
);

export default NewsCard;