import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  widthTablet?: string;
}

const SelectWrapper = styled.div<Props>`
  display: flex;
  position: relative;

  ${StyleMediaMax.tablet} {
    width: ${props => props.widthTablet ? props.widthTablet : undefined};
  }

  ${StyleMediaMax.mobileM} {
    margin: 0 0 30px 0;
    width: 100%;
  }
`;

export default SelectWrapper;
