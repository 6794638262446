import React from 'react';

import IntroductionWidget from './widgets/IntroductionWidget';
import DevCentersWidget from './widgets/DevCentersWidget';
import IndustryWidget from './widgets/IndustryWidget';
import CustomerWidget from './widgets/CustomerWidget';
import CounterWidget from './widgets/CounterWidget';
import JoinUsWidget from './widgets/JoinUsWidget';

const AboutPage = () => (
  <>
    <IntroductionWidget />
    {/* <DevCentersWidget /> */}
    <IndustryWidget />
    {/* <CustomerWidget /> */}
    <CounterWidget />
    {/* <JoinUsWidget /> */}
  </>
);

export default AboutPage;