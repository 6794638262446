import React from 'react';
import Radio from './Radio';
import StyledRadio from './StyledRadio';
import Wrapper from './Wrapper';


interface Props {
  name: string;
  value: string;
  label: string;
  checked?: boolean;
  onChange: (value: string) => void
}

const Radiobutton: React.FC<Props> = ({ name, value, label, checked, onChange }) => (
  <Wrapper>
    <StyledRadio>
      <Radio name={name} value={value} checked={checked} onChange={(event) => onChange(event.target.value)}/>
      <div />
    </StyledRadio>
    {label}
  </Wrapper >
);

export default Radiobutton;