import React from 'react';

import DropDownWrapper from './DropDownWrapper';
import { DropParagraph }  from './Components';
import DropDownContainer from './DropDownContainer';
import { ListWrapper } from '@app/components/PageLayout';

const DropDownWidget = () => (
  <DropDownWrapper>
    <DropDownContainer title="Визуализация объектов и событий" open>
      <DropParagraph>
        <ListWrapper>
          <li>визуализация карты города и тепловой сети с детализацией топологии сети и её элементов в зависимости от масштаба</li>
          <li>визуализация текущего состояния сегментов теплосети с помощью цветовой кодировки</li>
          <li>масштабирование карты города и топологии тепловой сети</li>
          <li>3D визуализация зданий, рельефа и топологии тепловой сети</li>
          <li>перемещение и редактирование объектов тепловой сети</li>
          <li>группировка приборов учета для предоставления агрегированной информации по эффективности теплоснабжения</li>
          <li>визуализация текущих значений показаний теплорегистраторов на карте и в окнах-отчетах</li>
          <li>разграничение доступа к системе для различных категорий пользователей</li>
        </ListWrapper>
      </DropParagraph>
    </DropDownContainer>
    <DropDownContainer title="Обработка и анализ данных">
      <DropParagraph>
        <ListWrapper>
          <li>опрос теплорегистраторов, получение и хранение данных в режиме реального времени</li>
          <li>анализ накопленных данных для прогнозирования потребления тепла</li>
          <li>анализ текущей эффективности теплоснабжения</li>
          <li>резервное копирование и восстановление данных</li>
          <li>возможность интеграции с другими системами (отслеживание транспорта, CRM и т.п.)</li>
        </ListWrapper>
      </DropParagraph>
    </DropDownContainer>
    <DropDownContainer title="Информирование и отчетность">
      <DropParagraph>
        <ListWrapper>
          <li>уведомления для пользователей от диспетчера теплосетевой компании</li>
          <li>оповещения для диспетчера о превышении допустимых значений показаний, отказах, потере связи с теплорегистраторами, сбоях в системе</li>
          <li>система поиска точек на карте города и объектов теплосети</li>
          <li>формирование отчетов</li>
        </ListWrapper>
      </DropParagraph>
    </DropDownContainer>
    <DropDownContainer title="Настройка для конечного потребителя">
      <DropParagraph>
        <ListWrapper>
          <li>импорт данных из внешних источников (GIS, картографические данные)</li>
          <li>настройка элементов для отображения по группам пользователей</li>
          <li>редактируемые атрибуты объектов</li>
          <li>возможность использования свободных источников картографических данных</li>
        </ListWrapper>
      </DropParagraph>
    </DropDownContainer>
  </DropDownWrapper>
);


export default DropDownWidget;