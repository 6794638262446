import styled from 'styled-components';
import SectionTitle from '../SectionTitle';
import { StyleMediaMax } from '@app/components/StyleMedia';

const ContactsWrapper = styled.div`
  padding-right: 20px;
  margin-left: 130px;

  ${StyleMediaMax.tablet} {
    margin: 120px 0 0;

    ${SectionTitle} {
      margin-bottom: 30px;
    }
  }
`;

export default ContactsWrapper;
