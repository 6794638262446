import React, { FC } from "react";
import { FlexContainer, Title } from "@app/components/PageLayout";
import EmptyContainer from "./EmptyContainer";

interface Props {
  padding?: string;
}

const EmptyDataPlaceholder:FC<Props> = ({children, padding}) => (
    <EmptyContainer padding={padding ? padding : "90px 0 120px 0"}>
      <FlexContainer justifyContent="center">
        <Title>{children}</Title>
      </FlexContainer>
    </EmptyContainer>
);

export default EmptyDataPlaceholder;