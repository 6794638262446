import React, { ComponentState, FC, SetStateAction } from "react";

import { FlexContainer, Title } from "@app/components/PageLayout";
import OrangeButton from "@app/components/Buttons/OrangeButton";
import Paragraph from "./Paragraph";
import LoaderContainer from "./LoaderContainer";
import LoadElement from "./LoadElement";
import ErrorElement from "./ErrorElement";
import MainLoaderContainer from './MainLoaderContainer';

interface Props {
  reloading?: SetStateAction<ComponentState>;
  padding?: string;
  error?: boolean;
}

const Loader: FC<Props> = ({ error, reloading, padding }) => (
  <MainLoaderContainer padding={padding ? padding : "50px 0 80px"} paddingMobile="50px 20px 80px 0">
    {!error
      ? (
        <LoaderContainer>
          <LoadElement />
        </LoaderContainer>
      )
      : (
        <FlexContainer flexFlow="column" alignItems="center">
          <LoaderContainer error={error}>
            <ErrorElement />
          </LoaderContainer>
          <Title margin="40px 0 0 0">
            Упс!
                </Title>
          <Paragraph>
            Во время загрузки страницы произошел сбой.<br />
            Пожалуйста, попробуйте еще раз.
                </Paragraph>
          <OrangeButton width="230px" onClick={() => reloading(true)}>
            Повторить
                </OrangeButton>
        </FlexContainer>
      )
    }
  </MainLoaderContainer>
);

export default Loader;