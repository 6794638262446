import React from 'react';
import { RouteComponentProps } from "react-router";
import { Route } from 'react-router-dom';

import { BreadcrumbContext } from '@app/components/BreadcrumbContext';
import CrumbComponent from './CrumbComponent';

interface Props {
  path: string;
  exact?: boolean;
  pathName?: string;
  pathUrl?: string;
  Component: React.FC<any> | React.FC<RouteComponentProps>;
}

const CrumbRoute: React.FC<Props> = ({ path, exact, pathName, pathUrl, Component }) => {
  return (
    <BreadcrumbContext.Consumer>{
      (context) => (
        <Route path={path} exact={exact} render={(routeProps: RouteComponentProps) => {
          return (
            <CrumbComponent
              {...context}
              routeProps={routeProps}
              pathName={pathName ? pathName : ''}
              pathUrl={pathUrl}
              Component={Component}
            />
          )
        }} />
      )
    }
    </BreadcrumbContext.Consumer>
  );
};

export default CrumbRoute;