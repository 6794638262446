import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const FormContainer = styled.div`
  display: flex;
  margin: auto;
  width: 1170px;

  ${StyleMediaMax.tablet} {
    max-width: 100%;
    flex-direction: column;
  }
`;

export default FormContainer;