import React from 'react';
import CopyrightAboutUs from '@app/components/CopyrightAboutUs';
import Container from './Container';
import SocialMediaIcon from './SocialMediaIcon';
import Wrapper from './Wrapper';

import logo from '@assets/logo.svg';
import vk from '@assets/icons/footer_vk.svg';
import linkedin from '@assets/icons/footer_linkedin.svg';
import youtube from '@assets/icons/footer_youtube.svg';

import vk_hover from '@assets/icons/footer_vk_hover.svg';
import linkedin_hover from '@assets/icons/footer_linkedin_hover.svg';
import youtube_hover from '@assets/icons/footer_youtube_hover.svg';

const SocialMediaAboutUs = () => (
  <Wrapper>
    <Container>
      <img src={logo} alt="" />
      <CopyrightAboutUs />
      <div>
        <SocialMediaIcon href="https://vk.com/oi.russia" target="_blank" src={vk} hover={vk_hover} />
        <SocialMediaIcon href="https://linkedin.com/company/mera" target="_blank" src={linkedin} hover={linkedin_hover} />
        <SocialMediaIcon href="https://www.youtube.com/c/orioninnovation" target="_blank" src={youtube} hover={youtube_hover} />
      </div>
    </Container>
  </Wrapper>
);

export default SocialMediaAboutUs;
