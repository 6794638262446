import React, { useState } from 'react';

import NavigationMenuAboutUs from '@app/components/NavigationMenuAboutUs';

import Wrapper from './Wrapper';
import Container from './Container';
import HeaderLogo from './HeaderLogo';
import SearchWrapper from './SearchWrapper';

const HeaderAboutUs = () => {
  const [isRequestOpen, setRequestOpen] = useState(false);

  return (
    <Wrapper>
      <Container>
        <HeaderLogo to="/" />
        <SearchWrapper
          isRequestOpen={isRequestOpen}
          setRequestOpen={(value) => setRequestOpen(value)}
        />
        {!isRequestOpen && <NavigationMenuAboutUs />}
      </Container>
    </Wrapper>
  );
}


export default HeaderAboutUs;