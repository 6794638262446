import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;
  height: 809px;
  padding-top: 120px;
  padding-bottom: 120px;
  background: black;

  ${StyleMediaMax.tablet} {
    padding: 120px 60px;
    height: auto;
  }

  ${StyleMediaMax.mobileM} {
    padding: 70px 20px;
  }
`;

export default Wrapper;
