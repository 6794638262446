import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { StyleMediaMax } from '@app/components/StyleMedia';

const MenuLink = styled(NavLink)`
  text-decoration: none;
  font-weight: 600;
  border-bottom: 2px solid transparent;
  color: ${props => props.theme.color.white};

  &.active {
    border-bottom: ${props => `2px solid ${props.theme.color.primaryColor}`};
    color: ${props => props.theme.color.white};
  }

  &:hover {
    text-decoration: none;
  }

  ${StyleMediaMax.tablet} {
    color: ${props => props.theme.color.primaryColorGrey};

    &.active {
      border-bottom: ${props => `2px solid ${props.theme.color.primaryColor}`};
      color: ${props => props.theme.color.primaryColorGrey};
    }
  }

  ${StyleMediaMax.mobileM} {
    color: ${props => props.theme.color.primaryColorGrey};

    &.active {
      border-bottom: ${props => `2px solid ${props.theme.color.primaryColor}`};
      color: ${props => props.theme.color.primaryColorGrey};
    }
  }
`;

export default MenuLink;
