import React from 'react';
import { ButtonWrapper, ButtonTextWrapper } from './Style';

interface IButton {
  type?: 'button' | 'submit' | 'reset' | undefined;
  text?: string;
  onClick?: (e: React.MouseEvent) => void;
  classes?: string;
};

const Button = ({ type, text, onClick, classes }: IButton) => {
  return (
    <ButtonWrapper
      type={type}
      onClick={onClick}
    >
      <ButtonTextWrapper className={classes || ''}>
        {text}
      </ButtonTextWrapper>
    </ButtonWrapper>
  );
};

export default Button;
