import { parseText } from "@app/utils";

export const scrollTo = (id: string) => {
    const element = document.getElementById(`section_${id}`);
    element && element.scrollIntoView();
    const mobileMenu = document.getElementById('landing-mobile-menu');
    if (mobileMenu && mobileMenu.classList.contains('opened')) mobileMenu.classList.toggle('opened');
};

export const openMail = (email = '', subject = '', body = '', alert_text = '') => {
    const mailToLink = `mailto:${email}?subject=${subject}&body=${parseText(body)}`;
    const text = alert_text.replace(/\\n/g, '\n');
    alert(parseText(text));
    window.location.assign(mailToLink);
};