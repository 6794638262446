import styled from "styled-components";

interface Props {
    margin?: string;
    fontWeight?: string;
}

const DropParagraph = styled.p<Props>`
  margin: ${props => props.margin ? props.margin : '10px 0'};
  font-weight: ${props => props.fontWeight ? props.fontWeight : "400"};
`;

export default DropParagraph;