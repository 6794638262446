import styled from "styled-components";
import { StyleMediaMax } from '@app/components/StyleMedia';
import { FlexContainer } from '@app/components/PageLayout';

import { theme } from "@app/styles/theme";
import { Container } from "@app/components/PageLayout";

const ContactsContainer = styled(Container)`
  border-bottom: 1px solid ${theme.color.borderLight};

  ${StyleMediaMax.tablet} {
    max-width: calc(100vw - 120px);
    ${FlexContainer} {
      flex-direction: column;
    }
  }

  ${StyleMediaMax.mobileM} {
    max-width: calc(100vw - 40px);
  }
`;

export default ContactsContainer;