export const theme = {
  color: {
    primaryColor: '#27c3f3', //gray
    primaryColorMediumLight: '#f79f80',
    primaryColorLight: '#feefe9', //light orange
    primaryColorGrey:'#3b3e48',
    secondaryColor: '#0c7185', //bright blue
    secondaryColorLight: '#669ccf', //bright blue light
    primaryText: '#08294a', //dark blue #08294a;
    hotColor: '#e24a46',
    errorColor: '#e24a36',
    textLight: '#6a7e92', //grey text
    lightBlue: '#27c3f3',
    white: '#ffffff',
    black: '#000000',
    breadCrumbsCareer: '#aab8c2',
    searchGrey: '#aab8c2',
    borderGrey: '#cdd4db', //grey
    borderLight: '#e1e8ed', //border grey light
    transparentBorder: 'rgba(255, 255, 255, 0.3)',
    secondaryTextLight: '#9ca9b6',
    breadCrumbsText: '#e1e8ed',
    inputColor: '#e1e8ed',
    inputColorLightBlack: '#e1e8ed',
    backgroundColorGrey: '#f7f8f9',
    backgroundColorInput: '#f0f3f6',
    inputBorder: '#ccdeef', //light blue for checkbox and radio button borders
    transparentWhite: 'rgba(255, 255, 255, 0.6)',
    transparent: 'rgba(255, 255, 255, 0.2)',
    transparentDisabled: 'rgba(255, 255, 255, 0.1)',
    shadow: 'rgba(8, 41, 74, 0.15)',
    faqQuestionBack: '#e1e8ed',
    faqAnswerBack: '#657786',
    faqQuestionText: '#3b3e48',
    faqAnswerText: 'white',
    toolTipText: '#e24a36',
    toolTipBack: '#fcecea',
    scrollbarGray: '#e1e8ed',
    blackButtonHover: '#303030',
    whiteButtonHover: '#cdcdcd',
  }
};
