import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  isRequestOpen?: boolean;
}
const Wrapper = styled.div<Props>`
  display: flex;
  flex: ${props => props.isRequestOpen ? '1 1' : undefined};

  ${StyleMediaMax.tablet} {
    position: absolute;
    display: block;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    padding: 10px 20px;
    z-index: 3;
    box-shadow: ${props => `0 8px 20px 0 ${props.theme.color.shadow}`};
    background-color: ${props => props.theme.color.white};
  };
`;

export default Wrapper;