import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import { BlueLinkButton } from '@app/components/Buttons';

const IndustryWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 30px;
  margin-left: -1px;

  ${BlueLinkButton} {
    display: none;
  }

  ${StyleMediaMax.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
    grid-row-gap: 40px;
    max-width: calc(100vw - 120px);

    ${BlueLinkButton} {
      display: block;
    }
  }

  ${StyleMediaMax.mobileM} {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
    max-width: calc(100vw - 40px);
  }
`;

export default IndustryWrapper;