import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  mobilePadding?: string;
}

const SubDescription = styled.div<Props>`
  line-height: 1.63;
  color: ${props => props.theme.color.faqAnswerBack};
  padding-top: 30px;
  padding-left: 30px;
  padding-bottom: 120px;

  ${StyleMediaMax.tablet} {
    padding-bottom: 0;
  }

  ${StyleMediaMax.mobileM} {
    padding: ${props => props.mobilePadding ? `${props.mobilePadding}` : undefined};
  }
`;

export default SubDescription;