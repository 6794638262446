import styled from 'styled-components';

export interface InputProps {
  margin: string;
  type?: string;
  error?: boolean;
  backgroundColor?: string;
}

const InputContainer = styled.div<InputProps>`
  position: relative;
  width: 100%;
  margin: ${props => props.margin};

  & > input{
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    font-weight: 400;
    outline: none;
    padding-left: 20px;
    border: ${props => props.error ? `solid 1px ${props.theme.color.errorColor}` : 'none'};
    background-color: ${props => props.backgroundColor? props.backgroundColor :props.theme.color.white};
    color: ${props => props.theme.color.primaryColorGrey};

    &::placeholder{
      color: ${props => props.theme.color.secondaryTextLight};
    }
  }
`;

export default InputContainer;