import { FlattenSimpleInterpolation } from 'styled-components';

export interface MediaQueries {
  [index: string]: FlattenSimpleInterpolation;
}

const sizes = {
  mobile: 640,
  mobileM: 700,
  mobileL: 812,
  tabletS: 1000,
  tablet: 1200,
  laptop: 1366,
  desktopS: 1770,
  desktop: 1920
};

export default sizes;
