import styled from "styled-components";
import FlexContainer from "@app/components/PageLayout/FlexContainer";
import { theme } from "@app/styles/theme";

interface Props {
  error?: boolean;
}

const LOAD = theme.color.secondaryColor;
const ERROR = theme.color.primaryColorLight;

const LoaderContainer = styled(FlexContainer)<Props>`
  width: 120px;
  height: 120px;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-color: ${props => props.error ? ERROR : LOAD};
  border-radius: 50%;
`;

export default LoaderContainer;