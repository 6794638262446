import React from 'react';
import { Wrapper, ContentWrapper } from './Style';

const Poster = ({ path }: {path: string}) => (
    <Wrapper>
        <ContentWrapper>
            <img src={path} alt="poster"/>
        </ContentWrapper>
    </Wrapper>
);

export default Poster;
