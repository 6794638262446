import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import {theme} from "@app/styles/theme";

const DirectionTitle = styled.span`
  color: ${theme.color.black};
  ${StyleMediaMax.mobileM} {
    display: none;
  }
`;

export default DirectionTitle;