import React, {useContext, useState} from 'react';

import { BreadcrumbProvider } from '@app/components/BreadcrumbContext';
import StyledBreadcrumb from "@app/components/StyledBreadcrumb";
import CrumbRoute from '@app/components/CrumbRoute';
import Wrapper from './Wrapper';
import PagesRouter from '@app/components/PagesRouter';
import DarkBackground from "@app/components/Modal/DarkBackground";
import LandingContext from "@app/components/Builder/LandingContext";

interface IContext {
    isDark: boolean,
    setIsDark: (type:boolean) => void,
}

interface IProps  {
  page?: string
}

export const IsDarkContext = React.createContext({} as IContext);


const Content = ({ page }: IProps) => {
    const {isLanding} = useContext(LandingContext);
    const [isDark, setIsDark] = useState(false);
    const value = {isDark, setIsDark};
    return (
  <IsDarkContext.Provider value={value}>
      <Wrapper id="content" isLanding={isLanding}>
        <BreadcrumbProvider>
          { page !== 'AboutUs' && <StyledBreadcrumb /> }
          <CrumbRoute path="/" pathName="Главная" Component={PagesRouter} />
        </BreadcrumbProvider>
      </Wrapper>
      {isDark? <DarkBackground isVacancy={true}/>:undefined}
  </IsDarkContext.Provider>
    )
};

export default Content;