import React from 'react';
import Copyright from '@app/components/Copyright';
import Container from './Container';
import SocialMediaIcon from './SocialMediaIcon';
import Wrapper from './Wrapper';

import logo from '@assets/logo.svg';
import vk from '@assets/icons/footer_vk.svg';
import youtube from '@assets/icons/footer_youtube.svg';

import vk_hover from '@assets/icons/footer_vk_hover.svg';
import youtube_hover from '@assets/icons/footer_youtube_hover.svg';

const SocialMediaSection = () => (
  <Wrapper>
    <Container>
      <img src={logo} alt="" />
      <Copyright />
      <div>
        <SocialMediaIcon href="https://vk.com/oi.russia" target="_blank" src={vk} hover={vk_hover} />
        <SocialMediaIcon href="https://www.youtube.com/c/orioninnovation" target="_blank" src={youtube} hover={youtube_hover} />
      </div>
    </Container>
  </Wrapper>
);

export default SocialMediaSection;
