import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Container = styled.div`
  width: 470px;
  height: 40px;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  // box-shadow: ${props => `0 4px 10px 0 ${props.theme.color.shadow}`};
  background-color: ${props => props.theme.color.white};

  ${StyleMediaMax.desktopS} {
    width: 25vw;
  }

  ${StyleMediaMax.tablet} {
    width: 100%;
  }
`;

export default Container;