import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const PaginationItemsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0 30px;

  ${StyleMediaMax.mobileM} {
    margin: 0;
  }
`;

export default PaginationItemsContainer;