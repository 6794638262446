import React from 'react';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components'
import { theme } from './styles/theme';
import GlobalStyle from './GlobalStyle';
import Builder from '@app/components/Builder';

export const history = createBrowserHistory();

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <Router history={history}>
        <Builder />
      </Router>
    </>
  </ThemeProvider>
);

export default App;
