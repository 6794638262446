import React from 'react';
import Style from './Style';
import { ISteps } from '../../types';
import { parseText } from '@app/utils/index';
import LandingBtn from '@app/components/HeaderLanding/LandingBtn';
import { openMail, scrollTo } from '../../btnUtils';

const Steps = ({ steps, btn_send, btn_recommend, email, subject, body, alert_text }: ISteps) => {
	return (
		<Style>
			<div className='steps-wrapper'>
				<ul>
					{steps && steps.map((step, i) => (
						<li key={step.id}>
							<div>0{i + 1}.</div>
							<p>
								{parseText(step.description)}
							</p>
						</li>
					))}
				</ul>
				
				<div className='steps-btns'>
					<LandingBtn typeBtn='submit' onClick={() => scrollTo('form')}>
						<span>
							{btn_send || ''}
						</span>
					</LandingBtn>

					<LandingBtn typeBtn='recommend' onClick={() => openMail(email, subject, body, alert_text)}>
						<span>
							{btn_recommend || ''}
						</span>
					</LandingBtn>
				</div>
			</div>
	</Style>
	);
};

export default Steps;
