import React from 'react';

import LinkContainer from './LinkContainer';
import { ExternalLink } from '@app/components/Links';

const TutorialLink = () => {
  const tutorial = '/media/static/tutorial_smart_heating_system.docx';

  return (
    <LinkContainer>
      <ExternalLink onClick={()=>{window.open(tutorial, '_blank');}}>Руководство пользователя</ExternalLink>
    </LinkContainer>
  )
};

export default TutorialLink;