import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import logo from '@assets/logo.svg';
import logoMobile from '@assets/logo_mobile.svg';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Logo = styled.div`
  height: 46px;
  width: 170px;
  background: url(${logo}) no-repeat center center;
  background-size: cover;

  ${StyleMediaMax.desktopS} {
    margin-right: calc(100vw / 20);
  }

  ${StyleMediaMax.tablet} {
    margin: 0;
  }

  ${StyleMediaMax.mobileM} {
    height: 28px;
    width: 126px;
    background: url(${logoMobile}) no-repeat center center;
  }
`;

const HeaderLogo = Logo.withComponent(NavLink);

export default HeaderLogo;