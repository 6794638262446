import styles from 'styled-components';

interface ITextareaWrapper {
  width?: string;
};

interface ITextareaLabel {
  value: string;
};

const TextareaWrapper = styles.div<ITextareaWrapper>`
  max-width: ${props => props.width || '100%'};
  width: 100%;

  textarea {
    width: 100%;
    padding: 10px 8px;
    resize: vertical;
    color: white;
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 3px solid rgba(255, 255, 255, .3);

    &::placeholder {
      font-size: 20px;
      line-height: 140%;
      color: white;
    }
  }
`;

const TextareaLabel = styles.div<ITextareaLabel>`
  margin-top: 48px;
  opacity: 0;

  ${props => props.value && `
    opacity: 1;
  `}

  transition: all .2s ease-in-out;
  margin-left: 10px;
  font-size: 20px;
  line-height: 140%;
`;

export { TextareaWrapper, TextareaLabel };
