import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 30px;

  ${StyleMediaMax.tablet} {
    margin-top: 28px;
  }
`;

export default CheckboxWrapper;
