import styled from 'styled-components';

interface Props {
  selected: boolean;
}

const PaginationItem = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
  font-weight: 600;
  color: ${props => props.selected ? props.theme.color.white : props.theme.color.black};
  background-color: ${props => props.selected ? props.theme.color.black : props.theme.color.white};
  &:last-child{
    margin-right: 0;
  }
`;

export default PaginationItem;