import styled from 'styled-components';
import { StyleMediaMin, StyleMediaMax } from '@app/components/StyleMedia';

const DropdownContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  ${StyleMediaMin.mobileM} {
    ${StyleMediaMax.tablet} {
      padding: 45px 0 0 60px;
      width: calc(100% - 40px);
    }
  }

  ${StyleMediaMax.mobileM} {
    margin: 26px 0 0 11px;
  }
`;

export default DropdownContainer;