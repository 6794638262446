import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const StyledInput = styled.input`
  height: 100%;
  width: 100%;
  padding-left: 20px;
  padding-right: 10px;
  border: none;
  outline: none;
  font-weight: 400;
  line-height: 1.63;
  display: flex;
  flex: 1 1;
  &::placeholder{
    color: ${props => props.theme.color.secondaryTextLight};
  }
  &::-ms-clear {
    display: none;
  }

  ${StyleMediaMax.mobileM} {
    width: calc(100% - 85px);
  }
`;

export default StyledInput;