import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const NewsItemContainer = styled.div`
  padding: 60px 0 90px;
  border-bottom: ${props => `1px solid ${props.theme.color.borderLight}`};

  li{
    position: relative;
    left: 18px;
    max-width: calc(100% - 18px);
  }

  img{
    max-width: 100%;
    height: auto !important;
  }

  ${StyleMediaMax.mobileM} {
    padding: 60px 0 70px;

    iframe{
      width: calc(100vw - 40px);
    }
  }
`;

export default NewsItemContainer;