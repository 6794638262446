import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';
import arrow_up_hover from '@assets/icons/arrow_up_hover.svg';
import {theme} from "@app/styles/theme";

const UpContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  margin-left: 75px;
  cursor: pointer;
  align-items: center;
  color: ${props => props.theme.color.white};
  &:hover div {
    background: url(${arrow_up_hover});
    background-color: ${theme.color.breadCrumbsText};
    background-position: center;
    background-repeat: no-repeat;
    border: ${props => `1px solid ${props.theme.color.breadCrumbsText}`};
  }

  ${StyleMediaMax.desktopS} {
    bottom: 30px;
    margin-left: calc((100vw - 1170px)/2);
  }

  ${StyleMediaMax.tablet} {
    display: none;
  }
`;

export default UpContainer;