import styled from 'styled-components';

const Container = styled.div`
  width: 1170px;
  margin: auto;
  display: flex;
  align-items: center;
  font-weight: 400;
`;

export default Container;
