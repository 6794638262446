import React from 'react';
import { TextareaWrapper, TextareaLabel } from './Style';

interface ITextarea {
  placeholder: string;
  value: string;
  setValue: (value: string) => void;
  width?: string;
};

const Textarea = ({ placeholder, value, setValue, width }: ITextarea) => {
  return (
    <TextareaWrapper width={width}>
      <TextareaLabel value={value}>
        {placeholder}
      </TextareaLabel>
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)}
      >

      </textarea>
    </TextareaWrapper>
  );
};

export default Textarea;
