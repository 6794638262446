import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const PaginationWrapper = styled.div`
  display: inline-flex;
  cursor: pointer;

  ${StyleMediaMax.mobileM} {
    width: 100%;
    justify-content: space-between;
  }
`;

export default PaginationWrapper;