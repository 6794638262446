import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

const Style= styled.section`
  & {
    padding-bottom: 160px;
    background: #E1E8ED;
  }

  .steps-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .steps-btns {
    display: flex;
    & > button:first-child {
      margin-right: 40px;
    }
  }
  
  & ul {
    display: flex;
    margin: 0 auto 60px;
    list-style: none;
    padding: 0;
    box-sizing: border-box;
    justify-content: space-between;

    & > li {
      width: 30%;
      margin-right: 0;

      & > div {
        width: 100%;
        margin-bottom: 16px;
        font-family: Gerbera Medium, sans-serif;
        font-size: 48px;
        line-height: 124%;
        color: #111111;
      }

      & > p {
        width: 100%;
        font-family: Gerbera Light, sans-serif;
        font-size: 20px;
        line-height: 160%;
        color: #333333;
        letter-spacing: -0.04em;
      }
    }
  }
  ${StyleMediaMax.mobileM} {
    & ul {
      & > li {
        & > div {
          font-size: 34px;
        }
      }
    }
  }

  & li {
  	display: flex;
  	flex-direction: column;
    margin-right: 90px;
  }

  & p {
    font-size: 18px;
    line-height: 140%;
    color: #3B3E48;
  	width: 300px;
  }
  
  & img {
  	align-self: flex-start;
    margin-bottom: 30px;
  }

  ${StyleMediaMax.tabletS} {
    & ul {
      justify-content: space-between;
    }
    & li {
      margin: 0 0 20px;
      width: 184px;     
    }

    & li:last-child {
      margin-bottom: 0;
    }

    & p {
      width: 184px;
      align-self: center;
    }
    & li:last-child p {
      margin-top: 0;
    }
    & img {
      width: 64px;
      margin-bottom: 30px;
    }
    & button{
      margin: 0;
    }
    & button.friend {
      margin-left: 24px;
    }
  }

  ${StyleMediaMax.mobileL} {
    & {
      padding-bottom: 100px;
    }
    & li {
      margin: 0 0 20px;
    }

    & li:last-child {
      margin-bottom: 0;
    }

    & ul > li > p {
      font-size: 16px;
    }
    & li:last-child p {
      margin-top: 0;
    }
    & img {
      width: 64px;      
      margin-bottom: 10px;
    }
    & button{    
      white-space: nowrap;
    }
    & button.friend {
      margin: 0;
    }
  }

  ${StyleMediaMax.mobileM} {
    & button{    
      height: 40px;
      width: 192px;
      span {
        font-size: 16px;
      }
    }
  }

  ${StyleMediaMax.mobile} {
    & {
      padding: 0 20px 80px;
    }
    & ul {
      flex-direction: column;
      margin-bottom: 40px;

      & > li {
        width: 100%;
        flex-direction: row;

        & > div {
          width: 32px;
          font-size: 24px;
          margin: 0 10px 0 0;
        }
      }
    }
    div.steps-btns {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    & button{    
      width: 288px;
      margin: 8px 0 !important;
    }
  }
`;

export default Style;