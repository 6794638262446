import styled from "styled-components";
import { StyleMediaMax } from '@app/components/StyleMedia';

import { Container } from "@app/components/PageLayout";

const LinkContainer = styled(Container)`
  padding: 80px 0 120px;

  ${StyleMediaMax.tablet} {
    max-width: calc(100vw - 120px);
  }

  ${StyleMediaMax.mobileM} {
    max-width: calc(100vw - 40px);
    padding: 60px 0 100px;
  }

  & a {
    display: block;
    text-decoration: none;
    text-align: center;
    padding: 13px 0;
    font-weight: lighter;
    background: rgba(12, 113, 133, 0.12);
    border: 1px solid #0C7185;
    color: #0C7185;
  }
  & a:hover {
    cursor: pointer;
    background: rgba(12, 113, 133, 0.2);
  }
`;

export default LinkContainer;