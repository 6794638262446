import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface IStyle {
  backgroundImage: string;
};

const Style= styled.section<IStyle>`
  & {
    padding: 100px 0;
    box-sizing: border-box;
    position: relative;
    background: url(${props => props.backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
  }

  .about-project__wrapper {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    color: #FFFFFF;

    .about-project__title {
      z-index: 2;
      margin-bottom: 20px;
      font-family: Gerbera Medium, sans-serif;
      font-size: 20px;
      line-height: 160%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #FFFFFF;
    }

    .project-description__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 80px;

      .about-project__top {
        z-index: 2;
        width: 100%;
        margin-bottom: 20px;

        &, & * {
          font-family: Gerbera Regular, sans-serif;
        }
  
        & * {
          width: 100%;
          font-size: 32px;
          line-height: 124%;
          letter-spacing: -0.04em;
          color: #FFFFFF;
        }
      }

      .about-project__left, .about-project__right {
        z-index: 2;
        width: 50%;

        & ul {
          margin-left: 15px;
          margin-bottom: 30px;
        }

        &, & * {
          font-family: Gerbera Light, sans-serif;
        }

        & * {
          font-size: 20px;
          line-height: 160%;
          letter-spacing: -0.04em;
          color: #FFFFFF;
        }
      }
    }

    .about-project__technologies-title {
      z-index: 2;
      margin: 0 0 50px;
      font-family: Gerbera Regular, sans-serif;
      font-size: 32px;
      line-height: 124%;
      letter-spacing: -0.04em;
      color: #FFFFFF;
    }

    .technologies-wrapper {
      width: 100%;
      justify-content: space-between;

      .technologies-list {
        width: 21%;
        display: flex;
        flex-direction: column;
        list-style: none;

        .technology-type {
          padding-bottom: 8px;
          font-family: Gerbera Medium, sans-serif;
          font-weight: bold;
          font-size: 16px;
          line-height: 148%;
          letter-spacing: 0.064em;
          text-transform: uppercase;
          color: #FFFFFF;
          border-bottom: 2px solid #EAEAEA;
        }

        .technology-element {
          margin-top: 16px;
          padding-bottom: 8px;
          font-family: Gerbera Light, sans-serif;
          font-size: 20px;
          line-height: 160%;
          letter-spacing: -0.04em;
          color: #FFFFFF;
          border-bottom: 1px solid #EAEAEA;
        }
      }
    }
  }

  & h2 {
    font-weight: normal;
    font-size: 60px;
    line-height: 100%;
    color: #3B3E48;
    margin-bottom: 60px;
  }

  & p {
    font-size: 18px;
    line-height: 140%;
    color: #3B3E48;
	  margin-bottom: 40px;
    width: 650px;
  }
  
  & > div > div {
    display: flex;
  }
  
  & > div div p {
    width: 95%;
    margin-bottom: 15px;
  }
  
  & img {
    width: 456px;
    height: 100%;
    margin-right: 120px;
  }
  
  & h3 {
    margin-top: 60px;
    margin-bottom: 60px;
    font-weight: bold;
    font-weight: bold;
    font-size: 32px;
    line-height: 100%;
    color: #3B3E48;
  }

  & h3 + div {
    display: flex;
    flex-direction: row;
  }

  ${StyleMediaMax.tabletS} {
    & {
      padding-top: 100px;
    }

    & h2 {
      line-height: 93%;
    }

    & > div div p {
      margin: 0;
    }

    & img {
      margin-bottom: 20px;
    }

    & h3 {
      margin: 60px 0 80px;
    }

    & h3 + div {
      justify-content: space-between;
    }
    .technologies-wrapper ul {
      width: 22% !important;
      margin: 0;
    } 
  }

  ${StyleMediaMax.mobileL} {
    & {
      padding: 80px 20px;
    }

    & h2 {
      font-size: 16px !important;
      margin-bottom: 16px !important;
    }

    & p {
      font-size: 14px;
      line-height: 140%;
      margin-bottom: 20px;
    } 

    & > div > div {
      flex-direction: column;
      margin-bottom: 10px !important;

      & > div {
        width: 100% !important;
        margin: 0 !important;

        & > p {
          font-size: 16px !important
        } 
      }

      & > div:first-child {
        & > p {
          font-size: 24px !important
        } 
      }
    }
    .about-project__left, .about-project__right {
      & ul {
        & * {
          font-size: 16px !important;
        }
      }
    }

    & > div div p {
      margin-bottom: 20px;
    }

    & img {
      margin: 0;
    }

    & h3 {
      font-weight: normal;
      font-size: 24px !important;
      margin: 0 !important;
    }

    & h3 + div {
      margin: 20px 0 90px;
      flex-direction: row;
    }
    .technologies-wrapper ul {      
      margin: 0;

      li:not(:first-child) {
        font-size: 16px !important;
      }

      li:first-child {
        font-size: 12px;
      }
    }
  }
  ${StyleMediaMax.mobile} {
    & h3 + div {
      flex-wrap: wrap;
    }
    .technologies-wrapper ul {      
      width: 47% !important;
      margin-bottom: 32px;
    }
  }
`;

export default Style;