import styled from 'styled-components';
import { StyleMediaMax } from '@app/components/StyleMedia';

interface Props {
  widthTablet?: string;
}

const PaginationWrapper = styled.div<Props>`
  width: 100%;
  display: flex;
  justify-content: center;

  ${StyleMediaMax.tablet} {
    width: ${props => props.widthTablet ? props.widthTablet : 'calc(100% - 60px)'};
  }

  ${StyleMediaMax.mobileM} {
    width: calc(100% - 20px);
  }
`;

export default PaginationWrapper;