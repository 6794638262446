import React, {useContext, useState} from 'react';

import Dropdown from '@app/components/Dropdown';
import SelectWrapper from './SelectWrapper';
import SelectControl from './SelectControl';
import OptionContainer from './OptionContainer';
import ContentContainer from "./ContentContainer";
import {IsDarkContext} from "@app/components/Content/Content";

interface Props {
  placeholder: string;
  options: string[];
  selected?: string;
  onSelect: (select: string) => void;
  widthTablet?: string;
}

const Select: React.FC<Props> = ({ placeholder, options, selected, onSelect, widthTablet }) => {
  const [isOpen, setOpen] = useState(false);
  const context = useContext(IsDarkContext);
  const dropdownContent = () => (
    <ContentContainer>
      {
        options.map(option =>
          <OptionContainer
            key={option}
            selected={selected === option}
            onClick={() => {
              onSelect(option);
                if (context) {
                    context.setIsDark(false);
                }
              setOpen(false);
            }}
          >
            {option}
          </OptionContainer>)
      }
    </ContentContainer>
  );

  return (
    <SelectWrapper widthTablet={widthTablet}>
      <Dropdown
        isOpen={isOpen}
        content={dropdownContent()}
      >
        <SelectControl
          isOpen={isOpen}
          onClick={(event) => {
            event.stopPropagation();
              if (context) {
                  context.setIsDark(!isOpen);
              }
            setOpen(!isOpen);
          }}
        >
          {selected !== undefined ? selected : placeholder}
        </SelectControl>
      </Dropdown>
    </SelectWrapper>
  )
};

export default Select;
